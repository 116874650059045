import { sequence } from '@angular/animations';
import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private restful: RestfulService,
  ) {
  }
  // 주문 리스트 가져오기
  getOrderList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 상세정보 가져오기
  getOrderDetail( orderSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderDetail'
      }, {
        seq: orderSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 매장판매 주문 리스트 가져오기
  getOrderStoreSalesList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderStoreSalesList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 메모 리스트 가져오기
  getOrderMemoList( orderSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderMemoList'
      }, {
        seq: orderSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 메모 리스트 가져오기
  getOrderMemoCustomerList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderMemoCustomerList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 메모 저장
  setOrderMemoSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderMemoSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 초도 주문 리스트 가져오기
  getOrderInitialList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderInitialList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 초도 주문 저장
  setInitialOrderNameSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setInitialOrderNameSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // PG결제 신용카드 및 은행코드 가져오기
  getPaymentGatewayCodeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getPaymentGatewayCodeList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문정보 저장
  setOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 출고지시 주문 리스트 가져오기
  getOrderDeliveryList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderDeliveryList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 출고지시 주문 상세 리스트 가져오기
  getOrderDeliveryDetailList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderDeliveryDetailList'
      }, {
        order_seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  setInitialOrderNameEdit( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setInitialOrderNameEdit'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }


  delOrderInitialList( array: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'delOrderInitialList'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.extractData);
  }

  setInitialOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setInitialOrderSave'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  getOrderDetailInitialList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderDetailInitialList'
      }, {
        iniOrderSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 배송전 취소
  setOrderDetailCancel( seq: number, array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderDetailCancel'
      }, {
        orderSeq: seq,
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.extractData);
  }


  // 교환/환불 저장
  setOrderDetailRefund( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderDetailRefund'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }


  getChangeRefundList( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getChangeRefundList'
      }, {seq: seq}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }


  // 결제내역 저장
  setOrderPaySave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderPaySave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 결제내역 삭제
  setOrderPayDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderPayDelete'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 결제 확인 처리
  setOrderPayComplete( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderPayComplete'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 여신 대체 처리
  setOrderPayCreditReplace( seq: number ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderPayCreditReplace'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }


  // 출고지시
  getOrderStock(seq): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'order',
      version: '1.0',
      action: 'getOrderStock'
    }, {seq: seq}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  
}
