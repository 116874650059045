/*******************************************************************************
  설  명 : 회원정보 - 메모내역
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';
import { OrderService } from '@app/service/order.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';


@Component({
  selector: 'app-order-out-delivery',
  templateUrl: './order-out-delivery.component.html',
  styleUrls: ['./order-out-delivery.component.scss']
})
export class OrderOutDeliberyComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() OrderSeq: any;
  @Input() dormant: any;
  @Input() memberNo: any;

  public search: any = {
    searchText: ''
  };

  public form: FormGroup;
  public formErrors = {};

  // 그리드 관련 선언
  memberMemoList: any = [];
  memberInfo: any = {
    hp: '',
    email: ''
  }

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  outColumnDefs: any;
  outGridApi: any;
  outGridColumnApi: any

  rowData: any = [];
  rowData1: any = [];

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public setMemberMemoSaveFunc: any;
  public setMemberHPEmailFunc: any;


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      type: ['1', [Validators.required]],
      type_data: ['', [Validators.required, Validators.maxLength(50)]],
      OrderSeq: ['', []],
      message: ['', [Validators.required] ],
      emailBlocked: ['', []],
      smsBlocked: ['', []],
      kakaoBlocked: ['', []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'mng_out_order_seq', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품코드', field: 'product_code', width: 100, cellClass: 'cp center ag-cell80h'},
      { headerName: '제조사', field: 'brand_name', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          var provider = '';
          var categoryName = '';

          if ( params.data.provider_code ) {
            provider = '<span class="badge badge-light f12">공급자연락처</span><br/>';
          }

          if( params.data.category_name ) {
            categoryName = '<br/><font style="color:red;">' + params.data.category_name + '</font>';
          }

          return `${provider}${params.data.brand_name}${categoryName}`;
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent'},
      { headerName: '상품명', field: 'product_name', width: 250, cellClass: 'cp center ag-cell80h'},
      { headerName: '이벤트', field: 'event_name', width: 250, cellClass: 'cp center ag-cell80h'},
      { headerName: '색상', field: 'color', width: 50, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<span style="display:inline-block;margin-top:32px;width:16px;height:16px;border:solid 1px #ccc;background-color:${params.data.color}">&nbsp;</span>`;
        } 
      },
      { headerName: '색상명', field: 'color_name', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.color_name + ( params.data.display_color_name ? '<br/>(' + params.data.display_color_name + ')' : '' );
        } 
      },
      { headerName: '사이즈', field: 'size_name', width: 100, cellClass: 'cp center ag-cell80h' },
      { headerName: '출고지시수량', field: 'out_order_qty', width: 150, cellClass: 'cp center ag-cell80h' },
      { headerName: '출고수량', field: 'out_qty', width: 150, cellClass: 'cp center ag-cell80h' },
      { headerName: '미출고수량', field: 'incompleteOutQty', width: 150, cellClass: 'cp center ag-cell80h' },
    ];

    // ag grid 컬럼 선언
    this.outColumnDefs = [
      { headerName: '매입구분', field: 'product_gbn', width: 70, cellClass: 'cp center' },
      { headerName: '출고지시번호', field: 'mng_out_seq', width: 90, cellClass: 'cp center' },
      { headerName: '출고지시일자', field: 'out_order_date', width: 100, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowData;

    this.rowData1;

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getOrderStock();
  }



  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
  설  명 : ag grid ready 시 처리
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
  onOutGridReady(params) {
    this.outGridApi = params.api;
    this.outGridColumnApi = params.columnApi;
  }


   /*******************************************************************************
    설  명 : 데이터 선택시 상세 보여주기
  *******************************************************************************/
    onRowClicked($event) {
      this.rowData1 = $event.data.stockList;
    }



  getOrderStock() {
    this.orderService.getOrderStock(this.OrderSeq).then(response => {
      if( response.ResultCode ) {
        this.rowData = this.detailStock(response.data);
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  // LEFT JOIN event_product m
  //     ON
  //       m.product_seq = a.product_seq
      
  //     LEFT JOIN event j
  //     ON
  //       j.seq = m.event_seq
  //       AND IFNULL(j.del_yn, 0) = false


  detailStock(data) {
    const detailStock = []
    data.forEach(e => {
      const stockList = {
        brand_name: e['brand_name'],
        category_name: e['category_name'],
        mng_out_order_seq : e['mng_out_order_seq'],
        product_name : e['product_name'],
        product_code: e['product_code'],
        color_name : e['color_name'],
        size_name : e['size_name'],
        event_name: e['event_name'],
        thumbnail_url : e['thumbnail_url'],
        out_order_qty : parseInt(e['out_order_qty']),
        out_qty : parseInt(e['out_qty']),
        incompleteOutQty: parseInt(e['incompleteOutQty'])
      }
      const idx = detailStock.findIndex(_e => _e['mng_out_seq'] == e['mng_out_seq'])
      idx < 0
      ? detailStock.push({
        product_gbn : e['product_gbn'],
        mng_out_seq : e['mng_out_seq'],
        mng_out_status_name : e['mng_out_status_name'],
        out_order_date : e['out_order_date'],
        out_date : e['out_date'],

        stockList : [stockList]
      })
      : detailStock[idx]['stockList'].push(stockList)
    })

    detailStock.forEach(e => {
      ['qty', 'out_order_qty', 'out_qty', 'incompleteOutQty'].forEach(_e => {
        e[_e] = e['stockList'].reduce((a,c) => a + c[_e], 0)
      })
    })

    return detailStock
  }


  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getRowHeight = function(params) {
      return 80;
    };
  

}
