import { SafeResourceUrl } from '@angular/platform-browser';
/*******************************************************************************
  설  명 : 상품관리 - 상품사이즈 관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { ProductService } from '@app/service/product.service';

import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ProductPropertyAddComponent } from '@page/product/product-property/product-property-add/product-property-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-property',
  templateUrl: './product-property.component.html',
  styleUrls: ['./product-property.component.scss']
})
export class ProductPropertyComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 1000,
    searchText: '',
    fid: '',
    category_code: '',
    brand: '',
    soldout: '',
    display: '',
    property_count_view: true
  };


  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    completeCount: 0,
    uncompleteCount: 0
  }

  public categoryList: any = [];
  public brandList: any = [];
  public productList: any = [];
  public productPropertyList: any = [];

  public productSeqSelected: any = '';

  public totalCount: number = 0;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  propertyGridApi: any;
  propertyGridColumnApi: any;
  propertyColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private productService: ProductService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '상품코드', field: 'product_code', width: 120, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '카테고리', field: 'category_name', width: 180, cellClass: 'cp' },
      { headerName: '브랜드', field: 'brand_name', width: 150, cellClass: 'cp center' },
      { headerName: 'FID', field: 'bama_fid', width: 100, cellClass: 'cp center' },
      { headerName: '상품명', field: 'product_name', width: 420, cellClass: 'cp' },
      { headerName: '색상 개수', field: 'color_cnt', width: 80, cellClass: 'cp' },
      { headerName: '사이즈 개수', field: 'size_cnt', width: 90, cellClass: 'cp' },
    ];

    // ag grid 컬럼 선언
    this.propertyColumnDefs = [
      { headerName: '순번', field: 'rowIndex', width: 90, cellClass: 'cp center', valueGetter: 'node.rowIndex + 1',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '색상', field: 'color', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.color != null ) {
            return `<span style="display:inline-block;margin-top:6px;width:16px;height:16px;border:solid 1px #ccc;line-height: 12px;background-color:${params.data.color}">&nbsp;</span> ${params.data.color_name}`;
          } else {
            return '';
          }
        }
      },
      { headerName: '사이즈', field: 'size_name', width: 150, cellClass: 'cp center' },
      { headerName: '정렬순서', field: 'sort_order', width: 95, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCategoryList();
    this.getBrandList();

    // 상품 데이터 불러오기
    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      this.brandList = [];
      if( response.ResultCode ) {
        // this.brandList = response.data;

        this.brandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.brandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.brandList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductList() {
    this.productService.getProductList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.productList = response.data;
        this.totalCount = response.total;
        this.statistic.searchCount = this.totalCount;

        if( this.productList.length > 0 ) {
          this.productSeqSelected = this.productList[0].seq;

          let index = 0;
          setTimeout(() => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }, 0);

          // 선택 상품의 색상 사이즈 가져오기
          this.getProductPropertyList();

          // 위젯 통계 가져오기
          this.getProductPropertyStatistic();
        }
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 속성 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductPropertyList() {
    this.productService.getProductPropertyList( this.productSeqSelected ).then( response => {
      if( response.ResultCode ) {
        this.productPropertyList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 속성 통계 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductPropertyStatistic() {
    this.productService.getProductPropertyStatistic().then( response => {
      if( response.ResultCode ) {
        this.statistic.totalCount = response.statistic.totalCount;
        this.statistic.completeCount = response.statistic.completeCount;
        this.statistic.uncompleteCount = response.statistic.uncompleteCount;
      } else {
        this.toastrService.error(response.ResultMessage);

        this.statistic.totalCount = 0;
        this.statistic.completeCount = 0;
        this.statistic.uncompleteCount = 0;
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPropertyGridReady(params) {
    this.propertyGridApi = params.api;
    this.propertyGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked( event: any ) {
    this.productSeqSelected = event.data.seq;

    this.getProductPropertyList();
  }

  /*******************************************************************************
    설  명 : ag grid 페이징 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPaginationChanged( event: any ) {
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onBrandChanged( event: any ) {
    this.search.brand = event;
    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 속성 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addProperty() {
    const nodes = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error( '상품을 선택하시기 바랍니다.' );
      return false;
    } else {
      const modalRef = this.modalService.open(ProductPropertyAddComponent, options);

      const seqArray: any = [];
      for(const node of nodes ) {
        seqArray.push( node.seq );
      }

      modalRef.componentInstance.seqArray = seqArray;

      modalRef.result.then((result) => {
        if( result ) {
          this.getProductList();
        }
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 속성 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPropertyDelete() {
    const nodes = this.propertyGridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 속성을 선택하세요', '' );
      return false;
    }

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data );
    }

    if ( confirm('삭제하시겠습니까?') ) {
      this.productService.setPropertyDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.getProductList();
          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 1000,
      searchText: '',
      fid: '',
      category_code: '',
      brand: {id: '0'},
      soldout: '',
      display: ''
    };

    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getProductList();
  }

}
