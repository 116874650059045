/*******************************************************************************
  설  명 : 주문관리 - 주문 상세정보
  작성일 : 2020-12-12
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
// import { OrderService } from '@app/service/order.service';
// import { CommonService } from '@app/service/common.service';
// import { BasicService } from '@app/service/basic.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import { ScmOrderService } from '@app/service/scm.order.service'
import { DashboardComponent } from '@page/dashboard/dashboard.component'
import { ProductComponent } from '@page/product/product.component'
import { ModalDeliveryComponent } from '@page/order/modal-delivery/modal-delivery.component'
// import { BasicComponent } from '@app/components/basic/basic.component';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  search_protoType = {
    mng_out_seq: '',
    index: 1,
    searchField: 'o_name',
    searchText: '',
    sdate: '',
    edate: '',
    searchTerm: '',
    status: ''
  };
  search = { ...this.search_protoType };
  orderDetail: any
  = {
    o_name : '',
    o_email : '',
    o_hp : '',
    o_hp2 : '',
    r_name : '',
    r_hp : '',
    r_zipcode : '',
    r_address : '',
    r_address_detail : '',
    r_comment : '',
    status : 0,
    mng_out_seq : '',
    mng_out_delivery_seq : ''
  };

  setOrderMemoSaveFunc: any;

  memoForm: FormGroup;
  memoFormErrors = {};

  // 그리드 관련 선언
  GridApi_order: any
  GridColumnApi_order: any
  ColumnDefs_order: any
  rowData_order: Array<Object>
  GridApi_orderSum: any
  GridColumnApi_orderSum: any
  ColumnDefs_orderSum: any
  rowData_orderSum: Array<Object>
  GridApi_memo: any
  GridColumnApi_memo: any
  ColumnDefs_memo: any
  rowData_memo: Array<Object>

  defaultColDef: any
  domLayout: any
  rowSelection: any
  paginationPageSize: any = 100
  noRowsTemplate: string
  rowClassRules: any
  gridOption: any
  isRowSelectable: any
  components: any
  
  orderDetailList = []

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private agGridExComponent: AgGridExComponent,
    private scmOrderService: ScmOrderService,
    private dashboardComponent: DashboardComponent,
    private productComponent: ProductComponent,
  ) {
    this.setOrderMemoSaveFunc = this.setMemo.bind(this)

     this.ColumnDefs_order = [
      this.dashboardComponent.checkboxColumn,
      { headerName: '브랜드', field: 'brand_name', width: 150, cellClass: 'cp center ag-cell80h' },
      { headerName: '이미지', field: 'thumbnail_url', width: 120, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      // { headerName: '홍보문구', field: 'comment', width: 200, cellClass: 'cp left ag-cell80h' },
      { headerName: '상품명', field: 'product_name', width: 600, cellClass: 'cp left ag-cell80h' },
      { headerName: '색상', field: 'color_name', cellClass: 'cp center ag-cell80h', width: 150, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const displayColorName = params.data.display_color_name;

          return '<span class="fb">' + displayColorName + '</span>';
        }
      },
      { headerName: '사이즈', field: 'size_name', cellClass: 'cp center ag-cell80h', width: 150, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const displaySizeName = params.data.display_size_name;

          return '<span class="fb">' + displaySizeName + '</span>';
        }
      },
      { headerName: '주문수량', field: 'out_order_qty', width: 100, cellClass: 'cp right f14 fb ag-cell80h',
        valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '출고수량', field: 'releaseQty', width: 100, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellEditor: "numericCellEditor", cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '공급가', field: 'buy_price', width: 100, cellClass: 'cp right ag-cell80h',
        valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '합계', field: '', width: 100, cellClass: 'cp right ag-cell80h',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: (params: any) => params.data.buy_price * params.data.out_order_qty
      }
    ]

    this.ColumnDefs_orderSum = [
      { headerName: '', field: '', width: 1200 },
      { headerName: '출고지시수량 합계', field: 'qty', cellClass: 'right', width: 130, flex: 0,
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '공급가 합계', field: 'amt', cellClass: 'right', width: 130, flex: 0,
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '총 합계', field: 'total_amt', cellClass: 'right', width: 130, flex: 0,
        valueFormatter: this.agGridExComponent.currencyFormatter
      }
    ]

     this.ColumnDefs_memo = [
      { headerName: '구분', field: 'malltype', width: 100, flex: 0 },
      { headerName: '코멘트', field: 'comment', flex: 1 },
      { headerName: '작성자', field: 'name', width: 100, flex: 0 },
      { headerName: '등록일', field: 'write_date', width: 100, flex: 0 },
    ]

    this.defaultColDef = this.dashboardComponent.defaultColDef
    this.rowSelection = 'multiple';
    this.noRowsTemplate = this.dashboardComponent.noRowsTemplate
    this.components = this.dashboardComponent.components
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {

    // 입력 파라미터 처리
    this.activatedRoute.queryParams.subscribe( async params => {
      this.search.mng_out_seq = ( typeof params.mng_out_seq == 'undefined' || params.mng_out_seq == '' ) ? '' : params.mng_out_seq;
      this.search.index = ( typeof params.index == 'undefined' || params.index == '' ) ? '1' : params.index;
      this.search.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? '' : params.searchField;
      this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.search.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.search.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.search.searchTerm = ( typeof params.searchTerm == 'undefined' || params.searchTerm == '' ) ? '' : params.searchTerm;
      this.search.status = ( typeof params.status == 'undefined' || params.status == '' ) ? '' : params.status;
      
      if(!this.search.mng_out_seq) return;

      this.getOrderDetail()
      this.getMemo()
    })

    this.buildForm()
  }

  buildForm(): void {
    this.memoForm = this.formBuilder.group({
      comment: ['', [Validators.required] ]
    })

    this.memoForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memoForm, this.memoFormErrors )
    })
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = () => 80

  /*******************************************************************************
    설  명 : 주문 상세정보 가져오기
  *******************************************************************************/
  getOrderDetail() {
    this.scmOrderService.getOrderDetail({
      mem_no : this.dashboardComponent.getUser(),
      mng_out_seq : this.search.mng_out_seq
    }).then(response => {
      if(response.ResultCode) {
        const _RD = response.data

        this.rowData_order = _RD.map(item => ({...item, releaseQty: item.out_order_qty}))
        this.rowData_orderSum = [{
          qty : _RD.reduce((a,c) => a + parseInt(c['out_order_qty']), 0),
          amt : _RD.reduce((a,c) => a + parseInt(c['buy_price']), 0),
          total_amt : _RD.reduce((a,c) => a + (parseInt(c['buy_price']) * parseInt(c['out_order_qty'])), 0),
        }]
        this.orderDetail = _RD[0]
        this.orderDetailList = _RD
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 주문 메모 가져오기
  *******************************************************************************/
  getMemo() {
    this.scmOrderService.getMemo({
      mem_no : this.dashboardComponent.getUser(),
      mng_out_seq : this.search.mng_out_seq
    }).then(response => {
      if(response.ResultCode) {
        this.rowData_memo = response.data
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 주문 메모 작성하기
  *******************************************************************************/
  setMemo() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memoForm, this.memoFormErrors)
    if(!this.memoForm.valid) return

    this.scmOrderService.setMemo({
      mem_no : this.dashboardComponent.getUser(),
      mng_out_seq : this.search.mng_out_seq,
      comment : this.memoForm.value.comment
    }).then(response => {
      if(response.ResultCode) {
        this.toastrService.success(response.ResultMessage)
        this.getMemo()
      } else this.toastrService.error(response.ResultMessage)
    })
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady_order(params) {
    this.GridApi_order = params.api;
    this.GridColumnApi_order = params.columnApi;
  }
  onGridReady_orderSum(params) {
    this.GridApi_orderSum = params.api;
    this.GridColumnApi_orderSum = params.columnApi;
  }
  onGridReady_memo(params) {
    this.GridApi_memo = params.api;
    this.GridColumnApi_memo = params.columnApi;
  }

  /*******************************************************************************
    설  명 : cell 클릭 시
  *******************************************************************************/
  onCellClicked($event) {
    if ($event.colDef.field === 'thumbnail_url') {
      this.productComponent.productDetail($event.data.product_seq);
    }
  }

  /*******************************************************************************
    설  명 : 메모 input에서 엔터키 입력 시
  *******************************************************************************/
  setKeyPressMemo( $event ) {
    if( $event.key == 'Enter' ) this.setMemo()
  }

  /*******************************************************************************
    설  명 : 리스트로 돌아가기
  *******************************************************************************/
  goList() {
    this.search.mng_out_seq = '';

    this.router.navigate(
      ['/order/list'],
      { relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 출고처리
  *******************************************************************************/
  setDelivery() {
    if(this.orderDetail.mng_out_delivery_seq && !confirm('출고처리한 주문입니다. \r\n배송정보를 변경하시겠습니까?')) return

    const modalRef = this.modalService.open(ModalDeliveryComponent, {
      backdrop: 'static',
      keyboard: false,
      size: '',
      centered: true,
      windowClass: 'modal-fadeInDown'
    })
    modalRef.componentInstance.mng_out_seq = this.orderDetail.mng_out_seq
    modalRef.componentInstance.mng_out_delivery_seq = this.orderDetail.mng_out_delivery_seq
    modalRef.componentInstance.order_master_seq = this.orderDetail.mng_out_order_seq
    modalRef.result.then(result => {
      this.getOrderDetail()
    }, (reason) => {})
  }
  
  /*******************************************************************************
    설  명 : 출고취소
  *******************************************************************************/
  setCancelDelivery() {
    if(!this.orderDetail.mng_out_delivery_seq && this.toastrService.warning('출고한 주문이 아닙니다.')) return
    else if(!confirm('출고처리를 취소하시겠습니까?')) return

    this.scmOrderService.setCancelDelivery({
      mng_out_seq : this.orderDetail.mng_out_seq, 
      mng_out_delivery_seq : this.orderDetail.mng_out_delivery_seq, 
      order_master_seq : this.orderDetail.mng_out_order_seq,
      mem_no : this.dashboardComponent.getUser()
      }).then(res => {
        if(res.ResultCode) {
          this.toastrService.success('출고취소하였습니다.')
          this.getOrderDetail()
        } else this.toastrService.error(res.ResultMessage)
      }).catch(e => this.toastrService.error(e))
  }

  // 출고지시 분할
  divideRelease() {
    if(this.orderDetail.status != 1000 && this.toastrService.warning('출고지시 상태에서만 가능합니다.')) return
    this.orderDetailList = this.orderDetailList.map(item => ({...item, divide_qty: 0}))
    document.getElementById('orderDetailList').style.transform = "scale(1)"
  }

  putOrderDetailListDivideQty($event, index) {
    const value = parseInt($event.target.value)
    if(!value) this.toastrService.error('유효한 입력이 아닙니다.')
    this.orderDetailList[index].divide_qty = value || 0
  }

  divideSubmit() {
    const data = this.orderDetailList.filter(item => item.divide_qty)
    if(!data.length) {
      this.toastrService.warning('분할 수량을 입력하십시오.')
      return
    } else if( this.orderDetailList.reduce((a,c) => a + c.out_order_qty, 0) == this.orderDetailList.reduce((a,c) => a + c.divide_qty, 0) ) {
      this.toastrService.warning('남는 수량이 존재해야 합니다.')
      return
    }
    for(let i = 0; i < data.length; i++) 
    if(data[i].divide_qty > data[i].out_order_qty - data[i].out_qty) {
      this.toastrService.warning(`분할 수량은 남은 수량을 초과할 수 없습니다.`, `LINE ${i+1}`)
      return
    }

    this.scmOrderService.postDivideRelease({
      mem_no : this.dashboardComponent.getUser(),
      divide_list : data
    }).then(res => {
      if(res.ResultCode) {
        this.toastrService.success('성공')
        this.divideCancel()
        this.getOrderDetail()
      } else this.toastrService.error(res.ResultMessage)
    }).catch(e => this.toastrService.error(e))
  }

  divideCancel() {
    document.getElementById('orderDetailList').style.transform = "scale(0)"
  }

  // 출고 실행. 기존의 출고처리 + 분할 기능을 통합하여 출고수량선택을 강제함.
  postRelease() {
    const message = [
      '출고할 상품을 선택하지 않았습니다. 체크박스를 선택하십시오.',
      '출고할 수량이 하나도 없습니다. 출고수량을 입력하십시오.',
      '출고할 수량이 주문수량을 초과합니다. 적정한 출고수량을 입력하십시오.',
      '출고하지 않는 수량이 있습니다. \r\n이대로 계속하시겠습니까? \r\n남은 수량은 자동분할합니다.',
      '출고하시겠습니까?',
      '이미 출고하였습니다. 출고할 수 없습니다.',
      '체크박스를 선택하지 않은 상품들이 있습니다. \r\n이 상품들은 자동분할합니다. \r\n계속 하시겠습니까?'
    ]
    const rows = this.GridApi_order.getSelectedRows()
    if(this.orderDetail.mng_out_delivery_seq && this.toastrService.warning(message[5])) return
    else if(!rows.length && this.toastrService.warning(message[0])) return
    else if(!rows.filter(item => parseInt(item.releaseQty)).length && this.toastrService.warning(message[1])) return
    else if(rows.filter(item => parseInt(item.releaseQty) > parseInt(item.out_order_qty)).length && this.toastrService.warning(message[2])) return
    else if(rows.reduce((a,c) => a + parseInt(c.out_order_qty), 0) > rows.reduce((a,c) => a + parseInt(c.releaseQty), 0) && !confirm(message[3])) return
    else if(rows.length != this.rowData_order.length && !confirm(message[6])) return

    const modalRef = this.modalService.open(ModalDeliveryComponent, {
      backdrop: 'static',
      keyboard: false,
      size: '',
      centered: true,
      windowClass: 'modal-fadeInDown'
    })
    modalRef.componentInstance.mng_out_seq = this.orderDetail.mng_out_seq
    modalRef.componentInstance.returnDataMode = true
    modalRef.result.then(result => {
      if(!confirm(message[4])) return
      modalRef.close()

      const filterKey = 'mng_out_order_seq'
      const divide_list = rows
      .filter(item => parseInt(item.out_order_qty) > parseInt(item.releaseQty))
      .map(item => ({...item, divide_qty : parseInt(item.out_order_qty) - parseInt(item.releaseQty)}))
      .concat(this.rowData_order.filter(item => {
        for(let _item of rows) if(_item[filterKey] == item[filterKey]) return false
        return true
      }).map((item: any) => ({...item, divide_qty: parseInt(item.out_order_qty)})))

      const setDelivery = () => {
        this.scmOrderService.setDelivery({
          mem_no : this.dashboardComponent.getUser(),
          consign_date : result['consign_date'],
          delivery_company : result['delivery_company'],
          delivery_no : result['delivery_no'],
          mng_out_seq : result['mng_out_seq'],
          mng_out_delivery_seq : this.orderDetail.mng_out_delivery_seq,
          order_master_seq: this.orderDetail.order_seq
        }).then(response => {
          if(response.ResultCode) {
            this.toastrService.success(response.ResultMessage)
            this.getOrderDetail()
          } else this.toastrService.error(response.ResultMessage)
        }).catch(e => this.toastrService.error(e))
      }

      divide_list.length 
      ? this.scmOrderService.postDivideRelease({
        mem_no : this.dashboardComponent.getUser(),
        divide_list : divide_list
      }).then(res => {
        if(res.ResultCode) {
          setDelivery()
        } else this.toastrService.error(res.ResultMessage)
      }).catch(e => this.toastrService.error(e))
      : setDelivery()

    }, (reason) => {})

  }

}
