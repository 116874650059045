/*******************************************************************************
  설명 : 전역 변수 및 JWT 토큰 관리 클래스
  작성일 : 2019-07-07
  작성자 : 송영석
*******************************************************************************/
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { config } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class Globals {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // token data
  public token : string = '';
  public tokenData : object  = {
    header: {
      typ: 'JWT',
      alg: 'HS256'
    },
    payload: {
      iss: config.baseUrl,
      exp: '',
      memNo: ''
    },
    signature: ''
  };

  // api base url
  public apiBaseUrl: string = config.apiBaseUrl + '/index.php';

  // indicator
  public indicator: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public indicator$ = this.indicator.asObservable();

  /*******************************************************************************
    설명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor() {

    // 로컬 세션 스토리지에 저장된 토큰을 불러온다.
    //this.token = sessionStorage.getItem( config.cookie_token );
    this.token = this.getCookie( config.cookie_token );

    if( this.token !== null )
      this.setToken( this.token );
  };

  /*******************************************************************************
    설명 : Indicator check
    입력값 : token = 서버에서 받은 토큰 문자열
    리턴값 : 없음
  *******************************************************************************/
  public getIndicator() {
    return this.indicator.subscribe( value => {
      return value;
    });
  }

  /*******************************************************************************
    설명 : 토큰 hash 저장
    입력값 : token = 서버에서 받은 토큰 문자열
    리턴값 : 없음
  *******************************************************************************/
  public setToken( token : string ) {
    if( token == null ) return;

    this.token = token;

    let jsonData = token.split( '.' );
    if( jsonData.length !== 3 ) return;

    let header = this.b64DecodeUnicode( jsonData[0] );
    let payload = this.b64DecodeUnicode( jsonData[1] );

    this.tokenData = {
      header: header,
      payload: payload,
      signature: jsonData[2]
    }

    // save session storage 브라우저 리프레쉬 로그인 끊김 차단
    //sessionStorage.setItem( config.cookie_token, token );
    this.setCookie( config.cookie_token, token, 1 );
  }

  /*******************************************************************************
    설명 : 토큰 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public clearToken() {
    this.token = '';
    this.tokenData = {
      header: {
        typ: 'JWT',
        alg: 'HS256'
      },
      payload: {
        iss: config.baseUrl,
        exp: '',
        memNo: ''
      },
      signature: ''
    }

    //sessionStorage.removeItem( config.cookie_token );
    this.deleteCookie( config.cookie_token );
  }

  /*******************************************************************************
    설명 : base64_decode
    입력값 : str = 문자열
    리턴값 : base64 decode 문자열
  *******************************************************************************/
  private b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  /*******************************************************************************
    설명 : base64_encode
    입력값 : str = 문자열
    리턴값 : base64 encode 문자열
  *******************************************************************************
  private base64EncodingUTF8(str) {
    var encoded = new TextEncoderLite('utf-8').encode(str);
    var b64Encoded = base64js.fromByteArray(encoded);
    return b64Encoded;
  }
  */

  /*******************************************************************************
    설명 : 쿠키 저장
    입력값 : name, value, day
    리턴값 : 없음
  *******************************************************************************/
  setCookie(name, value, day) {
    let date = new Date();
    date.setTime(date.getTime() + day * 60 * 60 * 24 * 1000);

    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
  }

  /*******************************************************************************
    설명 : 쿠키 값 불러오기
    입력값 : name
    리턴값 : value
  *******************************************************************************/
  getCookie(name) {
    let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value ? value[2] : null;
  };

  /*******************************************************************************
    설명 : 쿠키 삭제
    입력값 : name
    리턴값 : 없음
  *******************************************************************************/
  deleteCookie(name) {
    let date = new Date();
    document.cookie = name + "= " + "; expires=" + date.toUTCString() + "; path=/";
  }

}
