import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 카테고리 리스트
  getCategoryList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'category',
        version: '1.0',
        action: 'getCategoryList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 카테고리 리스트 treeview
  getCategoryListN(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'category',
        version: '1.0',
        action: 'getCategoryListN'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // select2 용 카테고리 리스트 가져오기
  getCategoryList2(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'category',
        version: '1.0',
        action: 'getCategoryList2'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품순서관리 - 카테고리 리스트 가져오기
  getCategoryListForSort(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'category',
        version: '1.0',
        action: 'getCategoryListForSort'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 카테고리 정보 저장하기
  setCategory( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'category',
        version: '1.0',
        action: 'setCategory'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 카테고리 정보 저장하기
  updateCategory( jsonData ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'category',
        version: '1.0',
        action: 'updateCategory'
      }, {
        data: jsonData
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 카테고리 정보 삭제하기
  deleteCategory( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'category',
        version: '1.0',
        action: 'deleteCategory'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 노드 순서 저장하기
  setNodeSort( node ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'category',
        version: '1.0',
        action: 'setNodeSort'
      }, {
        node: node
      }
    ).then(this.restful.extractData)
      .catch( err => {
      console.log( err );
    });
  }
}
