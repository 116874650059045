import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(
    public restful: RestfulService,
  ) { }

  // 브랜드 리스트 가져오기
  getBrandList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'getBrandList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드 리스트 가져오기
  getBrandListForComponent( form: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'getBrandListForComponent'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // selectbox용 브랜드 리스트 가져오기
  getBrandListForselectBox(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'getBrandListForComponent'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }  

  // 상품순서관리 - 브랜드 리스트 가져오기
  getBrandListForSort(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'getBrandListForSort'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드 정보 가져오기
  getBrandInfo( brandSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'getBrandInfo'
      }, {
        seq: brandSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드 등록 및 수정
  setBrandSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'setBrandSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 이미지 삭제
  setDeleteUploadFile( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'setDeleteUploadFile'
      }, {
        imageSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드별 기준할인율 리스트 가져오기
  getBrandBaseDiscountList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'getBrandBaseDiscountList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드별 기준할인율 일괄 저장
  setBrandBaseDiscountSave( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'brand',
        version: '1.0',
        action: 'setBrandBaseDiscountSave'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
