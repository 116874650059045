<div class="modal-header pb10">
    <h4 class="modal-title fb" id="modal-common-code">상품추가</h4>
    <button type="button" class="close" aria-label="Close" (click)="setDismiss()">&times;</button>
  </div>
  
  <div class="modal-body p10 mb5">
    <div class="header">
      <div class="fl w50p pt5">
        <span class="f12">전체 갯수 : {{getComma(productList.length)}} 건</span>
      </div>
      <div class="fr w50p right">
        <!--
        <input type="text" id="quickFilter" (input)="onQuickFilterChanged($event)" class="form-control" placeholder="상품번호, 상품명 검색" />
        //-->
      </div>
      <div class="cb"></div>
    </div>
  
    <div class="h100">
      <!-- <div style="height: 100%;" (dragover)="gridDragOver($event)"> -->
      <div style="height: 100%;">
  
        <div class="form-inline mb5">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>
  
          <select [(ngModel)]="search.category_code" class="form-control form-control-small ml5" (change)="getProductListForProperty()">
            <option value="">카테고리 전체</option>
            <option *ngFor="let item of categoryList" value="{{item.category_code}}">{{item.category_nm}}</option>
          </select>
  
          <l-select2
            [data]="brandList"
            (ngModelChange)="onSelectBrandChanged($event)"
            [(ngModel)]="search.brand_seq"
            class="form-control form-control-small ml5"
            [options]="{multiple: false}"
            style="width: 200px; padding:0px !important;border:solid 0px #fff !important;"
          ></l-select2>
  
          <input type="text" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="30" class="form-control form-control-small ml5" placeholder="상품코드, 상품명 검색" />
        </div>
  
        <ag-grid-angular
          #myGrid
  
          style="width: 50%; height: 500px; float: left;"
          class="ag-theme-alpine"
  
          rowSelection="multiple"
          [defaultColDef]="defaultColDef"
          [columnDefs]="leftColumnDefs"
          [rowData]="productList"
          [overlayNoRowsTemplate]="leftGridNoRowsTemplate"
          [enableMultiRowDragging]="true"
          [suppressRowClickSelection]="false"
          [getRowNodeId]="getRowNodeId"
          [rowDragManaged]="true"
          [suppressMoveWhenRowDragging]="true"
          [animateRows]="true"
          [frameworkComponents]="frameworkComponents"
  
          (gridReady)="onLeftGridReady($event)"
          >
        </ag-grid-angular>
      </div>
  
      <!-- <div style="height: 100%;" (dragover)="gridDragOver($event)" (drop)="gridDrop($event)"> -->
      <div style="height: 100%;">
        <!--
        <div style="width: 10%; height: 500px; float: left;">
          <span id="eBin" (dragover)="binDragOver($event)" (drop)="binDrop($event)" class="factory factory-bin">
            <i class="material-icons delete_forever">&#xe92b;</i>
          </span>
        </div>
        //-->
        <ag-grid-angular
          #myGrid
  
          style="width: 50%; height: 500px; float: left;"
          class="ag-theme-alpine"
        
          [defaultColDef]="defaultColDef"
          [columnDefs]="rightColumnDefs"
          [rowData]="selectedList"
          [overlayNoRowsTemplate]="rightGridNoRowsTemplate"
          [getRowNodeId]="getRowNodeId"
          [rowDragManaged]="true"
          [animateRows]="true"
          [frameworkComponents]="frameworkComponents"
  
          (gridReady)="onRightGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </div>
  </div>
  
  <div class="modal-footer p10">
    <button type="button" class="btn btn-default btn-small f15 mr10" aria-label="Close" (click)="setDismiss()">창닫기</button>
    <button type="button" class="btn btn-primary btn-small f15" (click)="selectProduct()">추가하기</button>
  </div>
  