/*******************************************************************************
  설  명 : 레이아웃
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component } from "@angular/core";
import { Location } from '@angular/common';

import { CommonService, MenuNode } from '@app/service/common.service';

@Component( {
  selector: "app-home-layout",
  templateUrl: "./home.layout.component.html",
  styleUrls: ["./home.layout.component.scss"],
} )
export class HomeLayoutComponent {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/

  public checkPrintPage:boolean = false;    // 출력 페이지 여부

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public commonService: CommonService,
    public location: Location,
  ) {
  }
  
  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public ngOnInit() {

    if( this.location.path().indexOf('/print') > -1 ) {
      this.checkPrintPage = true;
    }

  }  
}
