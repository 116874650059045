import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-barcode-input',
  templateUrl: './barcode-input.component.html',
  styleUrls: ['./barcode-input.component.scss']
})
export class BarcodeInputComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public product_seq: number;
  public property_seq: number;
  public product_name: any;
  public color_seq: any;
  public color_name: any;
  public size_seq: any;
  public size_name: any;
  public barcode: any;

  public formBarcode: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formBarcode = this.formBuilder.group({
      product_seq:["", [] ],
      property_seq:["", [] ],
      product_name:["", [] ],
      color_name:["", [] ],
      size_name:["", [] ],
      barcode:["", [] ],
    });

    this.formBarcode.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formBarcode, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public authService: AuthService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
  }

  /*******************************************************************************
    설  명 : 뷰 표시 완료 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    this.formBarcode.patchValue({
      product_seq: this.product_seq,
      property_seq: this.property_seq,
      product_name: this.product_name,
      color_seq: this.color_seq,
      color_name: this.color_name,
      size_seq: this.size_seq,
      size_name: this.size_name,
      barcode: this.barcode,
    });
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.activeModal.close( this.formBarcode.value );
  }

  /*******************************************************************************
    설  명 : 엔터키 시 별표 제거 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  checkAsterisk( $event ) {
    if( $event.key == 'Enter' ) {
      const tmp = this.formBarcode.controls.barcode.value;
      const barcode = tmp.replace(/\*/g, '');

      this.formBarcode.patchValue({
        barcode: barcode
      });
    }
  }
   
}
