<ng-container *ngIf="! checkPrintPage">

<app-header></app-header>
<app-gnb></app-gnb>
<section class="contents-top">
  <div class="fl path">
    <ul>
      <li><a routerLink="/dashboard">홈</a></li>
      <li *ngIf="commonService.currMenu.parentNode != null">{{commonService.currMenu.parentNode.menu_name}}</li>
      <li>{{commonService.currMenu.menu_name}}</li>
    </ul>
  </div>
  <!-- <div class="fr right">
    <span class="board-instructions">지시사항</span>
    <span class="board-instructions-title">임직원 여러분 안녕하십니까. 대표이사님 지시사항입니다.</span>
    <span class="board-instructions-date">2020-09-01</span>
  </div> -->
  <div class="cb"></div>
</section>
<section class="contents-body">
  <router-outlet></router-outlet>
</section>

</ng-container>

<ng-container *ngIf="checkPrintPage">
   <div id="print-area">
     <router-outlet></router-outlet>
   </div>
</ng-container>