//const baseURL = (window.location.hostname === 'localhost') ? 'http://localhost:8094' : window.location.protocol + '//' + window.location.hostname;
const baseURL = (window.location.hostname === 'localhost') ? 'https://scm.bikemart.co.kr' : window.location.protocol + '//' + window.location.hostname;
const shopURL = 'https://shop.bikemart.co.kr';

export const config = {
  production: false,
  baseUrl: baseURL,
  shopUrl: shopURL,
  apiBaseUrl: baseURL + "/api",
  apiFileUploadUrl: baseURL + "/api/file/file_upload.php",
  apiImageUploadUrl: baseURL + "/api/file/image_upload.php",
  cookie_token: 'bikemarttoken',
  summernoteConfig: {
    lang : 'ko-KR',
    placeholder: '',
    tabsize: 2,
    height: 350,
    uploadImagePath: '',
    toolbar: [
        // [groupName, [list of button]]
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'link', 'video', 'hr']]
    ],
    fontNames: ['굴림', '굴림체', '돋움', '돋움체', 'Noto Sans KR', 'Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times', 'Impact', 'Lucida Grande', 'Tahoma', 'Times New Roman', 'Verdana'],
    fontNamesIgnoreCheck: [ '굴림', '굴림체', '돋움', '돋움체', 'Noto Sans KR' ]
  }
};
