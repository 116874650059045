import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-iniorder-add',
  templateUrl: './order-iniorder-add.component.html',
  styleUrls: ['./order-iniorder-add.component.scss']
})
export class OrderIniorderAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};
  public selectedItem: any = {
    seq: ""
  };
  public title: string = '추가';

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      order_name: ['', [Validators.required] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  
  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService,
  ) {
    this.buildForm();
  }
  
  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.selectedItem == 0 ) {
      this.title = '추가';
    } else {
      this.title = '수정';
      // 데이터 로딩 처리
      this.form.patchValue( this.selectedItem );
    }
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInitialOrderNameSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.orderService.setInitialOrderNameSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 초도주문 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    setInitialOrderNameEdit() {
      this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
  
      if(this.form.valid) {
        this.orderService.setInitialOrderNameEdit(this.form).then( response => {
  
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.activeModal.close(true);
            
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
  
      } else {
        this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      }
    }
  
  /*******************************************************************************
    설  명 : 창닫기
  *******************************************************************************/
  setDismiss() {
    this.activeModal.dismiss();
  }
  
}
