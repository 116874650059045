  <section class="widget-div">
    <div class="widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{totalCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>
  </section>

  <div class="fl w70p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getBrandList()">
      <i class="material-icons-outlined">search</i>
    </button>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn==''}" (click)="search.use_yn='';getBrandList()">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='1'}" (click)="search.use_yn='1';getBrandList()">사용</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='0'}" (click)="search.use_yn='0';getBrandList()">사용안함</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.purchasing_agent_yn==''}" (click)="search.purchasing_agent_yn='';getBrandList()">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.purchasing_agent_yn=='1'}" (click)="search.purchasing_agent_yn='1';getBrandList()">구매대행</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.purchasing_agent_yn=='0'}" (click)="search.purchasing_agent_yn='0';getBrandList()">대행아님</button>
    </div>

    <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="브랜드명 검색" />
  </div>

  <div class="fr w30p right">
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
    <button type="button" class="btn btn-primary btn-small ml5" (click)="brandAdd(0)">브랜드등록</button>
  </div>

  <div class="cb"></div>

  <div class="mt5" style="height:calc(100% - 95px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="brandList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
      >
    </ag-grid-angular>
  </div>

