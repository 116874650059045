import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { CategoryService } from '@app/service/category.service';

import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-product-property-add',
  templateUrl: './product-property-add.component.html',
  styleUrls: ['./product-property-add.component.scss']
})
export class ProductPropertyAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() categorySeq: any;

  public search: any = {
    category_code: ''
  };

  public sizeCategoryList: any = [];
  public colorList: any = [];
  public sizeList: any = [];

  colorGridApi: any;
  colorGridColumnApi: any;
  colorColumnDefs: any;

  sizeGridApi: any;
  sizeGridColumnApi: any;
  sizeColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private activeModal: NgbActiveModal,
    private productService: ProductService,
    private categoryService: CategoryService,
  ) {
    // ag grid 컬럼 선언
    this.colorColumnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '사용여부', field: 'use_yn', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1') return '<span class="badge badge-success f12 fn">사용</span>';
          else return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      },
      { headerName: '색상', field: 'color', width: 60, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return '<span style="display:inline-block;margin-top:6px;width:16px;height:16px;border:solid 1px #ccc;background-color:' + params.data.color + '">&nbsp;</span>';
        }
      },
      { headerName: '색상명', field: 'color_name', width: 120, cellClass: 'cp' },
      { headerName: '색상명(영문)', field: 'color_ename', width: 100, cellClass: 'cp' },
      { headerName: '색상값', field: 'color', width: 100, cellClass: 'cp' }
    ];

    // ag grid 컬럼 선언
    this.sizeColumnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '사용여부', field: 'use_yn', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1') return '<span class="badge badge-success f12 fn">사용</span>';
          else return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      },
      { headerName: '카테고리', field: 'category_name', width: 140, cellClass: 'cp' },
      { headerName: '사이즈', field: 'size_name', width: 245, cellClass: 'cp' },
      // { headerName: '사이즈 설명', field: 'size_summary', width: 500, cellClass: 'cp' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onColorGridReady(params) {
    this.colorGridApi = params.api;
    this.colorGridColumnApi = params.columnApi;
  }

  onSizeGridReady(params) {
    this.sizeGridApi = params.api;
    this.sizeGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // this.getSizeCategoryList();
    this.getColorList();
    this.getCategoryList2();
  }

  /*******************************************************************************
    설  명 : 사이즈 카테고리 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSizeCategoryList() {
    this.productService.getSizeCategoryList().then( response => {
      // console.log(response);
      if( response.ResultCode ) {
        this.sizeCategoryList = response.data;
      } else {
        this.sizeCategoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : select 전용 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList2() {
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) {
        this.sizeCategoryList = response.data;

        if( this.categorySeq != '' && this.categorySeq != null ) {
          const tmp = this.sizeCategoryList.filter( item => item.seq === this.categorySeq );
          if( tmp.length > 0 ) {
            this.search.category_code = tmp[0]['category_code'];
          }
        }
      } else {
        this.sizeCategoryList = [];
      }

      this.getSizeList();
    });
  }

  /*******************************************************************************
    설  명 : 컬러 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getColorList() {
    this.productService.getColorList({}).then( response => {
      // console.log(response);
      if( response.ResultCode ) {
        this.colorList = response.data;
      } else {
        this.colorList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 사이즈 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSizeList() {
    this.productService.getSizeList({category_code: this.search.category_code, use_yn: '1'}).then( response => {
      // console.log(response);
      if( response.ResultCode ) {
        this.sizeList = response.data;
      } else {
        this.sizeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPropertySave() {
    this.activeModal.close({
      colorNodes : this.colorGridApi.getSelectedRows(),
      sizeNodes : this.sizeGridApi.getSelectedRows()
    })

    // const colorNodes = this.colorGridApi.getSelectedRows();
    // const sizeNodes = this.sizeGridApi.getSelectedRows();

    // this.productService.setPropertySave( this.seqArray, colorNodes, sizeNodes ).then( response => {
    //   if( response.ResultCode ) {
    //     this.toastrService.success( response.ResultMessage);

    //     this.activeModal.close(true);
    //   } else {
    //     this.toastrService.error( response.ResultMessage);
    //   }
    // });
  }

  /*******************************************************************************
    설  명 : 모달창 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDismiss() {
    this.activeModal.dismiss();
  }

}
