<form [formGroup]="formSMS" class="h100">
  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">SMS 발송</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <div class="sms-image-div">
      <div class="phone-image">
        <div class="phone-title">
          SMS 발송
        </div>
        <div class="pl20 pt30 pr20 pb10">
          <inputEx name="title" [formGroup]="formSMS" [formErrors]="formErrors" placeholder="SMS 발송 제목"></inputEx>
        </div>
        <div class="pl20 pr20 pb10">
          <inputEx name="callback" [formGroup]="formSMS" [formErrors]="formErrors" placeholder="발송번호" readonly = "true"></inputEx>
        </div>
        <div>
          <textarea formControlName="message" rows="10" cols="20"></textarea>
          <div *ngIf="formErrors.message" class="error-message">{{formErrors.message}}</div>
          <div class="pl20 pr20 pt10 f12">{{getTextLength(formSMS.controls.message.value)}} / 80 글자</div>
        </div>
        <div class="pl20 pr20 pt10">
          <button type="button" class="btn btn-primary btn-small w100p" (click)="submit();">발송하기</button>
        </div>

        <div class="pl20 pr20 pt10">
          <button type="button" class="btn btn-danger btn-small w100p" (click)="autoSend()">일괄자동발송</button>
        </div>

        <hr class="db" />

        <div class="pl20 pr20">
          <h5 class="f14">사용가능 변수</h5>

          <p class="f12">
            #고객명 <!-- #고객명 #결제일 #신청일 #아이디 #만료일 -->
          </p>
        </div>
      </div>
    </div>
    <div class="sms-member-div">
      <div>
        <div class="fl">
          <h6 class="pt5 mb0 f14">발송 리스트 - {{memberList.length | comma}}건</h6>
        </div>
        <div class="fr">
          <button type="button" class="btn btn-danger btn-small" (click)="deleteMember()">삭제</button>
        </div>
        <div class="cb"></div>
      </div>

      <div class="mt5" style="height:calc(100% - 40px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsMember"
          [defaultColDef]="defaultColDef"
          [rowData]="memberList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [rowSelection]="rowSelectionMember"

          (gridReady)="onGridReadyMember($event)"
          (filterChanged)="onFilterChangedMember($event)"
        >
        </ag-grid-angular>
      </div>
    </div>

    <div class="sms-grid-div">
      <div>
        <div class="fl">
          <h6 class="pt5 mb0 f14">발송양식</h6>
        </div>
        <div class="fr">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="999" [btnGroup]="1"></btn-ag-grid-save>
        </div>
        <div class="cb"></div>
      </div>

      <div class="mt5" style="height:calc(100% - 40px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="smsList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [rowSelection]="rowSelection"

          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged($event)"
        >
        </ag-grid-angular>
      </div>
    </div>
    <div class="cb"></div>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">

    </div>
    <div class="fr w100p right ml0 mr0">
      
      <div class="fl ml10 mr10">최대 {{MAXIMUM | comma}} 건까지 동시 발송</div>

      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
