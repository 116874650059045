import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private restful: RestfulService,
  ) {
  }

  
  // 바마 메모 get
  getMemo( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'dashboard',
      version: '1.0',
      action: 'getMemo'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }



}
