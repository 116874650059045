import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-returnreq-detail-img',
  templateUrl: './order-returnreq-detail-img.component.html',
  styleUrls: ['./order-returnreq-detail-img.component.scss']
})
export class OrderReturnreqDetailImgComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
