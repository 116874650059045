import { Component, OnInit } from '@angular/core';

import * as FileSaver from 'file-saver';  
import * as XLSX from 'xlsx';  
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';  

@Component({
  selector: 'app-export-excel',
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.scss']
})
export class ExportExcelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public exportAsExcelFile(json: any[], excelFileName: string, excelSheetName: string = 'sheet1', colWidth = []): void {  
    let workSheet: XLSX.WorkSheet
    workSheet = XLSX.utils.sheet_add_aoa( workSheet, [[ excelFileName ], [] ] );
    // workSheet = XLSX.utils.json_to_sheet(json, { origin: "A3" });
    workSheet = XLSX.utils.sheet_add_json( workSheet, json, {origin: "A3"} );
    if(colWidth.length) workSheet['!cols'] = colWidth.map(e => ({ wpx: e }));
    const workbook: XLSX.WorkBook = { Sheets: { [excelSheetName]: workSheet }, SheetNames: [excelSheetName] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    this.saveAsExcelFile(excelBuffer, excelFileName);  
  }  
  private saveAsExcelFile(buffer: any, fileName: string): void {  
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});  
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);  
  }

  public excelToJson($event) {
    const target: DataTransfer = <DataTransfer>($event.target)
    const reader: FileReader = new FileReader()
    return new Promise((res, rej) => {
      reader.onload = async (e: any) => {
        const bstr: string = e.target.result
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'array'})
        const wsname: string = wb.SheetNames[0]
        const ws: XLSX.WorkSheet = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, {header: 1})
        
        const dataParsing = JSON.parse( JSON.stringify(data) )
        // const header = dataParsing.shift()
        res(dataParsing as Array<any>)
      }
      // reader.readAsBinaryString(target.files[0])
      reader.readAsArrayBuffer(target.files[0])
    })
  }

}
