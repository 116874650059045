/*******************************************************************************
  설  명 : SMS 발송 버튼 표시 및 처리
  작성일 : 2019-09-18
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ModalSmsComponent } from '@components/modal-sms/modal-sms.component';
import { ModalEmailComponent } from '@components/modal-email/modal-email.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'btn-send',
  templateUrl: './send-btn.component.html',
  styleUrls: ['./send-btn.component.scss']
})
export class SendBtnComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() sendList: any;
  @Input() type: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
  }

  /*******************************************************************************
    설  명 : SMS 발송 모달 실행
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  sendSMS() {
    const modalRef = this.modalService.open(ModalSmsComponent, options);

    modalRef.componentInstance.sendList = this.sendList;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : Email 발송 모달 실행
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  sendEmail() {
    const modalRef = this.modalService.open(ModalEmailComponent, options);

    modalRef.componentInstance.sendList = this.sendList;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

}
