import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ag-grid-html',
  templateUrl: './ag-grid-html.component.html',
  styleUrls: ['./ag-grid-html.component.scss']
})
export class AgGridHtmlComponent {

  msg : any;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  agInit(params: any): void {
    this.msg = this.sanitizer.bypassSecurityTrustHtml( params.value );
  }

}
