import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbActiveModal, NgbDateStruct, NgbDatepickerI18n, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { AngularSplitModule } from 'angular-split';
import { NgxSummernoteModule } from 'ngx-summernote';
import { LSelect2Module } from 'ngx-select2';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartsModule } from 'ng2-charts';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPhotoshopModule } from 'ngx-color/photoshop'; // <color-photoshop></color-photoshop>

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PioneerTreeModule } from '@pioneer-code/pioneer-tree';

/* 공용 파이프 모듈 */
import { PipesModule } from '@app/pipes/pipes.module';
import { AutoFocusDirective } from '@app/directive/auto-focus.directive';
import { ImageTooltipDirective } from '@app/directive/tooltip.directive';

import { AuthGuard } from '@app/service/auth.guard';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { DaumAddressComponent } from '@app/components/daum-address/daum-address.component';
import { SendBtnComponent } from '@app/components/send-btn/send-btn.component';
import { TreeViewComponent } from '@app/components/tree-view/tree-view.component';

import { HomeLayoutComponent } from '@page/layout/home.layout.component';

import { LoginComponent } from '@page/login/login.component';
import { DashboardComponent } from '@page/dashboard/dashboard.component';

import { InputExComponent } from '@components/input-ex/input-ex.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHeaderCheckboxComponent } from '@components/ag-grid-header-checkbox/ag-grid-header-checkbox.component';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridTooltipComponent } from '@components/ag-grid-tooltip/ag-grid-tooltip.component';
import { MemberFindComponent } from '@components/member-find/member-find.component';
import { BrandFindComponent } from '@components/brand-find/brand-find.component';
import { ProductFindComponent } from '@components/product-find/product-find.component';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { BarcodeInputComponent } from '@components/barcode-input/barcode-input.component';
import { PrintExcelComponent } from '@components/print-excel/print-excel.component';
import { GnbComponent } from '@page/layout/gnb/gnb.component';
import { HeaderComponent } from '@page/layout/header/header.component';

import { ProductComponent } from '@page/product/product.component';
import { CategoryComponent } from '@page/product/category/category.component';
import { ProductAddComponent } from '@page/product/product-add/product-add.component';
import { ProductMarginComponent } from '@page/product/product-margin/product-margin.component';
import { ProductPropertyComponent } from '@page/product/product-property/product-property.component';
import { ProductPropertyAddComponent } from '@page/product/product-property/product-property-add/product-property-add.component';
import { ProductSortComponent } from '@page/product/product-sort/product-sort.component';
import { ProductSellGraphComponent } from '@page/product/product-sell-graph/product-sell-graph.component';
import { ProductSoldoutComponent } from '@page/product/product-soldout/product-soldout.component';
import { OrderComponent } from '@page/order/order.component';
import { OrderOpenmarketComponent } from '@page/order/order-openmarket/order-openmarket.component';
import { OrderOpenmarketUploadComponent } from '@page/order/order-openmarket/order-openmarket-upload/order-openmarket-upload.component';
import { OrderOpenmarketFindComponent } from '@page/order/order-openmarket/order-openmarket-find/order-openmarket-find.component';
import { OrderStoreSalesComponent } from '@page/order/order-store-sales/order-store-sales.component';
import { OrderRefundComponent } from '@page/order/order-refund/order-refund.component';
import { OrderRefundEditComponent } from '@page/order/order-refund/order-refund-edit/order-refund-edit.component';
import { OrderOutofstockMemoComponent } from './page/order/order-outofstock-memo/order-outofstock-memo.component';
import { OrderIniorderComponent } from '@page/order/order-iniorder/order-iniorder.component';
import { OrderIniorderAddComponent } from '@page/order/order-iniorder/order-iniorder-add/order-iniorder-add.component';
import { OrderPaymentComponent } from '@page/order/order- payment/order-payment.component';
import { OrderIniorderPriceComponent } from '@page/order/order-iniorder/order-iniorder-price/order-iniorder-price.component';
import { OrderDeliveryComponent } from '@page/order/order-delivery/order-delivery.component';
import { OrderReturnreqComponent } from '@page/order/order-returnreq/order-returnreq.component';
import { OrderReturnreqDetailComponent } from '@page/order/order-returnreq/order-returnreq-detail/order-returnreq-detail.component';
import { OrderReturnreqDetailImgComponent } from '@page/order/order-returnreq/order-returnreq-detail/order-returnreq-detail-img/order-returnreq-detail-img.component';
import { OrderChangeComponent } from '@page/order/order-history/order-change/order-change.component';
import { OrderOutDeliberyComponent } from '@page/order/order-history/order-out-delivery/order-out-delivery.component';

import { ProductColorManagementComponent } from '@page/product/product-color-management/product-color-management.component';
import { ProductSizeManagementComponent } from '@page/product/product-size-management/product-size-management.component';
import { ProductColorManagementAddComponent } from '@page/product/product-color-management/product-color-management-add/product-color-management-add.component';
import { ProductSizeManagementAddComponent } from '@page/product/product-size-management/product-size-management-add/product-size-management-add.component';
import { ProductBrandManagementComponent } from '@page/product/product-brand-management/product-brand-management.component';
import { ProductBrandManagementAddComponent } from '@page/product/product-brand-management/product-brand-management-add/product-brand-management-add.component';
import { DateTimePickerComponent } from '@app/components/date-time-picker/date-time-picker.component';

import { ModalSmsComponent } from '@components/modal-sms/modal-sms.component';
import { ModalEmailComponent } from '@components/modal-email/modal-email.component';
import { OrderDetailComponent } from '@page/order/order-detail/order-detail.component';
import { OrderPaymentAddComponent } from '@page/order/order-detail/order-payment-add/order-payment-add.component';

import { ModalFavoriteMenuComponent } from '@page/layout/modal-favorite-menu/modal-favorite-menu.component';
import { ModalFavoriteMenuGroupComponent } from '@page/layout/modal-favorite-menu-group/modal-favorite-menu-group.component';

import { MatMenuTriggerComponent } from '@components/mat-menu-trigger/mat-menu-trigger.component';
import { MatTooltipComponent } from '@components/mat-tooltip/mat-tooltip.component';
import { ModifyMyinfoComponent } from '@components/modify-myinfo/modify-myinfo.component';
import { ProductBarcodeComponent } from '@page/product/product-barcode/product-barcode.component';
import { OrderStatusChangeComponent } from '@page/order/order-detail/order-status-change/order-status-change.component';
import { ModalDeliveryComponent } from '@page/order/modal-delivery/modal-delivery.component';
import { ExportExcelComponent } from './components/export-excel/export-excel.component';
import { BasicComponent } from './components/basic/basic.component';
import { StockComponent } from './page/stock/stock.component';

/* 달력 컴포넌트 한글*/
const I18N_VALUES = {
  ko: {
    weekdays: ['일', '월', '화', '수', '목', '금', '토'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

/* 공지 기능 설정 */
const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'right',
			distance: 12
		},
		vertical: {
			position: 'top',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: false,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 200
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}


@NgModule({
  declarations: [
    AppComponent,

    HomeLayoutComponent,

    LoginComponent,
    DashboardComponent,
    GnbComponent,
    HeaderComponent,

    AutoFocusDirective,
    ImageTooltipDirective,

    DaumAddressComponent,
    SendBtnComponent,

    InputExComponent,
    AgGridImageComponent,
    AgGridHtmlComponent,
    AgGridSaveComponent,
    AgGridExcelComponent,
    AgGridHeaderCheckboxComponent,
    AgGridButtonComponent,
    AgGridTooltipComponent,
    MemberFindComponent,
    BrandFindComponent,
    ProductFindComponent,
    ProductFindPropertyComponent,
    ProductComponent,
    CategoryComponent,
    ProductAddComponent,
    ProductMarginComponent,
    ProductPropertyComponent,
    ProductPropertyAddComponent,
    ProductSortComponent,
    ProductSellGraphComponent,
    ProductSoldoutComponent,
    OrderComponent,
    OrderOpenmarketComponent,
    OrderOpenmarketUploadComponent,
    OrderOpenmarketFindComponent,
    OrderStoreSalesComponent,
    OrderRefundComponent,
    OrderRefundEditComponent,
    OrderOutofstockMemoComponent,
    TreeViewComponent,
    ProductColorManagementComponent,
    ProductSizeManagementComponent,
    ProductColorManagementAddComponent,
    ProductSizeManagementAddComponent,
    DateTimePickerComponent,
    ModalSmsComponent,
    ModalEmailComponent,
    OrderDetailComponent,
    ModalFavoriteMenuComponent,
    ModalFavoriteMenuGroupComponent,
    ProductBrandManagementComponent,
    ProductBrandManagementAddComponent,
    OrderIniorderComponent,
    OrderIniorderAddComponent,
    OrderPaymentComponent,
    OrderIniorderPriceComponent,
    OrderDeliveryComponent,
    OrderReturnreqComponent,
    OrderChangeComponent,
    OrderOutDeliberyComponent,
    OrderReturnreqDetailComponent,
    OrderReturnreqDetailImgComponent,
    BarcodeInputComponent,
    OrderPaymentAddComponent,
    MatMenuTriggerComponent,
    ModifyMyinfoComponent,
    ProductBarcodeComponent,
    MatTooltipComponent,
    PrintExcelComponent,
    OrderStatusChangeComponent,
    ModalDeliveryComponent,
    ExportExcelComponent,
    BasicComponent,
    StockComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    ChartsModule,
    AngularSplitModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right'
    }),
    AgGridModule.withComponents([
      AgGridImageComponent,
      AgGridHtmlComponent,
      AgGridHeaderCheckboxComponent,
      AgGridButtonComponent,
      AgGridTooltipComponent,
    ]),
    PipesModule,
    LSelect2Module,
    HttpClientModule,
    DragDropModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatDividerModule,
    MatTreeModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    PioneerTreeModule,
    ColorPhotoshopModule,
    NotifierModule.withConfig(customNotifierOptions)
  ],
  providers: [
    RestfulService,
    UtilService,
    Globals,
    AuthGuard,

    AgGridImageComponent,
    AgGridHtmlComponent,
    AgGridSaveComponent,
    AgGridExcelComponent,

    NgbActiveModal,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: MAT_DATE_LOCALE, useValue: 'ko-KR'},
    NgbDatepickerConfig,
    DashboardComponent,
    ProductComponent,
    ExportExcelComponent,
    BasicComponent,
  ],
  entryComponents: [
    ModalSmsComponent,
    ModalEmailComponent
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
