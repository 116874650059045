import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from "numeral";

@Pipe({
  name: 'Numeral'
})
export class NumeralPipe implements PipeTransform {
  constructor() {
    // numeral.locale('ko');
  }

  public transform(value: any, format: string): string {
    return value || value === 0 ? numeral(value).format(format) : null;
  }
}
