  <div class="modal-header">
    <h4 class="modal-title fb mb0">{{title}}요청 상세보기</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <table class="table table-bordered table-small mt50 mb50">
    <colgroup>
      <col style="width:16%" />
      <col style="width:30%" />
      <col style="width:16%" />
      <col style="width:16%" />
      <col style="width:22%" />
    </colgroup>

    <tbody>
    <tr>
      <th>신청일자</th>
      <td>2021-02-01</td>
      <th>주문번호</th>
      <td colspan="2">111111111</td>
    </tr>    
    <tr>
      <th>고객명</th>
      <td>홍길동</td>
      <th>연락처</th>
      <td colspan="2">010-111-1234</td>
    </tr>    
    <tr>
      <th>상품</th>
      <td colspan="2" class="goods-info">
        <img src="/assets/images/samp.jpg" class="fl">
        <div class="fl">
          <p class="fb">GRAVITY FLASH LIGHT 바람막이-GRAY</p>
          <p>사이즈:S / 색상:GRAY / 1개</p>
          <p class="fb">58,000원</p>  
        </div>
      </td>
      <th>수량</th>
      <td>1</td>
    </tr>    
    <tr [ngClass]="{blind: kind1 == '3'}">
      <th>{{title}}사유</th>
      <td>불량</td>
      <th>메세지</th>
      <td colspan="2">맘에 들지않아 반품 하고자 합니다.</td>
    </tr>  
    <tr [ngClass]="{blind: kind1 !== '1'}">
      <th>환불정보</th>
      <td colspan="4">우리은행, 111-111-1111111. 홍길동</td>
    </tr>  
    <tr [ngClass]="{blind: kind1 !== '2'}">
      <th>교환배송정보</th>
      <td colspan="4">홍길동(010-1111-1111). 서울특별시 금천구 서부샛길 606 대성디폴리스 지식산업센터 , B동 1507호</td>
    </tr>          
    <tr [ngClass]="{blind: kind1 == '3'}">
      <th>첨부이미지</th>
      <td colspan="4">
        <div class="fl mr10" (click)="viewImg()">
          <p><img src="/assets/images/samp.jpg" class="fl"></p>
          <p class="ellipsis">sample000001.jpg</p>
        </div>
        <div class="fl mr10" (click)="viewImg()">
          <p><img src="/assets/images/samp.jpg" class="fl"></p>
          <p>sample01.jpg</p>
        </div>
      </td>
    </tr>        
    </tbody>
    </table>

  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button type="button" class="btn btn-danger btn-small">미승인</button>
    </div>  
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small mr5">승인</button>
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
  </div>

