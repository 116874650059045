/*******************************************************************************
  설  명 : 상품관리 - 사이즈 관리
  작성일 : 2020-11-08
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { CategoryService } from '@app/service/category.service';

import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ProductSizeManagementAddComponent } from '@page/product/product-size-management/product-size-management-add/product-size-management-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-product-size-management',
  templateUrl: './product-size-management.component.html',
  styleUrls: ['./product-size-management.component.scss']
})
export class ProductSizeManagementComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public sizeList: any = [];
  public nodeData: any = [];
  public categoryList: any = [];

  public selectedNode = {
    seq: '',
    category_code: '',
    category_name: '',
    mall_display: true,
    memo: '',
    category_type: '',
    use_yn: true,
    children: []
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;
  selectedRows: number = 0;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    searchText: '',
    category_seq: '',
    use_yn: ''
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private productService: ProductService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center',headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '사용여부', field: 'use_yn', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1') return '<span class="badge badge-success f12 fn">사용</span>';
          else return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      },
      { headerName: '카테고리', field: 'category_name', width: 200, cellClass: 'cp' },
      { headerName: '사이즈', field: 'size_name', width: 100, cellClass: 'cp' },
      { headerName: '사이즈 설명', field: 'size_summary', width: 500, cellClass: 'cp' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCategoryListN();

    this.getCategoryList2();

    this.searchInit();
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryListN() {
    this.categoryService.getCategoryListN().then( response => {
      if (response.resultCode) {
        this.nodeData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : select 전용 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList2() {
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      this.addSize( $event.data.seq );
    } else {

    }
  }


  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      category_seq: '',
      use_yn: ''
    }

    this.getSizeList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getSizeList();
    }
  }

  /*******************************************************************************
    설  명 : 사이즈 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSizeList() {
    let search = {
      searchText: '',
      use_yn: this.search.use_yn,
      category_seq: this.selectedNode.seq
    }

    this.productService.getSizeList( search ).then( response => {
      if( response.ResultCode ) {
        this.sizeList = response.data;
      } else {
        this.sizeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 사이즈 추가
    입력값 : seq = 0 추가, 0 이외의 값 수정
    리턴값 : 없음
  *******************************************************************************/
  addSize( seq: any ) {
    const modalRef = this.modalService.open(ProductSizeManagementAddComponent, options);

    modalRef.componentInstance.seq = seq;
    modalRef.componentInstance.category_seq = this.selectedNode.seq;

    modalRef.result.then((result) => {
      this.getSizeList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 사용여부 검색
    입력값 : use_yn
    리턴값 : 없음
  *******************************************************************************/
  searchUseYn( use_yn ) {
    this.search.use_yn = use_yn;

    this.getSizeList();
  }

  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {
    this.selectedNode.seq = $event.seq;
    this.selectedNode.category_code = $event.key;
    this.selectedNode.category_name = $event.name;
    this.selectedNode.use_yn = $event.use_yn;
    this.selectedNode.category_type = $event.category_type;
    this.selectedNode.memo = $event.memo;

    this.getSizeList();
  }

  /*******************************************************************************
    설  명 : 사이즈 복사
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  copySize() {
    if( this.search.category_seq == '' ) {
      this.toastrService.error( "복사해 올 카테고리를 선택하세요.", "사이즈 복사" );
      return false;
    }

    if( this.selectedNode.seq == '' ) {
      this.toastrService.error( "카테고리를 선택하세요.", "사이즈 복사" );
      return false;
    }

    if( ! confirm("사이즈를 복사해 오시겠습니까?") ) {
      return false;
    }

    this.productService.copySize( this.search.category_seq, this.selectedNode.seq ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '사이즈 복사');

        this.getSizeList();
      } else {
        this.toastrService.error( response.ResultMessage, '사이즈 복사');
      }
    });
  }

  /*******************************************************************************
    설  명 : 사이즈 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteSize() {
    if( this.selectedNode.seq == '' ) {
      this.toastrService.error( "삭제할 카테고리를 선택하세요.", "사이즈 삭제" );
      return false;
    }

    let nodes = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error( "삭제할 사이즈를 선택하세요.", "사이즈 삭제" );
      return false;
    }

    if( ! confirm("선택한 사이즈를 삭제하시겠습니까?") ) {
      return false;
    }

    let data: any = [];
    for(let node of nodes ) {
      data.push( node.seq );
    }

    this.productService.deleteSizeList( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '사이즈 삭제');

        this.getSizeList();
      } else {
        this.toastrService.error( response.ResultMessage, '사이즈 삭제');
      }
    });
  }

}
