/*******************************************************************************
  설  명 : 주문관리 - 매장판매 조회
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { BasicService } from '@app/service/basic.service';
import { OrderService } from '@app/service/order.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import * as moment from 'moment';

@Component({
  selector: 'app-order-store-sales',
  templateUrl: './order-store-sales.component.html',
  styleUrls: ['./order-store-sales.component.scss']
})
export class OrderStoreSalesComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 1000,
    storeSeq: '',
    orderSdate: '',
    orderEdate: '',
    searchField: 'old_order_uid',
    searchText: '',
    searchTerm: this.getDays(200)
  };

  public statistic: any = {
    totalAmt: 0,
    daeguMtotal: 0,
    daeguDtotal: 0,
    seoulMtotal: 0,
    seoulDtotal: 0
  }

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
    {title: '150일', value: this.getDays(150)},
    {title: '200일', value: this.getDays(200)},
    {title: '365일', value: this.getDays(365)},
  ];

  public storeList: any = [];
  public orderList: any = [];

  public totalCount: number = 0;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private toastrService: ToastrService,
    private basicService: BasicService,
    private orderService: OrderService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '주문번호', field: 'old_order_uid', width: 120, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '매장', field: 'store_name', width: 100, cellClass: 'cp center' },
      { headerName: '온오프구분', field: 'onoff_gbn', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.onoff_gbn == 'ON' ) {
            return '<span class="badge badge-success f12">온라인</span>';
          } else {
            return '<span class="badge badge-secondary f12">오프라인</span>';
          }
        }
      },
      { headerName: '주문자명', field: 'o_name', width: 150, cellClass: 'cp center' },
      { headerName: '수신자명', field: 'r_name', width: 150, cellClass: 'cp center' },
      { headerName: '주문일시', field: 'order_date', width: 150, cellClass: 'cp center' },
      { headerName: '결제금액', field: 'order_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '현금', field: 'cash_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '카드', field: 'card_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '배송일자', field: 'consign_date', width: 150, cellClass: 'cp center' },
      { headerName: '주문상태(A/S)', field: 'order_status', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.order_status == '0' ) { return '<span class="badge badge-secondary f12">주문접수</span>';
          } else if( params.data.order_status == '1' ) { return '<span class="badge badge-warning f12">결제확인</span>';
          } else if( params.data.order_status == '2' ) { return '<span class="badge badge-info f12">배송준비</span>';
          } else if( params.data.order_status == '3' ) { return '<span class="badge badge-danger f12">전체반품</span>';
          } else if( params.data.order_status == '4' ) { return '<span class="badge badge-danger f12">부분반품</span>';
          } else if( params.data.order_status == '5' ) { return '<span class="badge badge-danger f12">전체교환</span>';
          } else if( params.data.order_status == '6' ) { return '<span class="badge badge-danger f12">부분교환</span>';
          } else if( params.data.order_status == '7' ) { return '<span class="badge badge-danger f12">부분취소</span>';
          } else if( params.data.order_status == '8' ) { return '<span class="badge badge-primary f12">발송완료</span>';
          } else if( params.data.order_status == '9' ) { return '<span class="badge badge-success f12">주문완료</span>';
          } else if( params.data.order_status == '10' ) { return '<span class="badge badge-danger f12">전체취소</span>';
          } else { return '<span class="badge badge-dark f12">문의</span>'; }
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {

    this.getStoreList();

    this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.search.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? 1 : params.pageNo;
        this.search.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? 10 : params.pageRow;
        this.search.storeSeq = ( typeof params.storeSeq == 'undefined' || params.storeSeq == '' ) ? '' : params.storeSeq;
        this.search.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? 'r_name' : params.searchField;
        this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
        this.search.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
        this.search.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
        this.search.searchTerm = ( typeof params.searchTerm == 'undefined' ) ? this.getDays(200) : params.searchTerm;
        this.onSearchClick(params.searchTerm);
      }

      this.onSearchClick(this.search.searchTerm);
    });

  }

  /*******************************************************************************
    설  명 : 매장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getStoreList() {
    await this.basicService.getStoreListOff({}).then( response => {
      if( response.ResultCode ) {
        this.storeList = response.data;

      } else {
        this.toastrService.error( response.ResultMessage, '');

      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 매장판매 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderStoreSalesList() {
    this.orderService.getOrderStoreSalesList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.orderList = response.data;
        this.totalCount = response.total;
        this.statistic = response.statistic;

      } else {
        this.toastrService.error( response.ResultMessage, '');
        this.totalCount = 0;
        this.statistic = {
          totalAmt: 0,
          daeguMtotal: 0,
          daeguDtotal: 0,
          seoulMtotal: 0,
          seoulDtotal: 0
        }
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 1000,
      storeSeq: '',
      orderSdate: '',
      orderEdate: '',
      searchField: 'old_order_uid',
      searchText: '',
      searchTerm: this.getDays(200)
    };

    // 200일 검색 디폴트
    this.onSearchClick(-200);

    this.getOrderStoreSalesList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getOrderStoreSalesList();
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.orderSdate = this.calendar.getToday();
      obj.close();
    } else {
      this.search.orderEdate = this.calendar.getToday();
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;
    this.getOrderStoreSalesList();
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }

    this.getOrderStoreSalesList();
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.goDetail( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( seq: any ) {
    const url = '/order/detail?seq=' + seq;
    window.open("about:blank").location.href = url;
  }
}
