  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{widget?.total | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">판매중<span class="cnt">{{widget?.sale | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">품절<span class="cnt">{{widget?.soldout | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">승인<span class="cnt">{{widget?.okay | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">미승인<span class="cnt">{{widget?.deny | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="">
    <div class="fl w80p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProduct()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getProduct()">
        <option value="10">10줄</option>
        <option value="20">20줄</option>
        <option value="50">50줄</option>
        <option value="100">100줄</option>
        <option value="200">200줄</option>
        <option value="500">500줄</option>
      </select>

      <select [value]="search.category_code" class="form-control form-control-small ml5" (change)="setCategory($event)">
        <option value="" selected>카테고리 선택</option>
        <option *ngFor="let item of categoryList" [value]="item.category_code">{{item.category_nm}}</option>
      </select>

      <input type="text" [(ngModel)]="search.searchText" size="30" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="상품명, 상품번호, 브랜드" />

      <input type="text" size="13" readonly="readonly" id="s_date" [(ngModel)]="search.s_date" class="form-control form-control-small calendar-input ml5" placeholder="등록일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="pr5 pl5">~</span>
      <input type="text" size="13" readonly="readonly" id="e_date" [(ngModel)]="search.e_date" class="form-control form-control-small calendar-input" placeholder="등록일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status == ''}" (click)="searchParams('status', '')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status == '0'}" (click)="searchParams('status', '0')">판매중</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status == '1'}" (click)="searchParams('status', '1')">일시품절</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status == '2'}" (click)="searchParams('status', '2')">영구품절</button>
      </div>
      
      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.approval_yn == ''}" (click)="searchParams('approval_yn', '')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.approval_yn == '1'}" (click)="searchParams('approval_yn', '1')">승인</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.approval_yn == '0'}" (click)="searchParams('approval_yn', '0')">미승인</button>
      </div>

    </div>
    <div class="fr w20p right mt5">
      <!-- <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save> -->
      <ag-grid-excel [gridApi]="gridApi" selected="true" filename="상품리스트" title="상품리스트"></ag-grid-excel>
      <button type="button" class="btn btn-success btn-small ml5" (click)="soldOutProduct()">품절체크</button>
      <button *ngIf="search.product_seq" type="button" class="btn btn-danger btn-small ml5" (click)="setSoldOut()">품절변경</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="productDetail('')">상품등록</button>
    </div>
    <div class="cb"></div>
  </div>

  <div class="mt5" style="height: calc(100% - 170px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%; height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="productList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowHeight]="getRowHeight"

      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
      (columnResized)="onColumnResized($event)"
      (columnVisible)="onColumnVisible($event)"
      >
    </ag-grid-angular>
    <section class="pagination mt10">
      <ngb-pagination
        *ngIf="search.totalCount"
        [(page)]="search.pageNo"
        [pageSize]="search.pageRow"
        [collectionSize]="search.totalCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
  </div>
