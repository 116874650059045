<button
  *ngIf="params.action=='barcode'"
  class="btn btn-default btn-xsmall f12 vai" 
  [ngClass]=""
  (click)="openModalBarcode()"
>
  {{params.btnName}}
</button>

<button 
  *ngIf="params.action=='delete'"
  type="button" 
  class="btn btn-default btn-grid2 mr5"
  (click)="params.listaction(params)">
  <i class="material-icons menu-icon fl micon-create f12 ml3">&#xe5cd;</i>
</button>

<button
  *ngIf="params.action=='modify'"
  type="button" 
  class="btn btn-default btn-grid2 mr5"
  (click)="params.listaction(params)">
  <i class="material-icons menu-icon fl micon-create f12 ml3">&#xe150;</i>
</button>

<!-- <ng-container *ngIf="params.action=='openmarket'">
  <button
    type="button" 
    class="btn btn-default btn-xsmall"
    (click)="params.listaction(params)">
    찾기
  </button>
  <br/>
  <button
    type="button" 
    class="btn btn-danger btn-xsmall"
    (click)="params.listaction(params)">
    삭제
  </button>
  <br/>
  <button
    type="button" 
    class="btn btn-primary btn-xsmall"
    (click)="params.listaction(params)">
    수정
  </button>
</ng-container> -->

<ng-container *ngIf="params.action=='openmarket'">
  <button
    type="button" 
    class="btn btn-default btn-grid-icon mr5"
    (click)="params.setOpenOrderProductFind(params)">
    <i class="material-icons menu-icon fl micon-create ml2">&#xe8b6;</i>
  </button>
  <button
    type="button" 
    class="btn btn-danger btn-grid-icon mr5"
    (click)="params.setOpenOrderDelete(params)">
    <i class="material-icons menu-icon fl micon-create ml2">&#xe92b;</i>
  </button>
  <button
    type="button" 
    class="btn btn-primary btn-grid-icon"
    (click)="params.setOpenOrderSave(params)">
    <i class="material-icons menu-icon fl micon-create ml2">&#xe3c9;</i>
  </button>
</ng-container>

<button
  *ngIf="params.action=='memberInfo' && params.data.id!=''"
  type="button"
  class="btn btn-default btn-xsmall mr5 mb5" 
  (click)="params.listaction(params)"
>
  {{params.btnName}}
</button>

<button
  *ngIf="params.action=='memberSearch' && params.data.id!=''"
  type="button"
  class="btn btn-default btn-xsmall mr5 mb5" 
  (click)="params.listaction(params)"
>
  {{params.btnName}}
</button>

<button
  *ngIf = "params.action == 'orderDetail'"
  type = "button"
  class = "btn btn-default btn-xsmall mr5 mb5" 
  (click) = "params.listaction(params)"
>{{params.btnName}}</button>

<ng-container *ngIf="params.action=='productList'">
  <div class="mt15">
    <button
      type="button"
      class="btn btn-default btn-xsmall mr5 mb5 fl" 
      (click)="params.goDetailFunc(params)"
    >
      상세보기
    </button>
    <button
      *ngIf="!params.data.property_seq"
      type="button"
      class="btn btn-default btn-xsmall mr5 mb5 mt10 fl" 
      (click)="params.getPropertyListFunc(params)"
    >
      옵션보기
    </button>
    <button
      *ngIf="params.data.property_seq"
      type="button"
      class="btn btn-default btn-xsmall mr5 mb5 mt10 fl" 
      (click)="params.getProductListFunc(params)"
    >
      전체보기
    </button>
  </div>
</ng-container>
