
<ng-container *ngFor="let menuItem of menuData">

  <ng-container *ngIf="menuItem.children.length > 0">
    <button #button mat-button [matMenuTriggerFor]="levelOne" #levelOneTrigger="matMenuTrigger" (mouseenter)="levelOneTrigger.openMenu()" (mouseleave)="buttonLeave(levelOneTrigger, button)" style="z-index:1050">
      <span class="icon fa" [ngClass]="menuItem.icon"></span>
      <span>{{menuItem.name}}
        <i class="fa fa-chevron-down"></i>
      </span>
    </button>

    <mat-menu #levelOne="matMenu" direction="down" yPosition="below">
      <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(levelOneTrigger, button)">
        <ng-container *ngFor="let childL1 of menuItem.children">
          <menu *ngIf="childL1.children.length < 1" mat-menu-item (click)="onMenuClick(childL1.link_url)" class="menu-favorite">{{childL1.name}}</menu>

          <ng-container *ngIf="childL1.children.length > 0">
            <menu mat-menu-item #levelTwoTrigger="matMenuTrigger" [matMenuTriggerFor]="levelTwo" class="menu-favorite">{{childL1.name}}</menu>

            <mat-menu #levelTwo="matMenu">
              <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(levelOneTrigger,levelTwoTrigger, button)">
              <ng-container *ngFor="let childL2 of childL1.children">
                <menu mat-menu-item (click)="onMenuClick(childL2.link_url)" class="menu-favorite">{{childL2.name}}</menu>
              </ng-container>
              </span>
            </mat-menu>
          </ng-container>
        </ng-container>
      </span>
    </mat-menu>
  </ng-container>

</ng-container>