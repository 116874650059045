/*******************************************************************************
  설  명 : 사이즈 추가
  작성일 : 2020-11-08
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ColorEvent } from 'ngx-color';

import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { AuthService } from '@app/service/auth.service';
import { CategoryService } from '@app/service/category.service';

@Component({
  selector: 'app-product-size-management-add',
  templateUrl: './product-size-management-add.component.html',
  styleUrls: ['./product-size-management-add.component.scss']
})
export class ProductSizeManagementAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: any = 0;
  public category_seq: any = '';
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  public categoryList: any = [];

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      category_seq:["", [Validators.required, Validators.maxLength(50)] ],
      size_name:["", [Validators.required, Validators.maxLength(50)] ],
      size_summary:["", [] ],
      use_yn:["1", [] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 카테고리 정보를 가져온다.
    this.getCategoryList();

    // 데이터가 넘어온 경우 데이터 설정
    if( this.seq !== 0 ) {
      this.title = '수정';

      this.getSizeInfo();

    // 추가 일 경우 카테고리 코드를 설정
    } else {
      this.title = '추가';

      this.formAdd.patchValue({
        category_seq: this.category_seq
      })
    }
  }

  /*******************************************************************************
    설  명 : select 전용 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) {
        let data: any = [];
        for(let item of response.data ) {
          data.push({
            value: item.seq,
            title: item.category_nm
          });
        }

        this.categoryList = data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    let title = ( this.seq != 0 ) ? '수정' : '추가';

    if(this.formAdd.valid) {
      this.productService.setSize(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '사이즈 ' + title);

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '사이즈 ' + title);
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '사이즈 ' + title);
    }
  }

  /*******************************************************************************
    설  명 : 캐리어 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSizeInfo() {
    this.productService.getSizeInfo( this.seq ).then( response => {
      if( response.ResultCode ) {
        let data: any = {
          seq: response.data.seq,
          category_seq: response.data.category_seq,
          size_name: response.data.size_name,
          size_summary: response.data.size_summary,
          use_yn: response.data.use_yn,
        }

        this.formAdd.patchValue( data );
      }
    });
  }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("선택하신 사이즈를 삭제하시겠습니까?") ) {
      this.productService.deleteSize( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '사이즈 삭제');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '사이즈 삭제');
        }
      });
    }
  }

}
