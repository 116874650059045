/*******************************************************************************
  설  명 : 상품관리 - 상품추가
  작성일 : 2021.06.29
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { CategoryService } from '@app/service/category.service';
import { MemberService } from '@app/service/member.service';
import { BasicService } from '@app/service/basic.service';
import { ProductService } from '@app/service/product.service';

import * as $ from 'jquery';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';

import { BrandFindComponent } from '@components/brand-find/brand-find.component';
import { ProductFindComponent } from '@components/product-find/product-find.component';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { ProductPropertyAddComponent } from '@page/product/product-property/product-property-add/product-property-add.component';
import { DashboardComponent } from '@page/dashboard/dashboard.component'
import { ScmProductService } from '@app/service/scm.product.service'
import { Color } from 'ag-grid-community';

const URL = config.apiImageUploadUrl;

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public baseURL = ( config.baseUrl.substr(0,4) == 'http' ) ? config.baseUrl : 'http:' + config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public categoryList: any = [];
  public categoryList2: any = [];
  public usingCategoryList: any = [];
  public brandList: any = [];

  public soldoutSelect: any = [
    {seq: '0', name: '판매중'},
    {seq: '1', name: '일시품절'},
    {seq: '2', name: '영구품절'}
  ];

  public components: any;

  public params: any = {
    product_seq: '',
    pageNo: 1,
    pageRow: 20,
    totalCount: 0,
    opn: '',
    otc: 0,
    searchText: '',
    status: '',
    s_date: '',
    e_date: '',
    category_code: '',
    approval_yn: ''
  }

  gridApi_memo: any;
  gridColumnApi_memo: any;
  columnDefs_memo: any;

  gridApi_property: any;
  gridColumnApi_property: any;
  columnDefs_property: any;

  gridApi_gift: any;
  gridColumnApi_gift: any;
  columnDefs_gift: any;

  gridApi_relation: any;
  gridColumnApi_relation: any;
  columnDefs_relation: any;

  gridApi_set: any;
  gridColumnApi_set: any;
  columnDefs_set: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public summernoteConfig: any = {
    ...config.summernoteConfig, 
    ...{disableDragAndDrop: true}
  };

  public select2OptionsBrand = {
    multiple: false
  };

  public memoAddFunc: any;
  public addBrandFunc: any;
  public providerChangedFunc: any;
  public customerChangedFunc: any;
  public categoryChangedFunc: any;
  public mainCategoryChangedFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      product_seq: [''],
      brand_seq: ['', [Validators.required]],
      brand_name: [''],
      comment: ['', [Validators.maxLength(255)]],
      product_name: ['', [Validators.required, Validators.maxLength(255)]],
      category_seq: ['', [Validators.required]],
      add_category_seq: ['', []],
      category_list: [[]],
      display_price: ['0'],
      org_consumer_price: ['', []],
      consumer_price: ['', [Validators.required]],
      org_buy_price: ['', []],
      buy_price: ['', [Validators.required]],
      origin_country: [''],
      search_keyword: ['', [Validators.required]],
      contents: [''],
      summary: [''],
      file: [''],
      upload: [[]],
      files: [[]],
      memo: [''],

      product_code: [''],
      provider_seq: [''],
      customer_seq: [''],
      is_outside_view: [0],
      is_consignment: [0],
      min_qty: [1],
      max_qty: [''],
      is_soldout: [0, [Validators.required]],
      is_stock: [0],
      is_delivery: [0],
      is_display: [1],
      approval_yn: [0],
      use_yn: [1],
      sale_price: [0],
      supersale_price: [0],
      supersale_rate: [0],
      b2b_price: [0],
      com_price: [0],
      seller_price: [0],
      mileage_price: [0],
      mileage: [0],
      delivery_price: [0],
      gift_product_name: [''],
      is_set_product: [0],

      rowData_memo : [[]],
      rowData_property : [[]],
      // rowData_gift : [[]],
      // rowData_relation : [[]],
      // rowData_set : [[]]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private restful: RestfulService,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private memberService: MemberService,
    private basicService: BasicService,
    private productService: ProductService,
    private agGridExComponent: AgGridExComponent,
    private dashboardComponent: DashboardComponent,
    private scmProductService: ScmProductService
  ) {

    this.memoAddFunc = this.memoAdd.bind(this);
    this.addBrandFunc = this.brandAdd.bind(this);
    // this.providerChangedFunc = this.providerChanged.bind(this);
    // this.customerChangedFunc = this.customerChanged.bind(this);
    this.categoryChangedFunc = this.categoryChanged.bind(this);
    this.mainCategoryChangedFunc = this.mainCategoryChanged.bind(this);

    this.columnDefs_memo = [
      this.dashboardComponent.checkboxColumn,
      { headerName: '메모', field: 'memo', width: 1200 },
      { headerName: '등록자', field: 'writer_name', width: 150, },
      { headerName: '등록일시', field: 'write_date', width: 150, }
    ]

    const colorBox = params => `<span style="display:inline-block;margin-top:6px;width:16px;height:16px;border:solid 1px #ccc;background-color:${params.data.color}">&nbsp;</span>`

    // ag grid 컬럼 선언
    this.columnDefs_property = [
      // { headerName: '순번', field: 'rowIndex', width: 90, cellClass: 'center', valueGetter: 'node.rowIndex + 1',
      //   headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      this.dashboardComponent.checkboxColumn,
      { headerName: 'RGB', field: 'color', width: 100, cellClass: 'center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: colorBox
      },
      { headerName: '색상', field: 'color_name', width: 150, cellClass: 'left' },
      { headerName: '표시할 색상', field: 'display_color_name', width: 200, cellClass: 'cp left ag-cell-edit', editable: true, singleClickEdit: true,
        valueGetter: valueCheckUndefined,
      },
      { headerName: '사이즈', field: 'size_name', width: 150, cellClass: 'left' },
      { headerName: '표시할 사이즈', field: 'display_size_name', width: 200, cellClass: 'cp left ag-cell-edit', editable: true, singleClickEdit: true,
        valueGetter: valueCheckUndefined,
      },
      // { headerName: '화면표시가', field: 'display_price', cellClass: 'right ag-cell-edit', editable: true, singleClickEdit: true,
      //   valueFormatter: currencyFormatter,
      //   valueParser: numberParser,
      // },
      { headerName: '소비자가', field: 'consumer_price', width: 150, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
        valueFormatter: currencyFormatter,
        valueParser: numberParser,
      },
      { headerName: '공급가', field: 'buy_price', width: 150, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
        valueFormatter: currencyFormatter,
        valueParser: numberParser,
      },
      { headerName: '품절여부', field: 'status', width: 150, 
        cellClass: function(params) {
          // if( parseInt(params.data.seq) > 0 ) 
          return 'cp center ag-cell-edit';
          // else return 'cp center';
        }, 
        editable: function(params) {
          // if( parseInt(params.data.seq) > 0 ) 
          return true;
          // else return false;
        }, 
        singleClickEdit: true,
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.soldoutSelect,
          cellRenderer: 'agGridHtmlComponent'
        },      
        valueGetter(params: any) {
          if ( params.data.status == '0' ) {
            return '<span class="badge badge-success f12">판매중</span>';
          } else if ( params.data.status == '1' ) {
            return '<span class="badge badge-warning f12">일시품절</span>';
          } else if ( params.data.status == '2' ) {
            return '<span class="badge badge-danger f12">영구품절</span>';
          } else {
            return '';
          }
        }
      },
      // { headerName: '', field: '', cellClass: 'center', width: 150, 
      //   cellRendererFramework: AgGridButtonComponent,
      //   cellRendererParams: {
      //     action: 'orderDetail',
      //     btnName: '품절여부 변경',
      //     listaction: $event => this.setSoldOut($event)
      //   }
      // }
    ]

    // this.columnDefs_gift = [
    //   this.dashboardComponent.checkboxColumn,
    //   { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center', width: 100, cellRenderer: 'agGridImageComponent' },
    //   { headerName: '색상', field: 'color', cellClass: 'cp center', width: 50, cellRenderer: 'agGridHtmlComponent',
    //     valueGetter: colorBox
    //   },
    //   { headerName: '색상', field: 'color_name', cellClass: 'cp center', width: 70 },
    //   { headerName: '사이즈', field: 'size_name', cellClass: 'cp center', width: 70 },
    //   { headerName: '상품명', field: 'product_name', width: 300, cellClass: 'cp' },
    //   { headerName: '제한수량', field: 'max_count', width: 80, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
    //     cellEditor: 'numericCellEditor',
    //     valueFormatter: currencyFormatter,
    //     valueParser: numberParser,
    //   },
    //   { headerName: '시작일자', field: 'sdate', width: 100, cellClass: 'cp center ag-cell-edit', editable: true, singleClickEdit: true, cellEditor: 'datePicker' },
    //   { headerName: '종료일자', field: 'edate', width: 100, cellClass: 'cp center ag-cell-edit', editable: true, singleClickEdit: true, cellEditor: 'datePicker' },
    //   { headerName: '메모', field: 'memo', width: 200, cellClass: 'cp ag-cell-edit', editable: true, singleClickEdit: true }
    // ]

    // this.columnDefs_relation = [
    //   { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
    //     headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    //   { headerName: '상품코드', field: 'relation_product_code', width: 70, cellClass: 'cp center' },
    //   { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center', width: 100, cellRenderer: 'agGridImageComponent' },
    //   { headerName: '상품명', field: 'relation_product_name', width: 400, cellClass: 'cp' },
    //   { headerName: '할인금액', field: 'discount_amt', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
    //     cellEditor: 'numericCellEditor',
    //     valueFormatter: currencyFormatter,
    //     valueParser: numberParser,
    //   },
    //   { headerName: '할인율', field: 'discount_rate', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
    //     cellEditor: 'numericCellEditor',
    //     valueFormatter: currencyFormatter,
    //     valueParser: numberParser,
    //   },
    //   { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
    // ];

    // this.columnDefs_set = [
    //   { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
    //     headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    //   { headerName: '상품코드', field: 'part_product_code', width: 70, cellClass: 'cp center' },
    //   { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center', width: 100, cellRenderer: 'agGridImageComponent' },
    //   { headerName: '상품명', field: 'part_product_name', width: 400, cellClass: 'cp' },
    //   { headerName: '할인금액', field: 'discount_amt', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
    //     cellEditor: 'numericCellEditor',
    //     valueFormatter: currencyFormatter,
    //     valueParser: numberParser,
    //   },
    //   { headerName: '할인율', field: 'discount_rate', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
    //     cellEditor: 'numericCellEditor',
    //     valueFormatter: currencyFormatter,
    //     valueParser: numberParser,
    //   },
    //   { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
    // ];

    this.defaultColDef = this.dashboardComponent.defaultColDef
    this.rowSelection = 'multiple';
    this.noRowsTemplate = this.dashboardComponent.noRowsTemplate
    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor(),
      btnCellRenderer: this.agGridExComponent.getBtnCellRenderer(),
      singleClickEditRenderer: this.agGridExComponent.getsingleClickEditRenderer()
    }

    function currencyFormatter(params: any) {
      const str = String(params.data[ params.column.colId ]);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }

    function valueCheckUndefined(params: any) {
      if( typeof params.data[ params.column.colId ] == 'undefined' || params.data[ params.column.colId ] === null ) {
        return '';
      } else {
        return params.data[ params.column.colId ];
      }
    }

    function numberParser(params) {
      return Number(params.newValue);
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm()

    // select2 용 카테고리 가져오기
    this.getCategoryList();

    // 입점업체전용 카테고리 가져오기
    this.getProductCategoryList();

    // 입력 파라미터 처리
    this.activatedRoute.queryParams.subscribe( async params => {
      this.form.patchValue({ product_seq: params.product_seq })

      if( params.product_seq ) {
        // this.toastrService.show('읽기 모드입니다.')
        // this.toastrService.info('메모 등록 외의 상품 수정은 관리자에게 문의하세요.')

        // 상품 상세정보 가져오기
        this.getProductDetail()
      }
    })

    this.activatedRoute.queryParams.subscribe( async params => {
      this.params = {
        product_seq: ( typeof params.product_seq == 'undefined' || params.product_seq == '' ) ? '' : params.product_seq,
        pageNo: ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo,
        pageRow: ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow,
        totalCount: ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount,
        opn: ( typeof params.opn == 'undefined' || params.opn == '' ) ? '' : params.opn,
        otc: ( typeof params.otc == 'undefined' || params.otc == '' ) ? '0' : params.otc,
        s_date : params.s_date,
        e_date : params.e_date,
        status : params.status || '',
        searchText : params.searchText,
        category_code : params.category_code,
        approval_yn: params.approval_yn || '',
      }

      this.form.patchValue({ product_seq: params.product_seq });

      if( params.product_seq ) {
        // 상품 상세정보 가져오기
        this.getProductDetail()
      }
    })

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    let defaultImage = true
    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const _F = this.form.controls.files.value
        _F.push({
          ...response,
          url: this.baseURL + response.url,
          origin_filename: response.origin_filename,
          filesize: response.size,
          thumbnail_url: this.baseURL + response.thumbnail,
          thumbnail_result: response.thumbnail_result,
          is_default : defaultImage
        })
        defaultImage = false

        this.form.patchValue({
          files: _F
        });

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.productService.setDeleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.form.controls.upload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 입점업체 전용 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductCategoryList() {
    this.scmProductService.getProductCategoryList().then( response => {
      if (response.ResultCode) {
        let data: any = [];

        data.push({
          value: '0',
          title: '해당없음'
        });

        for(let item of response.data ) {
          data.push({
            value: item.category_seq,
            title: item.category_name
          });
        }

        this.usingCategoryList = data;
      } else {
        this.usingCategoryList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : select 전용 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) {
        let data: any = [];

        for(let item of response.data ) {
          data.push({
            value: item.seq,
            title: item.category_nm
          });
        }

        this.categoryList = data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 상세정보 가져오기
  *******************************************************************************/
  getProductDetail() {
    this.scmProductService.getProductDetail({
      mem_no : this.dashboardComponent.getUser(),
      product_seq : this.form.value.product_seq
    }).then(response => {
      if(response.ResultCode) {
        const _R = response.data.shift()
        this.form.patchValue({
          ..._R,
          // brand_seq: _R['brand_seq'],
          // brand_name: _R['brand_name'],
          // comment: _R['comment'],
          // product_name: _R['product_name'],
          category_seq: _R['category_seq'] || '0',
          // category_list: response['ex_category'],
          // gift_product_name: _R['gift_product_name'],
          // is_set_product: _R['is_set_product'],
          // approval_yn: _R['approval_yn'],
          // display_price: _R['display_price'],
          org_consumer_price: _R['consumer_price'],
          // consumer_price: _R['consumer_price'],
          org_buy_price: _R['buy_price'],
          // buy_price: _R['buy_price'],
          // origin_country: _R['origin_country'],
          // search_keyword: _R['search_keyword'],
          // contents: _R['contents'],
          // summary: _R['summary'],
          file: '',
          upload: response['image'],
          files: [],
          
          rowData_memo : response.memo,
          rowData_property : response.property
        })

        // this.rowData_memo = response.memo
        // this.rowData_property = response.property
        // this.rowData_gift = response.gift
        // this.rowData_relation = response.relation
        // this.rowData_set = response.set

        // this.form.patchValue({
        //   category_list : response.ex_category.map(e => ({
        //     id : e['category_seq'],
        //     text : e['category_name']
        //   }))
        // })
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 상품등록
  *******************************************************************************/
  submit(_continue = false) {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    // const delDot = (string: any) => parseInt(string && string.replace(/,/g, ''))

    // const checkPrice = (_DP, _CP, _BP) => {
    //   if(_DP < _CP) {
    //     this.toastrService.warning('소비자가는 화면표시가보다 낮아야합니다.')
    //     return false
    //   } else if(_CP < _BP) {
    //     this.toastrService.warning('공급가는 소비자가보다 낮아야합니다.')
    //     return false
    //   } else return true
    // }

    if(
        this.form.controls.product_seq.value !== '' &&
        (
          this.form.controls.org_consumer_price.value != this.form.controls.consumer_price.value ||
          this.form.controls.org_buy_price.value != this.form.controls.buy_price.value 
        )
      ) {
        if( confirm('금액 변경 시 미승인 상태로 저장되고 다시 승인을 받아야 쇼핑몰에 노출됩니다.') ) {
        } else {
          return false;
        }
    }

    if (this.form.valid) {
      const _V = this.form.value

      if(!_V['rowData_property'].length) {
        this.toastrService.warning('색상 및 사이즈가 없습니다.')
        return
      } else if(!_V['files'].length && !_V['upload'].length) {
        this.toastrService.warning('이미지가 없습니다.')
        return
      }

      // const _DP = delDot(_V['display_price'])
      const _CP = this.deleteComma(_V['consumer_price'])
      const _BP = this.deleteComma(_V['buy_price'])
      // if(!checkPrice(_DP, _CP, _BP)) return

      const _RD_PY = []
      for(let i = 0; i < _V['rowData_property'].length; i++) {
        const e = _V['rowData_property'][i]

        // const _DP = delDot(e['display_price'])
        const _CP = this.deleteComma(e['consumer_price'])
        const _BP = this.deleteComma(e['buy_price'])
        // if(!checkPrice(_DP, _CP, _BP)) return

        _RD_PY.push({
          ...e,
          // display_price : _DP,
          display_price : _CP,
          consumer_price : _CP,
          buy_price : _BP,
          // display_color_name : e['color_name'],
          // display_size_name : e['size_name']
        })
      }

      if( _V.hasOwnProperty('rowData_property') ) _V['rowData_property'] = [];

      this.scmProductService.setProduct({
        ..._V,
        product_seq : _V['product_seq'],
        mem_no : this.dashboardComponent.getUser(),
        comment : _V['comment'],
        product_name : _V['product_name'],
        brand_seq : _V['brand_seq'],
        is_soldout : _V['is_soldout'],
        // min_qty : _V['min_qty'],
        // max_qty : _V['max_qty'],
        origin_country : _V['origin_country'],
        search_keyword : _V['search_keyword'],
        contents : _V['contents'],
        summary : _V['summary'],
        approval_yn : _V['approval_yn'],
        // display_price : _DP,
        display_price : _CP,
        org_consumer_price : _V['org_consumer_price'],
        consumer_price : _CP,
        org_buy_price : _V['org_buy_price'],
        buy_price : _BP,
        
        // category_seq : _V['category_seq']['id'],
        category_seq : _V['category_seq'],
        add_category_seq: _V['add_category_seq'],
        // category_list : _V['category_list'].map(e => e['id']),

        propertyList : JSON.stringify(_RD_PY),
        memoList : _V['rowData_memo'].map(e => e['memo']),
        
        file: _V['file'],
        upload: _V['upload'],
        files: _V['files']

      }).then(response => {
        if(response.ResultCode) {
          this.toastrService.success(response.ResultMessage)
          
          if(_continue) {
            this.continue()
          } else {
            if( this.form.controls.product_seq.value == '' ) {
              this.router.navigate(
                ['/product/list/add'],
                {
                  relativeTo: this.activatedRoute,
                  queryParams: {product_seq : response.productSeq},
                  queryParamsHandling: ''
                }
              );
            } else {
              this.getProductDetail();
            }
          }

        } else this.toastrService.error(response.ResultMessage)
      })
    } else this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.')
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyMemo(params) {
    this.gridApi_memo = params.api;
    this.gridColumnApi_memo = params.columnApi;
  }

  onGridReadyProperty(params) {
    this.gridApi_property = params.api;
    this.gridColumnApi_property = params.columnApi;
  }

  onGridReadyGift(params) {
    this.gridApi_gift = params.api;
    this.gridColumnApi_gift = params.columnApi;
  }

  onGridReadyRelation(params) {
    this.gridApi_relation = params.api;
    this.gridColumnApi_relation = params.columnApi;
  }

  onGridReadySet(params) {
    this.gridApi_set = params.api;
    this.gridColumnApi_set = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 메모 input에서 엔터키 입력 시
  *******************************************************************************/
  setKeyPressMemo( $event ) {
    if( $event.key == 'Enter' ) this.memoAdd()
  }

  /*******************************************************************************
    설  명 : 메모 추가
  *******************************************************************************/
  memoAdd() {
    const _M = this.form.value['memo']
    const _P = this.form.value['product_seq']
    if(!_M) return
    
    if(_P) {
      this.scmProductService.setMemo({
        product_seq : _P,
        memo : _M,
        mem_no : this.dashboardComponent.getUser()
      }).then(response => {
        if(response.ResultCode) this.toastrService.success(response.ResultMessage)
        else this.toastrService.error(response.ResultMessage)
        this.getProductDetail()
      })
    } else {
      this.form.patchValue({
        rowData_memo : [
          ...this.form.value['rowData_memo'], 
          {
            memo : _M,
            writer_name : '임시등록',
            write_date : '임시등록'
          }
        ]
      })
    }
  }

  /*******************************************************************************
    설  명 : 브랜드 검색
  *******************************************************************************/
  brandAdd() {
    const modalRef = this.modalService.open(BrandFindComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
        this.form.patchValue({
          brand_seq: result.data.seq,
          brand_name: result.data.brand_name
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 속성 추가
  *******************************************************************************/
  propertyAdd() {
    const modalRef = this.modalService.open(ProductPropertyAddComponent, optionsXL);

    modalRef.componentInstance.categorySeq = this.form.controls.category_seq.value;

    modalRef.result.then((result) => {
      if(!result) return 

        const _color = result['colorNodes'].map(e => ({
          color : e['color'],
          color_ename : e['color_ename'],
          color_name : e['color_name'],
          display_color_name : e['color_name'],
          color_seq : e['seq']
        }))
        const _size = result['sizeNodes'].map(e => ({
          size_seq : e['seq'],
          size_name : e['size_name'],
          display_size_name : e['size_name'],
          size_summary : e['size_summary']
        }))
        const _colorSize = []
        if( _color.length > 0 && _size.length < 1 ) {
          _color.forEach(e => {
            _colorSize.push({
              ...e,
              // display_price : this.form.value['display_price'],
              consumer_price : this.form.value['consumer_price'],
              buy_price : this.form.value['buy_price'],
              status : 0
            });
          });
        } else if( _color.length < 1 && _size.length > 0 ) {
          _size.forEach(_e => {
            _colorSize.push({
              ..._e,
              // display_price : this.form.value['display_price'],
              consumer_price : this.form.value['consumer_price'],
              buy_price : this.form.value['buy_price'],
              status : 0
            });
          });
        } else {
          _color.forEach(e => {
            _size.forEach(_e => {
              _colorSize.push({
                ...e,
                ..._e,
                // display_price : this.form.value['display_price'],
                consumer_price : this.form.value['consumer_price'],
                buy_price : this.form.value['buy_price'],
                status : 0
              });
            });
          });
        }

        this.form.patchValue({
          rowData_property : [
            ...this.form.value.rowData_property,
            ..._colorSize
          ]
        })
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 속성 삭제
  *******************************************************************************/
  setPropertyDelete() {
    const selectedRowNodes = this.gridApi_property.getSelectedNodes();

    if( selectedRowNodes.length < 1 ) {
      this.toastrService.error( '삭제할 데이터를 선택하시기 바랍니다.', '' );
    } else {
      if ( confirm('삭제하시겠습니까?') ) {
        const data: any = [];
        const rows: any = [];
        let tmp: any = this.form.value.rowData_property;

        selectedRowNodes.forEach(node => {
          if( typeof node.data.seq != 'undefined' && node.data.seq !== '' ) {
            data.push( node.data );
          }

          rows.push( node.data );
          tmp.splice(node.rowIndex, 1);
        });

        this.form.get('rowData_property').setValue(tmp);        
        this.gridApi_property.applyTransaction({ remove: rows });

        if( data.length > 0 ) {
          this.productService.setPropertyDelete( data ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');

              this.getProductDetail();
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });
        }
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 품절처리
  *******************************************************************************/
  setSoldOut($event) {
    // const row = this.gridApi_property.getSelectedRows().map(e => e['seq'])
    if(!this.form.value['product_seq']) {
      this.toastrService.error('상품등록 후 가능합니다.')
      return
    }
    const row = [$event.data.seq]
    if(!row.length) {
      this.toastrService.error('선택한 데이터가 없습니다.')
      return
    }
    if(!confirm('품절여부를 변경합니다. \r\n품절은 판매중으로, 판매중은 품절로 변경됩니다. \r\n실행하시겠습니까?')) return

    this.scmProductService.setSoldOutProperty({
      mem_no : this.dashboardComponent.getUser(),
      list : row
    }).then(response => {
      if(response.ResultCode) {
        this.toastrService.success(response.ResultMessage)
        this.ngOnInit()
      } else this.toastrService.error(response.ResultMessage)
    })

  }

  /*******************************************************************************
    설  명 : 저장하고 계속하기
  *******************************************************************************/
  continue() {
    const _brand_seq = this.form.value['brand_seq']
    const _brand_name = this.form.value['brand_name']
    const _category_seq = this.form.value['category_seq']

    this.buildForm()
    this.form.patchValue({
      brand_seq: _brand_seq,
      brand_name: _brand_name,
      category_seq: _category_seq
    })
  }

  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertUploadImage( file ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      this.scmProductService.setProductImageDefault( file ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertFilesImage( file: any ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      let files: any = this.form.controls.files.value;
      files.map((obj: any) => obj.is_default = '0');

      file.is_default = '1';
    }
  }
  
  /*******************************************************************************
    설  명 : 색상 및 사이즈 변경 시 자동 저장
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStopped( event: any ) {
    if( event.data.seq === undefined ) return false;

    this.scmProductService.setPropertySave( event.data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');

        this.getProductDetail();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 리스트로 돌아가기
  *******************************************************************************/
  goList() {
    this.params.product_seq = '';
    if( this.params.opn !== '' ) {
      this.params.pageNo = this.params.opn;
      this.params.totalCount = this.params.otc;
      this.params.opn = '';
      this.params.otc = 0;
    }

    this.router.navigate(
      ['/product/list'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 카테고리 변경시 처리
  *******************************************************************************/
  categoryChanged( e: any ): void {
    this.form.patchValue({
      category_list: e
    });
  }
  mainCategoryChanged( e: any ): void {
    this.form.patchValue({
      category_seq: e
    })
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  deleteComma( value: any ) {
    let str = String(value);
    return str.replace(/,/g, '');
  }
  
}
