import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 조직도 리스트 가져오기
  getGroupEmployeeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getGroupEmployeeList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 조직도 리스트 가져오기 (TreeView)
  getGroupEmployeeListN(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getGroupEmployeeListN'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 직원 리스트 가져오기
  getEmployeeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getEmployeeList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사원 정보 가져오기
  getEmployeeInfo( mem_no: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getEmployeeInfo'
      }, {
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사원 정보 검색
  getEmployeeInfoId( id: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getEmployeeInfoId'
      }, {
        id: id
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사원 정보 저장하기
  setEmployee( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'setEmployee'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사원 정보 삭제하기
  deleteEmployee( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'deleteEmployee'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 조직도 리스트 가져오기 - select 태그용
  getGroupList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getGroupList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 부서 리스트 가져오기
  getDepartmentList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getDepartmentList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사용자(직원) 리스트
  getMemberList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getMemberList'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }


  // 직원 조직도 셀렉트박스용 리스트 가져오기
  getGroupEmployeeListForSelect(): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'employee',
      version: '1.0',
      action: 'getGroupEmployeeListForSelect'
    }, {}
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
 }

  // 근태현황집계표 가져오기
  getTNAStatisticsList( mem_no, year ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getTNAStatisticsList'
      }, {
        mem_no: mem_no,
        year: year
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 근태현황집계표 연도 가져오기
  getTNAStatisticsYearList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getTNAStatisticsYearList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
