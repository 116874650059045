<div class="modal-header">
  <h4 class="modal-title fb mb0">{{marketList[form.controls.type.value]}} 배송목록업로드</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

    <table class="table table-bordered table-small mb0">
    <caption class="none">업로드 정보</caption>
    <colgroup>
      <col style="width:20%;" />
      <col style="width:80%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="file">파일등록</label></th>
      <td>
        <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="type">업체선택</label></th>
      <td>
        <inputEx type="select" name="type" [formGroup]="form" [formErrors]="formErrors" [data]="typeList"></inputEx>
      </td>
    </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="2">
          <h4>※ 등록전 다음 사항을 확인해 주세요</h4>
          <ol>
            <li>해당 오픈마켓 발주처리할 전체목록을 다운로드.</li>
            <li>엑셀 파일의 첫번째 필드를 삽입한후 첫행 첫필드에 0을 입력한다.</li>
            <li>엑셀을 다른 형식으로 저장하기 -> 탭으로 구분하는 확장자 .txt 문서로 변환한다.</li>
            <li>파일 형식이 txt 이면서 탭으로 구분되어 있고 필드가 탭으로 구분되는가?</li>
            <li><span class="cred">업로드 파일이름은 한글이 지원되지 않습니다.</span> 영문과 숫자로 하세요.</li>
            <li><span class="cred">네이버는 반드시 엑셀파일 시트명을 '발송처리'로 이름지어주세요.</span></li>
          </ol>
        </td>
      </tr>
    </tfoot>
    </table>

  </form>

</div>

<div class="modal-footer">
  <div class="fl w50p">
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    <button type="button" class="btn btn-primary btn-small ml5" (click)="setOpenmarketConsignSave()">저장하기</button>
  </div>
</div>
