  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">미처리<span class="cnt">{{statistic?.standbyCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="fl w70p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
      <i class="material-icons-outlined">search</i>
    </button>

    <input type="text" size="15" class="form-control form-control-small calendar-input ml5" placeholder="결제시작일" />
    <span class="ml5 mr5">~</span>
    <input type="text" size="15" class="form-control form-control-small calendar-input" placeholder="결제종료일" />

    <select name="search_field" id="search_field" class="form-control form-control-small ml5">
      <option value="name">주문자</option>
      <option value="id">ID</option>
      <option value="memo">메모</option>
      <option value="session">세션</option>
      <option value="fid">fid</option>
      <option value="model">model</option>
      <option value="return_num">환불금액</option>
    </select>

    <input type="text" size="15" class="form-control form-control-small ml5" placeholder="검색어" />
  </div>

  <div class="fr w30p right">
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
    <button type="button" class="btn btn-danger btn-small ml5" (click)="refundEdit(0)">수정(향후삭제)</button>
  </div>

  <div class="cb"></div>

  <div class="mt5" style="height:calc(100% - 80px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="refundList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
