/*******************************************************************************
  설  명 : 대시보드
  작성일 : 2021.06.22
  작성자 : 서기정
  접속URL : /dashboard
*******************************************************************************/

import { Component, NgZone, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'

import { AuthService } from '@app/service/auth.service'
import { UtilService } from '@app/service/util.service'
import { ProductService } from '@app/service/product.service'
import { CategoryService } from '@app/service/category.service'
import { DashboardService } from '@app/service/dashboard.service'

import { AgGridImageComponent } from '@app/components/ag-grid-image/ag-grid-image.component'
import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component'
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component'
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component'
import { AgGridExComponent } from '@app/components/ag-grid-excomponent/ag-grid-excomponent'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  rowData = []
  gridApi: any
  gridColumnApi: any
  columnDefs: any

  defaultColDef: any = {
    sortable: true,
    filter: false,
    resizable: true,
    // flex: true
  }
  domLayout: any
  rowSelection: any = 'single'
  noRowsTemplate: string = '검색된 데이터가 없습니다.'

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  }
  checkboxColumn = {
    headerName: '', 
    field: '', 
    width: 50, 
    headerCheckboxSelection: true, 
    headerCheckboxSelectionFilteredOnly: true, 
    checkboxSelection: true, 
    sortable: false, 
    filter: false, 
    resizable: false, 
    flex: 0 
  }
  valueGetter_num = (params) => (params.data[params.colDef.field] || 0).toLocaleString();
  components = {
    numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
    datePicker: this.agGridExComponent.getDatePicker(),
    selectCellEditor: this.agGridExComponent.getSelectCellEditor()
  }

  page = {
    index : 1,
    size : 1
  }
  
  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilService: UtilService,
    private productService: ProductService,
    private calendar: NgbCalendar,
    private categoryService: CategoryService,
    private toastrService: ToastrService,
    private agGridSaveComponent: AgGridSaveComponent,
    private agGridExcelComponent: AgGridExcelComponent,
    private agGridExComponent: AgGridExComponent,
    private dashboardService: DashboardService,
    private NgZone: NgZone,
  ) {
    this.columnDefs = [
      { headerName: '구분', field: 'malltype', 
        cellClass: function(params) {
          if( params.data.mng_out_seq !== null ) return 'cp center';
          else return 'center status7';
        }
      },
      // { headerName: '주문번호', field: 'order_seq', width: 110, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter(params: any) {
      //     if ( params.data.status == '0' ) {
      //       return '<span class="badge badge-secondary f12">대기상품</span>';
      //     } else if ( params.data.status == '1' ) {
      //       return '<span class="badge badge-success f12">판매중</span>';
      //     } else if ( params.data.status == '2' ) {
      //       return '<span class="badge badge-warning f12">안전재고위험</span>';
      //     } else if ( params.data.status == '3' ) {
      //       return '<span class="badge badge-danger f12">재고없음</span>';
      //     } else {
      //       return '';
      //     }
      //   }
      // },
      { headerName: '주문번호', field: 'order_seq',
        cellClass: function(params) {
          if( params.data.mng_out_seq !== null ) return 'cp center';
          else return 'center status7';
        }
      },
      { headerName: '메모 내용', field: 'comment', width: 1000, flex: 0,
        cellClass: function(params) {
          if( params.data.mng_out_seq !== null ) return 'cp left';
          else return 'left status7';
        }      
      },
      { headerName: '작성일', field: 'write_date',
        cellClass: function(params) {
          if( params.data.mng_out_seq !== null ) return 'cp center';
          else return 'center status7';
        }
      }
    ]
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getMemo()
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = () => 50

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
  }

  /*******************************************************************************
    설  명 : row 클릭시 해당 주문으로 이동
  *******************************************************************************/
  onRowClicked($event) {
    if( $event.data.mng_out_seq !== null ) {
      this.goOrderDetail($event.data['mng_out_seq']);
    }
  }

  goOrderDetail(seq) {
    this.NgZone.run(() => 
      this.router.navigate(
        ['/order/detail'], {
          relativeTo: this.activatedRoute,
          queryParams: {mng_out_seq : seq}
        }
      )
    )
  }

  onCellDoubleClicked($event) {}

  /*******************************************************************************
    설  명 : 인덱스 처리
  *******************************************************************************/
  pageChange($event) {
    this.page.index = $event
    this.getMemo()
  }

  /*******************************************************************************
    설  명 : 사용자 회원번호 가져오기
  *******************************************************************************/
  getUser() {
    let user
    this.authService.getLoginInfo.subscribe(e => {
      user = e['mem_no']
      // user = 973506
    }).unsubscribe()
    // this.toastrService.show('임시 로그인 ID : psb2')
    return user
  }

  /*******************************************************************************
    설  명 : 메모 가져오기
  *******************************************************************************/
  getMemo() {
    this.dashboardService.getMemo({
      mem_no : this.getUser(),
      index : this.page.index - 1
    }).then(response => {
      if(response.ResultCode) {
      } else this.toastrService.error(response.ResultMessage)

      this.rowData = response.data || []
      this.page.size = response.count || 0
    })
  }



}
