import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-order-iniorder-price',
  templateUrl: './order-iniorder-price.component.html',
  styleUrls: ['./order-iniorder-price.component.scss']
})
export class OrderIniorderPriceComponent implements OnInit {

  public search = {}
  public productList: any = [];

  public form: FormGroup;
  public formErrors = {};


  buildForm(): void {
    this.form = this.formBuilder.group({
      method: ['1', [Validators.required] ],
      price_kind: ['', [Validators.required] ],
      price: ['', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService
  ) {
    this.buildForm();
   }

  ngOnInit(): void {
  }

  priceChangeSave() {
    if( this.form.value.price_kind == '' && this.form.value.price == '' ) {
      this.toastrService.error("금액을 입력해주세요.");

    } else {
      this.activeModal.close(this.form);
    }
  }
}
