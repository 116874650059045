import { Component, OnInit } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbDateStruct, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@app/service/util.service';

import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { OrderReturnreqDetailComponent } from '@page/order/order-returnreq/order-returnreq-detail/order-returnreq-detail.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-returnreq',
  templateUrl: './order-returnreq.component.html',
  styleUrls: ['./order-returnreq.component.scss']
})
export class OrderReturnreqComponent implements OnInit {


  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    sdate: '',
    edate: '',
    state: '',
    state1: ''
  };
  public orderList: any = [];
  public components: any;
  
  // 그리드 관련 선언

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private utilService: UtilService,
    private calendar: NgbCalendar, 
    private agGridExComponent: AgGridExComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {
        headerName: '',
        field: '',
        width: 40,
        cellClass: 'cp center',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },       
      { headerName: '구분', field: 'kind', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.kind == '1' ) {
            return '<span class="badge badge-success f12">반품</span>';
          } else if ( params.data.kind == '2' ) {
            return '<span class="badge badge-primary f12">교환</span>';
          } else if ( params.data.kind == '3' ) {
            return '<span class="badge badge-danger f12">취소</span>';
          }      
        }
      },
      { headerName: '요청일자', field: '', width: 100, cellClass: 'cp' },
      { headerName: '주문번호', field: '', width: 100, cellClass: 'cp' },
      { headerName: '주문일자', field: '', width: 100, cellClass: 'cp' },
      { headerName: '고객명', field: '', width: 200, cellClass: 'cp' },
      { headerName: '연락처', field: '', width: 200, cellClass: 'cp' },
      { headerName: '상품명', field: '', width: 200, cellClass: 'cp' },
      { headerName: '사이즈', field: '', width: 100, cellClass: 'cp' },
      { headerName: '칼라', field: '', width: 100, cellClass: 'cp' },
      { headerName: '수량', field: '', width: 50, cellClass: 'cp' },
      { headerName: '요청사유', field: '', width: 120, cellClass: 'cp' },
      { headerName: '', field: '', width: 100, cellClass: 'cp center',
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          label: '상세보기',
          btnClass: 'btn btn-primary btn-grid1 f12',
          clicked: this.orderDetail.bind(this),
        },
      }, 
    ];  

    this.orderList = [{kind:'1'},{kind:'2'},{kind:'3'}];    

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';  
  
    this.components = {
      btnCellRenderer: this.agGridExComponent.getBtnCellRenderer()
    };     
  }

  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 상세보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderDetail(data: any) {
    const modalRef = this.modalService.open(OrderReturnreqDetailComponent, options);
    modalRef.componentInstance.kind = data.kind;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }  
}
