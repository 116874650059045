/*******************************************************************************
  설  명 : 색상 추가
  작성일 : 2020-11-08
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ColorEvent } from 'ngx-color';

import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { AuthService } from '@app/service/auth.service';

@Component({
  selector: 'app-product-color-management-add',
  templateUrl: './product-color-management-add.component.html',
  styleUrls: ['./product-color-management-add.component.scss']
})
export class ProductColorManagementAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: any = 0;
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  public isColorPicker: boolean = false;
  public bgcolor: any = '';

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      color_name:["", [Validators.required, Validators.maxLength(50)] ],
      color_ename:["", [Validators.maxLength(50)] ],
      color:["", [Validators.required, Validators.maxLength(10)] ],
      use_yn:["1", [] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 데이터가 넘어온 경우 데이터 설정
    if( this.seq !== 0 ) {
      this.title = '수정';

      this.getColorInfo();
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    let title = ( this.seq != 0 ) ? '수정' : '추가';

    if(this.formAdd.valid) {
      this.productService.setColor(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '색상 ' + title);

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '색상 ' + title);
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '색상 ' + title);
    }
  }

  /*******************************************************************************
    설  명 : 캐리어 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getColorInfo() {
    this.productService.getColorInfo( this.seq ).then( response => {
      if( response.ResultCode ) {
        let data: any = {
          seq: response.data.seq,
          color_name: response.data.color_name,
          color_ename: response.data.color_ename,
          color: response.data.color,
          use_yn: response.data.use_yn,
        }

        this.formAdd.patchValue( data );

        this.bgcolor = response.data.color;
      }
    });
  }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("선택하신 색상을 삭제하시겠습니까?") ) {
      this.productService.deleteColor( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '색상 삭제');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '색상 삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 컬러 픽커 선택 시 처리
    입력값 : $event: ColorEvent
    리턴값 : 없음
  *******************************************************************************/
  changeComplete($event: ColorEvent) {
    //this.formAdd.patchValue({bgcolor: $event.color.hex});
    this.bgcolor = $event.color.hex;
  }

  /*******************************************************************************
    설  명 : 컬러 픽커 표시 여부
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  toggleColorPicker() {
    this.isColorPicker = !this.isColorPicker;
  }

  /*******************************************************************************
    설  명 : 컬러 픽커 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  accept( $event: any ) {
    this.formAdd.patchValue({color: this.bgcolor});

    this.isColorPicker = false;
  }

  /*******************************************************************************
    설  명 : 컬러 픽커 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  cancel( $event: any ) {
    this.bgcolor = this.formAdd.controls.color.value;
    this.isColorPicker = false;
  }

}
