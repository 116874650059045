  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{statistic?.productCnt | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic?.searchCnt | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">미설정<span class="cnt">{{statistic?.waitCnt | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">설정완료<span class="cnt">{{statistic?.completeCnt | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="fl w70p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductMarginList()">
      <i class="material-icons-outlined">search</i>
    </button>

    <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getProductMarginList()">
      <option value="25">25줄</option>
      <option value="100">100줄</option>
      <option value="500">500줄</option>
      <option value="1000">1000줄</option>
      <option value="5000">5000줄</option>
    </select>

    <select [(ngModel)]="search.category_code" class="form-control form-control-small ml5" (change)="getProductMarginList()">
      <option value="">카테고리 전체</option>
      <option *ngFor="let item of categoryList" value="{{item.category_code}}">{{item.category_nm}}</option>
    </select>

    <l-select2
      [data]="brandList"
      (ngModelChange)="onBrandChanged($event)"
      [(ngModel)]="search.brand_seq"
      class="form-control form-control-small ml5"
      [options]="{multiple: false}"
      style="width: 200px; padding:0px !important;border:solid 0px #fff !important;"
    ></l-select2>

    <input type="text" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="30" class="form-control form-control-small ml5" placeholder="상품코드, 상품명, 공급자명 검색" />

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.display == ''}" (click)="search.display = '';getProductMarginList()">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.display == '1'}" (click)="search.display = '1';getProductMarginList()">판매중</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.display == '0'}" (click)="search.display = '0';getProductMarginList()">대기중</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.soldout == ''}" (click)="search.soldout = '';getProductMarginList()">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.soldout == '1'}" (click)="search.soldout = '1';getProductMarginList()">품절</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.soldout == '0'}" (click)="search.soldout = '0';getProductMarginList()">품절제외</button>
    </div>
  </div>

  <div class="fr w30p right">
    <button type="button" class="btn btn-{{search.listType == 'margin' ? 'primary' : 'default'}} btn-small ml5" (click)="setListTypeChange('margin')">마진 리스트</button>
    <button type="button" class="btn btn-{{search.listType == 'reverse' ? 'danger' : 'default'}} btn-small ml5" (click)="setListTypeChange('reverse')">역마진 리스트</button>
    <button type="button" class="btn btn-{{search.listType == 'mileage' ? 'success' : 'default'}} btn-small ml5" (click)="setListTypeChange('mileage')">마일리지 리스트</button>
  </div>

  <div class="cb"></div>

  <div class="mt5" style="height:calc(100% - 140px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="marginList"
      [domLayout]="domLayout"
      [singleClickEdit]="true"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [getRowHeight]="getRowHeight"
      [pagination]="false"
      [paginationPageSize]="paginationPageSize"
      [components]="components"

      (rowClicked)="onRowClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
    <section class="pagination mt10" *ngIf="totalCount">
      <ngb-pagination
        [(page)]="search.pageNo"
        [pageSize]="search.pageRow"
        [collectionSize]="totalCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
  </div>
