/*******************************************************************************
  설  명 : 배송 교환/반품/취소 모달
  작성일 : 2021-06-11
  작성자 : 박희정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { OrderService } from '@app/service/order.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';



@Component({
  selector: 'app-order-status-change',
  templateUrl: './order-status-change.component.html',
  styleUrls: ['./order-status-change.component.scss']
})
export class OrderStatusChangeComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  private date: NgbDateStruct = this.utilService.getDate('');

  public form: FormGroup;
  public formErrors = {};
  public orderStatusList:any = [];

  public item: any = {
    seq: ''
  }

  public changeRefList: any = [];
  public changeProduct: any = [];


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      qty: ['', [Validators.required] ],
      changeRef: ['', [Validators.required] ],
      changeRef_name: ['', [] ],
      mail_yn: [ '1', []],
      date: [this.date, [Validators.required] ]
      
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private commonService: CommonService,
    private orderService: OrderService,
    private toastrService: ToastrService
  ) {
    this.buildForm();

  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {

    this.getCommonList();

    this.form.patchValue({seq: this.item.seq});
  }


  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    
    this.commonService.getCommonListCode('CRK').then( response => {
      if ( response.ResultCode ) {

        this.changeRefList.push({value: '', title: '-- 분류선택 --'});

        response.data.forEach( row => {
          this.changeRefList.push({
            value: row.common_code,
            title: row.common_name
          });
        });

      } else {
        this.changeRefList = [];
      }
    });
  }

  changeSelect() {
    const changeRefund = this.changeRefList.filter(item => item.value === this.form.controls.changeRef.value);

    if( changeRefund[0].value ) {
      this.form.patchValue({
        changeRef_name: changeRefund[0].title
      });

    }
  }


  // changeProduct() {
  //   const changeRefund = this.changeRefList.filter(item => item.value === this.form.controls.changeRef.value);

  //   if( changeRefund[0].value ) {
  //     this.form.patchValue({
  //       changeRef_name: changeRefund[0].title
  //     });

  //   }
  // }


  setOrderDetailRefund() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if(this.form.valid) {
      if(this.form.value.qty > this.item.qty) {
        this.toastrService.error('수량을 확인하시기 바랍니다.', '');
      } else {
        this.orderService.setOrderDetailRefund(this.form).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.activeModal.close( true );
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
