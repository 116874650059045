/*******************************************************************************
  설  명 : 주문관리 - 환불관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';

import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { OrderRefundEditComponent } from '@page/order/order-refund/order-refund-edit/order-refund-edit.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-refund',
  templateUrl: './order-refund.component.html',
  styleUrls: ['./order-refund.component.scss']
})
export class OrderRefundComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  statistic: any = {
    totalCount: 0,
    searchCount: 0,
    standbyCount: 0
  }

  public refundList: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilService: UtilService,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '주문번호', field: 'mem_no', width: 90, cellClass: 'cp center',headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '진행상태', field: 'email', width: 120, cellClass: 'cp' },
      { headerName: '이미지', field: 'joindate', width: 140, cellClass: 'cp center' },
      { headerName: '모델', field: 'status', width: 200, cellClass: 'cp' },
      { headerName: '주문자', field: 'name', width: 150, cellClass: 'cp' },
      { headerName: '등록일', field: 'id', width: 120, cellClass: 'cp center' },
      { headerName: '요청일', field: 'hp', width: 120, cellClass: 'cp center' },
      { headerName: '환불사유', field: 'email', width: 200, cellClass: 'cp center' },
      { headerName: '동봉배송비', field: 'email', width: 100, cellClass: 'cp right' },
      { headerName: '수량', field: 'email', width: 100, cellClass: 'cp right' },
      { headerName: '환불금액', field: 'email', width: 100, cellClass: 'cp center' },
      { headerName: '환불방법', field: 'email', width: 120, cellClass: 'cp center' },
      { headerName: '주문내역', field: 'email', width: 100, cellClass: 'cp center' }, // 버튼
      { headerName: '반품내역', field: 'email', width: 100, cellClass: 'cp center' }, // 버튼
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 환불신청 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  refundEdit( seq: number ) {
    const modalRef = this.modalService.open(OrderRefundEditComponent, optionsLG);

    //modalRef.componentInstance.memberNo = memNo;

    modalRef.result.then((result) => {
      if( result ) {
        //this.getMemberList();
      }
    }, (reason) => {
    });
  }
}
