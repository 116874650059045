<div class="p0" style="height: 50%; position: relative;">
        <div class="mt10">
          <div class="fl w30p">
            <div class="fb mt5">주문 정보 <span class="cred">( 상품이미지를 클릭하면 상품 상세정보로 이동합니다. )</span></div>
          </div>
          <div class="fr w70p right">
            <button type="button" class="btn btn-primary btn-small mr5" (click)="postRelease()" *ngIf="!orderDetail.mng_out_delivery_seq">출고 실행</button>
            <!-- <button type="button" class="btn btn-default btn-small mr5" (click)="setDelivery()">출고처리</button> -->
            <button type="button" class="btn btn-danger btn-small mr5" (click)="setCancelDelivery()" *ngIf="orderDetail.mng_out_delivery_seq">출고취소</button>
            <!-- <button type="button" class="btn btn-default btn-small mr5" (click)="divideRelease()">지시분할</button> -->

            <btn-ag-grid-save [gridColumnsApi]="GridApi_order" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
            <button type="button" class="btn btn-default btn-small ml5" (click)="goList()">목록으로</button>
          </div>
          <div class="cb"></div>
        </div>

        <div class="mt5" style="height:calc(100% - 100px)">
          <ag-grid-angular
            #myGrid
      
            style="width:100%;height:100%;"
            class="ag-theme-balham"
      
            [columnDefs]="ColumnDefs_order"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData_order"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [isRowSelectable]="isRowSelectable"
            [pagination]="false"
            [getRowHeight]="getRowHeight"
            [singleClickEdit]="true"
            [stopEditingWhenGridLosesFocus]="true"    
            [suppressRowClickSelection]="true"
            [components]="components"
            [rowClassRules]="rowClassRules"
      
            (cellClicked)="onCellClicked($event)"
            (gridReady)="onGridReady_order($event)"
            >
          </ag-grid-angular>
        </div>
      
        <div class="cb"></div>
      
        <div class="mt5" style="height:65px">
          <ag-grid-angular
            #myGrid
      
            style="width: 100%; height:100%;"
            class="ag-theme-balham"
      
            [columnDefs]="ColumnDefs_orderSum"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData_orderSum"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
      
            (gridReady)="onGridReady_orderSum($event)"
            >
          </ag-grid-angular>
        </div>

        
  <div id="orderDetailList">
    <article>
    <ul>
      <li>이미지</li>
      <li>브랜드</li>
      <li>상품명</li>
      <li>색상</li>
      <li>사이즈</li>
      <li>수량</li>
      <li>분할수량</li>
    </ul>
    <ul *ngFor="let item of orderDetailList; index as index">
      <li><img src="{{item.thumbnail_url}}"></li>
      <li>{{item.brand_name}}</li>
      <li>{{item.product_name}}</li>
      <li>{{item.color_name}} - {{item.display_color_name}}</li>
      <li>{{item.size_name}} - {{item.display_size_name}}</li>
      <li>{{item.out_order_qty}}</li>
      <li><input type="text" [value]="item.divide_qty" (change)="putOrderDetailListDivideQty($event, index)"></li>
    </ul>
    </article>
    <div id="buttonGroup">
      <button type="button" class="btn btn-danger btn-small mr5" (click)="divideSubmit()">실행</button>
      <button type="button" class="btn btn-secondary btn-small" (click)="divideCancel()">취소</button>
    </div>
  </div>

</div>

  <ng-container>
    <div class="mt5 fl">
      <div class="fl w70p">
        <div class="fb mt5">MEMO</div>
      </div>
      <!-- <div class="fr w30p right">
        <btn-ag-grid-save [gridColumnsApi]="memoGridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
      </div> -->
    </div>

      <div class="mb5 mt5">
        <div class="w100p" style="position: relative; top: 5px;">
          <inputEx
            type="text"
            name="comment"
            [formGroup]="memoForm"
            [formErrors]="memoFormErrors"
            [rows]="1"
            placeholder="메모 작성(최대 255Byte, 한글 띄어쓰기 없이 최대 85자)"
            [addOn]="setOrderMemoSaveFunc"
            addOnText="등록"
            (keypress)="setKeyPressMemo($event)"
          ></inputEx>
        </div>
      </div>

    <div class="mt5 fr" style="width: 100%; height: 22%;">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="ColumnDefs_memo"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData_memo"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [isRowSelectable]="isRowSelectable"
        [pagination]="false"
        [paginationPageSize]="paginationPageSize"

        (gridReady)="onGridReady_memo($event)"
        >
      </ag-grid-angular>
    </div>
  </ng-container>

  <div class="cb"></div>

  <as-split unit="percent" restrictMove="true" gutterSize="10" style="height: auto;">
    <as-split-area size="60" minSize="40" maxSize="60">

      <div class="mt10 ">
        <div class="fl w20p">
          <h4 class="fb mt5 mb6">상품 수취인 정보</h4>
        </div>
        <div class="cb"></div>
      </div>
    
      <table class="table table-bordered table-small1 mb0">
      <caption class="none">상품 수취인 정보</caption>
      <colgroup>
        <col style="width:14%;" />
        <col style="width:36%;" />
        <col style="width:14%;" />
        <col style="width:36%;" />
      </colgroup>
    
      <tbody>
      <tr>
        <th scope="row"><label for="r_name">수취인 성명</label></th>
        <td><input type="text" id="r_name" [value]="orderDetail?.r_name" size="30" class="form-control form-control-small" readonly /></td>
        <th scope="row"><label for="r_hp">수취인 전화</label></th>
        <td><input type="text" id="r_hp" [value]="orderDetail?.r_hp" size="30" class="form-control form-control-small" readonly /></td>
      </tr>
      <tr>
        <th scope="row"><label for="r_zipcode">우편번호</label></th>
        <td><input type="text" id="r_zipcode" [value]="orderDetail?.r_zipcode" size="30" class="form-control form-control-small" readonly /></td>
        <th scope="row"><label for="r_address">주소</label></th>
        <td><input type="text" id="r_address" [value]="orderDetail?.r_address" size="30" class="form-control form-control-small" readonly /></td>
      </tr>
      <tr>
        <th scope="row"><label for="r_address_detail">상세주소</label></th>
        <td colspan="3"><input type="text" id="r_address_detail" [value]="orderDetail?.r_address_detail" size="30" class="form-control form-control-small" readonly /></td>
      </tr>
      </tbody>
      </table>
    
    </as-split-area>
    <as-split-area size="40" minSize="40" maxSize="60">

      <div class="mt10">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">주문자 정보</h4>
        </div>
        <div class="cb"></div>
      </div>
    
      <table class="table table-bordered table-small1 mb0">
      <caption class="none">주문자 정보</caption>
      <colgroup>
        <col style="width:14%;" />
        <col style="width:36%;" />
        <col style="width:14%;" />
        <col style="width:36%;" />
      </colgroup>
    
      <tbody>
      <tr>
        <th scope="row"><label for="o_name">주문자 이름</label></th>
        <td><input type="text" id="o_name" [value]="orderDetail?.o_name" size="30" class="form-control form-control-small" readonly /></td>
        <th scope="row"><label for="o_email">주문자 이메일</label></th>
        <td><input type="text" id="o_email" [value]="orderDetail?.o_email" size="30" class="form-control form-control-small" readonly /></td>
      </tr>
      <tr>
        <th scope="row"><label for="o_hp">주문자 연락처</label></th>
        <td><input type="text" id="o_hp" [value]="orderDetail?.o_hp" size="30" class="form-control form-control-small" readonly /></td>
        <th scope="row"></th>
        <td></td>
      </tr>
      <tr>
        <th scope="row"><label for="r_comment">요청사항</label></th>
        <td colspan="3"><input type="text" id="r_comment" [value]="orderDetail?.r_comment" size="30" class="form-control form-control-small" readonly /></td>
      </tr>
      </tbody>
      </table>
    
    </as-split-area>
  </as-split>