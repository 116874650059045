import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CategoryService } from '@app/service/category.service';
import { CommonService } from '@app/service/common.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  public typeList = [];
  public categoryList = [];

  public categoryForm: FormGroup;
  public formErrors = {};

  /* 노드관련 변수 */
  public nodeData: any = [];
  public selectedNode = {
    seq: '',
    category_code: '',
    category_name: '',
    mall_display: true,
    memo: '',
    category_type: '',
    use_yn: true,
    children: []
  };

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.categoryForm = this.formBuilder.group({
      seq: ['', [] ],
      category_code: ['', [Validators.required] ],
      category_name: ['', [Validators.required, Validators.maxLength(20)] ],
      mall_display: [true, []],
      memo: ['', [] ],
      category_type: ['', [Validators.required, Validators.maxLength(11)] ],
      use_yn: [true, [Validators.required] ],
    });

    this.categoryForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.categoryForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private categoryService: CategoryService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 공통 코드 가져오기
    this.getCommonList().then( () =>
      // 카테고리 리스트 가져오기
      this.getCategoryListN()
    );
  }

  /*******************************************************************************
    설  명 : 카테고리 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.categoryForm, this.formErrors);

    if(this.categoryForm.valid) {
      this.categoryService.setCategory(this.categoryForm).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getCategoryListN();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    return this.commonService.getCommonList('CGT').then( response => {
      this.typeList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryListN() {
    this.categoryService.getCategoryListN().then( response => {
      if (response.resultCode) {
        this.nodeData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteCategory() {
    if( this.categoryForm.controls.seq.value == '' ) {
      this.toastrService.error( '삭제할 카테고리를 선택하세요.', '');

      return false;
    }

    if( confirm("선택한 카테고리를 삭제하시겠습니까?") ) {
      this.categoryService.deleteCategory( this.categoryForm ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.categoryForm.patchValue({
            seq: '',
            category_code: '',
            category_name: '',
            memo: '',
            category_type: '',
            use_yn: true,
            children: []
          });

          this.getCategoryListN();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 삭제 할 수 있는지 검증 - 캐리어, 관리상품 카테고리는 삭제 불가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkDeleteCategory() {
    if( this.selectedNode.seq == '' ) return false;

    let category = this.selectedNode.category_code.substr(0, 3);

    if( category == '009' || category == '008' ) {
      return false;

    } else {
      if( typeof this.selectedNode.children == 'undefined' ) {
        return true;

      } else {
        if( this.selectedNode.children.length > 0 ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {
    // 선택 해제
    if( this.selectedNode.seq == $event.seq ) {
      this.selectedNode = {
        seq: '',
        category_code: '',
        category_name: '',
        mall_display: true,
        memo: '',
        category_type: '',
        use_yn: true,
        children: []
      };

    // 카테고리 선택
    } else {
      this.selectedNode.seq = $event.seq;
      this.selectedNode.category_code = $event.key;
      this.selectedNode.category_name = $event.name;
      this.selectedNode.use_yn = $event.use_yn;
      this.selectedNode.category_type = $event.category_type;
      this.selectedNode.memo = $event.memo;
    }

    this.categoryForm.patchValue( this.selectedNode );
  }

  /*******************************************************************************
    설  명 : 노드추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onAddNode($event) {
    this.categoryForm.patchValue({
      seq: '',
      category_code: $event.key,
      category_name: '',
      memo: '',
      use_yn: true,
      children: []
    });
  }

  /*******************************************************************************
    설  명 : 노드순서변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onDropNode($event) {
    this.categoryService.setNodeSort( $event ).then( response => {
      if( response.ResultCode ) {
        this.getCategoryListN();
      }
    }).catch( error => {
      console.log( error );
    });
  }
}
