<div class="modal-header">
  <h4 class="modal-title bold" id="modal-common-code">배송교환/반품/취소 처리</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <section>
    <div class="mt5">
      <div class="fl">
        <h4 class="fb mt5 mb6">배송일자에 월일을 입력하지 않고 배송일자를 등록하면 재고불출이 회복되므로 유의하세요.</h4>
      </div>
      <div class="fr w20p right">
        <button type="button" class="btn btn-primary btn-small" (click)="setOrderDetailRefund()">교환반품취소일자등록</button>
      </div>
      <div class="cb"></div>
    </div>

    <form [formGroup]="form">

      <table class="table table-bordered table-small1 mb0">
        <caption class="none">거래처정보</caption>
        <!-- <colgroup>
          <col style="width:15%;" />
          <col style="width:85%;" />
        </colgroup> -->

        <colgroup>
          <col style="width:20%;" />
          <col style="width:30%;" />
          <col style="width:20%;" />
          <col style="width:30%;" />
        </colgroup>
  
        <tbody>
          <tr>
            <th scope="row"><label for="date">처리일자</label></th>
            <td colspan="3"><inputEx type="date" name="date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="">수량</label></th>
            <td colspan="3">
              <label class="f12 form-inline amt">
                <inputEx type="text" name="qty" [formGroup]="form" [formErrors]="formErrors" [data]="orderStatusList"></inputEx>
                <div class="ml5"> 총수량 : {{this.item.qty}}</div>
              </label>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="">분류선택</label></th>
            <td>
              <inputEx type="select" name="changeRef" [formGroup]="form" [formErrors]="formErrors" [data]="changeRefList" (change)="changeSelect()"></inputEx>
            </td>
            <th scope="row"><label for="">교환 상품 선택</label></th>
            <td>
              <inputEx type="select" name="changeRef" [formGroup]="form" [formErrors]="formErrors" [data]="changeProduct"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="">메일</label></th>
            <td colspan="3">
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.mail_yn.value=='1'}" (click)="form.get('mail_yn').setValue('1')">메일YES</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.mail_yn.value=='0'}" (click)="form.get('mail_yn').setValue('0')">메일NO</button>
              </div>
              <!-- <inputEx type="hidden" name="consign_fee" [formGroup]="form" [formErrors]="formErrors"></inputEx> -->
            </td>
          </tr>
        </tbody>
      </table>
    </form>

  </section>

  <br>
  <br>

  <section>
    <div class="mt5">
      <div class="fl">
        <h4 class="fb mt5 mb6">#주의사항</h4>
        <h4 class="mt5 mb6">1. 발송 전 재고무/고객변심에 의한 건일 경우 - 주문취소</h4>
        <h4 class="mb6">&nbsp;&nbsp;&nbsp;&nbsp;단, 주문건 중 일부 취소일 경우 반품/취소로 처리하세요.</h4>
        <h4 class="mt5 mb6">2. 발송 후 교환/혹은 반품건일 경우 무조건 - 반품/취소</h4>
        <h4 class="mt5 mb6">3. 동시에 전체 반품할 경우에만 - 전체반품</h4>
        <h4 class="mb6">&nbsp;&nbsp;&nbsp;&nbsp;현재화면에서 전체교환/일부교환은 사용하지 마세요.</h4>
      </div>
    </div>
  </section>
</div>