import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Globals } from '@app/service/globals.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loggedInInfo: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  private loggedInMemberGrade:'';
  private authData: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  private memberData: any = {};
  public currAuth: any = {
    seq: '',
    auth_view: false,
    auth_write: false,
    auth_modify: false,
    auth_delete: false,
    auth_excel: false,
    auth_sms: false,
    auth_email: false,
    auth_kakao: false
  };

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get getLoginInfo() {
    return this.loggedInInfo.asObservable();
  }
 
  get getLoginMemberGrade() {
    return this.loggedInMemberGrade;
  }

  constructor(
    private router: Router,
    private globals: Globals,
  ) {}

  /*******************************************************************************
    설  명 : 로그인
  *******************************************************************************/
  login(response: any) {
    this.memberData = response;

    this.loggedInMemberGrade = response.data.grade;

    this.loggedIn.next(true);
    this.loggedInInfo.next(response.data);
    this.authData.next( response );

    this.globals.setToken(response.token);
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  logout(mode: string) {
    this.memberData = {};

    this.loggedIn.next(false);
    this.loggedInInfo.next([]);
    this.authData.next([]);

    this.globals.clearToken();

    if( mode == 'A' ) this.router.navigate(['/login']);
  }

  /*******************************************************************************
    설  명 : 현재 페이지 접속 권한을 리턴한다.
    입력값 : 없음
    리턴값 : 접속 권한
  *******************************************************************************/
  getPageAuth() {
    let path: string = location.pathname;

    this.currAuth = {
      seq: '',
      auth_view: false,
      auth_write: false,
      auth_modify: false,
      auth_delete: false,
      auth_excel: false,
      auth_sms: false,
      auth_email: false,
      auth_kakao: false
    };

    // 게시판인 경우
    if( path.indexOf('/board/') > -1 && path.indexOf('/board/management') == -1 ) {
      for( let item of this.memberData.auth ) {
        if( path.indexOf( item.board_id ) > -1 ) {
          this.currAuth = item;
          break;
        }
      }

    // 일반 페이지
    } else {
      // 로그인 페이지로
      if( typeof this.memberData.auth == 'undefined' ) {
        this.logout('A');

      } else {
        for( let item of this.memberData.auth ) {
          if( item.link_url != '' && path.indexOf( item.link_url ) > -1 ) {
            this.currAuth = item;
            break;
          }
        }
      }
    }

    return this.currAuth;
  }
}
