  <div class="modal-header">
    <h4 class="modal-title fb mb0"></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <img src="/assets/images/samp.jpg">
  </div>

  <div class="modal-footer">
    <div class="fr w50p right">
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">닫기</button>
    </div>
  </div>

