/*******************************************************************************
  설  명 : 상품관리
  작성일 : 2021.06.28
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { CategoryService } from '@app/service/category.service';

import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ScmProductService } from '@app/service/scm.product.service'
import { DashboardComponent } from '@page/dashboard/dashboard.component'

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  widget = {
    total: 0,
    sale: 0,
    soldout: 0,
    okay: 0,
    deny: 0
  }

  productList = []
  categoryList = []

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search_protoType: any = {
    product_seq: '',
    pageNo: 1,
    pageRow: 20,
    opn: '',
    otc: 0,
    totalCount: 0,
    searchText: '',
    status: '',
    s_date: '',
    e_date: '',
    category_code: '',
    approval_yn: ''
  }

  search = { ...this.search_protoType }

  currencyFormatter = (params: any) => Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  numberGetter = (params: any) => {
    const value = params.data[ params.column.colId ]
    return ( value ) ? parseInt( value ) : value
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilService: UtilService,
    private productService: ProductService,
    private calendar: NgbCalendar,
    private categoryService: CategoryService,
    private toastrService: ToastrService,
    private scmProductService: ScmProductService,
    private dashboardComponent: DashboardComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      this.dashboardComponent.checkboxColumn,
      { headerName: '상품번호/옵션번호', field: 'seq', width: 125, cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let productSeq = params.data.seq;
          let propertySeq = params.data.property_seq;

          return productSeq + ( propertySeq !== undefined ? '<br/>' + propertySeq : '' );
        }
      },
      { headerName: '승인여부(상품/옵션)', field: 'approval_yn', width: 135, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let approvalYn = params.data.approval_yn;
          let propertyApprovalYn = params.data.property_approval_yn;
          let approvalBadge = ( approvalYn == 1 ) ? '<span class="badge badge-success f12">승인</span>' : '<span class="badge badge-danger f12">미승인</span>';
          let propertyApprovalBadge = ( propertyApprovalYn == 1 ) ? '<span class="badge badge-success f12 mt5">승인</span>' : '<span class="badge badge-danger f12 mt5">미승인</span>';

          return approvalBadge + ( propertyApprovalYn !== undefined ? '<br/>' + propertyApprovalBadge : '' );
        }
      },
      { headerName: '품절여부(상품/옵션)', field: 'status', width: 135, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let parentStatus: any = params.data.parent_status;
          let childStatus: any = params.data.status;
          let parentBadge: any;
          let childBadge: any;

          if ( parentStatus == '0' ) parentBadge = '<span class="badge badge-success f12">판매중</span>';
          else if ( parentStatus == '1' ) parentBadge = '<span class="badge badge-warning f12">일시품절</span>';
          else if ( parentStatus == '2' ) parentBadge = '<span class="badge badge-danger f12">영구품절</span>';
          else parentBadge = '';

          if ( childStatus == '0' ) childBadge = '<span class="badge badge-success f12 mt5">판매중</span>';
          else if ( childStatus == '1' ) childBadge = '<span class="badge badge-warning f12 mt5">일시품절</span>';
          else if ( childStatus == '2' ) childBadge = '<span class="badge badge-danger f12 mt5">영구품절</span>';
          else childBadge = '';

          return parentBadge + ( childStatus !== undefined ? '<br/>' + childBadge : '' );
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 120, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '카테고리/브랜드/상품명', field: '', width: 420, cellClass: 'ag-cell80h-br cell-wrap-text', autoHeight: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let category_name = ( params.data.category_name ) ? params.data.category_name + '<br/>' : '';
          let brand_name = ( params.data.brand_name ) ? '[ ' + params.data.brand_name + ' ]<br/>' : '';
          let product_name = ( params.data.product_name ) ? params.data.product_name : '';

          return category_name + brand_name + product_name;
        }
      },
      { headerName: '색상/사이즈', field: '', width: 193, cellClass: 'left ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          // let color_name = ( params.data.color_name ) ? params.data.color_name : '';
          let display_color_name = ( params.data.display_color_name ) ? '<span class="fb">' + params.data.display_color_name + '</span>' : '';
          // let size_name = ( params.data.size_name ) ? params.data.size_name : '';
          let display_size_name = ( params.data.display_size_name ) ? '<span class="fb">' + params.data.display_size_name + '</span>' : '';

          if( params.data.property_seq === undefined )
            return '<span class="fb">옵션보기 시 표시</span>';
          else
            return display_color_name + ' <br/> ' + display_size_name;
        }
      },
      { headerName: '소비자가', field: 'consumer_price', width: 100, cellClass: 'right ag-cell80h',
        valueFormatter: this.currencyFormatter,
        valueGetter: this.numberGetter
      },
      { headerName: '공급가', field: 'buy_price', width: 100, cellClass: 'right ag-cell80h',
        valueFormatter: this.currencyFormatter,
        valueGetter: this.numberGetter
      },
      { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'center ag-cell80h' },
      {
        field: '관리',
        width: 90,
        cellClass: 'center ag-cell80h',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'productList',
          goDetailFunc: this.goDetail.bind(this),
          getPropertyListFunc: this.getPropertyList.bind(this),
          getProductListFunc: this.getProductList.bind(this)
        }
      }
    ]

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    }
    
    this.rowSelection = 'multiple'
    this.noRowsTemplate = this.dashboardComponent.noRowsTemplate
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = () => 80

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    // 검색 카테고리 리스트가져오기
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) this.categoryList = response.data
      else this.toastrService.error(response.ResultMessage)
    })

    this.activatedRoute.queryParams.subscribe( async params => {
      this.search = {
        product_seq: ( typeof params.product_seq == 'undefined' || params.product_seq == '' ) ? '' : params.product_seq,
        pageNo: ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo,
        pageRow: ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow,
        totalCount: ( typeof params.totalCount == 'undefined' ) ? '0' : params.totalCount,
        opn: ( typeof params.opn == 'undefined' || params.opn == '' ) ? '' : params.opn,
        otc: ( typeof params.otc == 'undefined' || params.otc == '' ) ? '0' : params.otc,
        s_date : params.s_date,
        e_date : params.e_date,
        status : params.status || '',
        searchText : params.searchText,
        category_code : params.category_code,
        approval_yn: params.approval_yn || '',
      }
      
      // 위젯 데이터 불러오기
      this.getWidget();

      // 상품 데이터 불러오기
      this.getProduct();
    })
    // this.toastrService.info('상품을 클릭하면 상세정보로 이동합니다.')
  }

  /*******************************************************************************
    설  명 : 위젯 데이터 불러오기
  *******************************************************************************/
  getWidget() {
    this.scmProductService.getWidget( this.search ).then(response => {
      if(response.ResultCode) {
        this.widget = response.data
      } else this.toastrService.error(response.ResultMessage)
    })
  }
  
  /*******************************************************************************
    설  명 : 등록상품 리스트 가져오기
  *******************************************************************************/
  getProduct() {
    this.scmProductService.getProductList({
      ...this.search,
      mem_no : this.dashboardComponent.getUser()
    }).then(response => {
      if(response.ResultCode) {
        this.productList = response.data
        this.search.totalCount = parseInt(response.count) || 0;
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 등록상품 리스트 가져오기
  *******************************************************************************/
  getProductList( params: any ) {
    this.search.product_seq = '';
    this.search.pageNo = this.search.opn;
    this.search.totalCount = this.search.otc;
    this.search.opn = '';
    this.search.otc = 0;

    this.router.navigate(
      ['/product/list'],
      { relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }
  
  /*******************************************************************************
    설  명 : 등록상품 리스트 가져오기
  *******************************************************************************/
  getPropertyList( params: any ) {
    this.search.product_seq = params.data.seq;
    this.search.opn = this.search.pageNo;
    this.search.otc = this.search.totalCount;
    this.search.pageNo = 1;
    this.search.totalCount = 0;

    this.router.navigate(
      ['/product/list'],
      { relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }
  
  /*******************************************************************************
    설  명 : 검색 필터 버튼
  *******************************************************************************/
  setSearch(key, value) {
    this.search[key] = value
    this.getProduct()
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 품절 체크페이지 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  soldOutProduct() {
    const rows = this.gridApi.getSelectedRows();

    const data: any = [];
    for(let row of rows) {
      data.push( row.seq );
    }

    if( data.length < 1) {
      this.toastrService.error('체크할 상품을 선택해주세요.');
    } else {
      const url = '/product/list/soldout?seq=' + data;
      window.open("about:blank").location.href = url;
    }

  }
  
  /*******************************************************************************
    설  명 : 품절처리
  *******************************************************************************/
  setSoldOut() {
    const row = this.gridApi.getSelectedRows().map(e => e['property_seq'])
    if(!row.length) {
      this.toastrService.error('선택한 데이터가 없습니다.')
      return
    }
    if(!confirm('선택한 상품 전체의 품절 여부를 변경합니다. \r\n품절은 판매중으로, 판매중은 품절로 변경됩니다. \r\n색상 및 사이즈 별 품절은 상세정보를 참고하세요. \r\n실행하시겠습니까?')) return

    // this.scmProductService.setSoldOut({
    //   mem_no : this.dashboardComponent.getUser(),
    //   list : row
    // }).then(response => {
    //   if(response.ResultCode) {
    //     this.toastrService.success(response.ResultMessage)
    //     this.ngOnInit()
    //   } else this.toastrService.error(response.ResultMessage)
    // })

    this.scmProductService.setSoldOutProperty({
      mem_no : this.dashboardComponent.getUser(),
      list : row
    }).then(response => {
      if(response.ResultCode) {
        this.toastrService.success(response.ResultMessage)
        this.ngOnInit()
      } else this.toastrService.error(response.ResultMessage)
    })

  }

  /*******************************************************************************
    설  명 : 검색 버튼에서 클릭 시 처리
    입력값 : key = this.params 키 값, value = 적용 값
    리턴값 : 없음
  *******************************************************************************/
  searchParams( key, value ) {
    this.search[key] = value;

    this.getProduct();
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화
  *******************************************************************************/
  searchInit() {
    this.search = { ...this.search_protoType }
    this.getProduct()
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
  *******************************************************************************/
  searchList( $event ) {
    if($event.key == 'Enter') this.getProduct()
  }

  /*******************************************************************************
    설  명 : 카테고리 선택 입력
  *******************************************************************************/
  setCategory($event) {
    this.setSearch('category_code', $event.target.value)
  }

  /*******************************************************************************
    설  명 : cell click
  *******************************************************************************/
  onCellClicked($event) {
    if ($event.colDef.field == 'seq') {
      this.productDetail($event.data.seq)
    } else if( $event.colDef.field === 'thumbnail_url' ) {
      const url = config.shopUrl + '/product/info?productSeq=' + $event.data.seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 셀 리사이즈 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onColumnResized(params) {
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 0);
  }

  onColumnVisible(params) {
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 0);
  }
  
  /*******************************************************************************
    설  명 : 상품등록
  *******************************************************************************/
  productDetail( seq: any ) {
    this.search.product_seq = seq;

    this.router.navigate(
      ['/product/list/add'],
      { relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 상품등록
  *******************************************************************************/
  goDetail( params: any ) {
    this.search.product_seq = params.data.seq;

    this.router.navigate(
      ['/product/list/add'],
      { relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }
  
  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.s_date = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.search.e_date = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 이동
  *******************************************************************************/
  goLink( linkUrl: any ) {
    // this.router.navigate(
    //   [linkUrl],
    //   { relativeTo: this.activatedRoute,
    //     queryParams: {},
    //     queryParamsHandling: '', // remove to replace all query params by provided
    //   }
    // );
  }

  /*******************************************************************************
    설  명 : 페이지 선택
  *******************************************************************************/
  loadPage(event: any) {
    this.search.pageNo = event;

    this.router.navigate(
      ['/product/list'],
      { relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }

}
