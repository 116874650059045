import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(
    private restful: RestfulService,
  ) {
  }
  // 주문 리스트 가져오기
  getOutofstockMemoList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getOutofstockMemoList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 업체요청사항
  getSCMMemoList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'stock',
        version: '1.0',
        action: 'getSCMMemoList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
