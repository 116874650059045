import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class OpenmarketService {

  constructor(
    private restful: RestfulService,
  ) {
  }
  // 주문 리스트 가져오기
  getOpenmarketList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getOpenmarketList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 지마켓/옥션 출력 리스트 가져오기
  getPrintGmarketAuctionList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintGmarketAuctionList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 네이버 출력 리스트 가져오기
  getPrintNaverList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintNaverList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 스토어팜 출력 리스트 가져오기
  getPrintFarmList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintFarmList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 11번가 출력 리스트 가져오기
  getPrint11stList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrint11stList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 쿠팡 출력 리스트 가져오기
  getPrintCoupangList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintCoupangList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 톡스토어 출력 리스트 가져오기
  getPrintTalkList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintTalkList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 배송목록 업로드
  setOpenmarketConsignSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setOpenmarketConsignSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
