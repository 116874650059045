<section class="gnb" [ngClass]="{'gnb-off': isOpen==false}">
  <section class="gnb-right" [ngClass]="{'gnb-off': isOpen==false}">
    <button mat-stroked-button (click)="isOpen=!isOpen">
      <span class="material-icons-outlined">
        <ng-container *ngIf="isOpen">keyboard_arrow_left</ng-container>
        <ng-container *ngIf="!isOpen">keyboard_arrow_right</ng-container>
      </span>
    </button>
    <button mat-stroked-button (click)="setOpenTreeNode()">
      <span class="material-icons-outlined">
        <ng-container *ngIf="isTreeOpen">keyboard_arrow_up</ng-container>
        <ng-container *ngIf="!isTreeOpen">keyboard_arrow_down</ng-container>
      </span>
    </button>
  </section>

  <section class="gnb-search">
    <input type="text" [(ngModel)]="search.menuText" class="gnb-search-input" placeholder="메뉴,기능 검색" />
    <mat-icon class="gnb-search-icon material-icons-outlined">search</mat-icon>
  </section>

  <!-- <section class="gnb-title">
    {{commonService.currMenu.menu_name }}
  </section> -->

  <mat-tree [dataSource]="dataSource | menuSearch: search.menuText" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node" [ngClass]="{divider: node.menu_name==='divider'}">
        <ng-container *ngIf="node.menu_name!=='divider'">
          <!-- <button mat-icon-button (click)="setMyMenu( node )">
            <span class="material-icons-outlined f14">star_border</span>
          </button> -->
          <a class="mat-tree-a" (click)="onLinkClick(node)">{{node.menu_name}}</a>
        </ng-container>

        <!-- divider -->
        <ng-container *ngIf="node.menu_name==='divider'">
          <mat-divider></mat-divider>
        </ng-container>
        <!-- divider -->
      </li>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild;">
      <li>
        <button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.menu_name" class="mat-tree-node mat-tree-toggle" [ngClass]="{'mat-tree-root': true}">
          <!-- <a class="mat-tree-a" (click)="treeControl.collapseAll()">{{node.menu_name}}</a> -->
          <a class="mat-tree-a">{{node.menu_name}}</a>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.menu_name" class="mat-tree-toggle">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
        </button>
        <!-- <ul class="menu-child-tree" [class.example-tree-invisible]="!treeControl.isExpanded(node)"> -->
        <ul class="menu-child-tree" [class.example-tree-invisible]="false">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</section>
