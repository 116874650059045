import { NgModule } from "@angular/core";
import {
  Router, Routes, RouterModule,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

import { AuthGuard } from '@app/service/auth.guard';
import { AuthService } from '@app/service/auth.service';

import { HomeLayoutComponent } from "@page/layout/home.layout.component";

import { LoginComponent } from "@page/login/login.component";
import { DashboardComponent } from "@page/dashboard/dashboard.component";

import { ProductComponent } from '@page/product/product.component';
import { ProductAddComponent } from '@page/product/product-add/product-add.component';
import { ProductMarginComponent } from '@page/product/product-margin/product-margin.component';
import { ProductPropertyComponent } from '@page/product/product-property/product-property.component';
import { ProductSortComponent } from '@page/product/product-sort/product-sort.component';
import { ProductBarcodeComponent } from '@page/product/product-barcode/product-barcode.component';
import { CategoryComponent } from '@page/product/category/category.component';
import { ProductColorManagementComponent } from '@page/product/product-color-management/product-color-management.component';
import { ProductSizeManagementComponent } from '@page/product/product-size-management/product-size-management.component';
import { ProductBrandManagementComponent } from '@page/product/product-brand-management/product-brand-management.component';
import { ProductSoldoutComponent } from '@page/product/product-soldout/product-soldout.component';

import { OrderComponent } from '@page/order/order.component';
import { OrderDetailComponent } from '@page/order/order-detail/order-detail.component';
import { StockComponent } from '@page/stock/stock.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: "",
    children: [
      { path: "login", component: LoginComponent },
    ],
  },
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
      {
        path: "product", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "category", component: CategoryComponent },
          { path: "list", component: ProductComponent },
          { path: "list/add", component: ProductAddComponent },
          { path: "margin", component: ProductMarginComponent },
          { path: "property", component: ProductPropertyComponent },
          { path: "size/management", component: ProductSizeManagementComponent },
          { path: "color/management", component: ProductColorManagementComponent },
          { path: "brand/management", component: ProductBrandManagementComponent },
          { path: "sort", component: ProductSortComponent },
          { path: "barcode", component: ProductBarcodeComponent },
          { path: "list/soldout", component: ProductSoldoutComponent },
        ]
      },
      {
        path: "order", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "list", component: OrderComponent },
          { path: "detail", component: OrderDetailComponent },
        ]
      },
      {
        path: "stock", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "statistic", component: StockComponent },
        ]
      },      
    ],
  },
];
``
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    // 로딩 처리
    this.router.events.subscribe(async (event: Event) => {
      switch( true ) {
        case event instanceof NavigationStart:
          break;

        case event instanceof NavigationEnd:
          await this.authService.getPageAuth();

          // 페이지 전환 시 최 상단으로 자동 스크롤
          window.scrollTo(0, 0);
          break;

        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          break;

        default: {
          break;
        }
      }
    });
  };
}