/*******************************************************************************
  설  명 : GNB
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from "@angular/core";
import { NestedTreeControl } from "@angular/cdk/tree";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MatTreeNestedDataSource } from "@angular/material/tree";
import {
  Router,
  Event,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';

import { CommonService, MenuNode } from '@app/service/common.service';
import { ModalFavoriteMenuComponent } from '@page/layout/modal-favorite-menu/modal-favorite-menu.component';
import { AuthService } from '@app/service/auth.service';

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: "app-gnb",
  templateUrl: "./gnb.component.html",
  styleUrls: ["./gnb.component.scss"]
})
export class GnbComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  treeControl = new NestedTreeControl<MenuNode>(node => node.menus);
  dataSource = new MatTreeNestedDataSource<MenuNode>();

  public isOpen: boolean = true;
  public isTreeOpen: boolean = false;

  hasChild = (_: number, node: MenuNode) => !!node.menus && node.menus.length > 0;

  public search: any = {
    menuText: ""
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute,
    public commonService: CommonService,
    private authService: AuthService,
  ) {
    // 라우팅 로딩 처리
    this.router.events.subscribe(async (event: Event) => {
      switch( true ) {
        case event instanceof NavigationEnd:
          // 현재 페이지 정보 가져오기
          this.getCurrMenu();

          // 페이지 전환 시 최 상단으로 자동 스크롤
          window.scrollTo(0, 0);
          break;
      }
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 메뉴 정보 가져오기
    let memberGrade = this.authService.getLoginMemberGrade; 
    this.commonService.getMenuList(memberGrade);

    // 메뉴 로딩시 처리
    this.commonService.menuList$.subscribe( data => {

      this.dataSource.data = data

    })
    // 현재 페이지 정보 가져오기
    this.getCurrMenu();
  }
  
  /*******************************************************************************
    설  명 : 현재 페이지 메뉴 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCurrMenu() {
    let path = location.pathname;
    let query = location.search.substr(1, location.search.length ); // ? 제거

    // 현재 메뉴 페이지 검색
    for( let item of this.dataSource.data ) {
      for( let subitem of item.menus ) {
        if( path == subitem.path ) {
          if(
            ( subitem.query === '' || subitem.query === null ) ||
            ( subitem.query !== '' && subitem.query !== null && subitem.query.indexOf( query ) > -1 )
          ) {
            let menu = subitem;
            menu.parentNode = item; // 부모노드 저장

            // 현재 페이지 정보 저장
            this.commonService.currMenu$.next(menu);
            this.commonService.currMenu = menu;

            // 현재 선택된 주메뉴 열기
            this.treeControl.expand( item );
          }
        }
      }
    }

  }

  /*******************************************************************************
    설  명 : 나만의 메뉴 추가
    입력값 : node
    리턴값 : 없음
  *******************************************************************************/
  setMyMenu( node: any ) {
    if ( confirm('선택하신 메뉴를 나만의 메뉴에 추가하시겠습니까?') ) {
      const modalRef = this.modalService.open(ModalFavoriteMenuComponent, optionsXL);
      modalRef.componentInstance.scrollPage = node.menu_id;

      modalRef.result.then((result) => {
        if ( result ) {
        }
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 메뉴 트리 전체 오픈/닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenTreeNode() {
    this.isTreeOpen = ! this.isTreeOpen;

    if( this.isTreeOpen ) {
      // this.treeControl.expandAll(); 오류로 루핑
      for( let node of this.dataSource.data ) {
        this.treeControl.expand( node );
      }
    } else {
      this.treeControl.collapseAll();
    }

  }

  /*******************************************************************************
    설  명 : uri 해시
    입력값 : uri
    리턴값 : []
  *******************************************************************************/
  query_to_hash(uri) {
    const result = {};

    uri.replace(/\?/, "").split("&").forEach(
      function(value) {
        const values = value.split('=');

        result[values[0]] = values[1];
      }
    );

    return result;
  }

  /*******************************************************************************
    설  명 : 링크 클릭 시 처리
    입력값 : node
    리턴값 : 없음
  *******************************************************************************/
  onLinkClick(node: any) {
    const uri = node.path;
    const path = location.pathname;

    const queryHash = (node.query != null) ? this.query_to_hash(node.query) : "";

    if( uri.indexOf('http://') > -1 ||  uri.indexOf('https://') > -1 ) {
      var win = window.open(uri, '_blank');
      win.focus();
    } else {
      if( uri == path ) {
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = "reload";
      }

      this.router.navigate(
        [uri],
        {
          queryParams: queryHash,
          relativeTo: this.activateRoute,
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );
    }
  }
}
