/*******************************************************************************
  설  명 : 헤더
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { IPioneerTreeConfiguration } from '@pioneer-code/pioneer-tree';
import { NotifierService } from 'angular-notifier';

import { MemberService } from '@app/service/member.service';
import { SystemGroupService } from '@app/service/system.group.service';
import { FavoriteMenuService } from '@app/service/favorite-menu.service';

import { ModalFavoriteMenuComponent } from '@page/layout/modal-favorite-menu/modal-favorite-menu.component';
import { EmployeeService } from '@app/service/employee.service';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('customNotification', { static: true }) customNotificationTmpl;

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public isAlarm: boolean = false; // 알림창 표시
  public isOrganization: boolean = false // 조직도 표시
  public employeePositionList = []; // 사원포함한 조직도
  public selectedMemberInfo = [];
  private notifier: NotifierService;

  public topSearchText: any;

  public menuData: any = [];
  public message: any = [];

  /* 노드관련 변수 */
  public nodeData: any = [];

  public selectedTree:any = {};

  public member_info:any = {
    mem_no: '',
    id: '',
    level: '',
    password: '',
    name: '',
    email: '',
    birthdate: null,
    birth_sec: 'S',
    joindate: null,
    leavedate: null,
    dept_seq: '',
    dept_name: '',
    dept_cd: '',
    position_seq: '',
    position_name: '',
    tel: '',
    hp: '',
    extension_number: '',
    emergency_tel: '',
    auth_group_seq: '',
    picture: '',
    picture_url: '/assets/images/no_image.png',
    picture_origin: '',
    badge: '',
    sdate: '',
    edate: ''
  };

  public configuration = {
    "childPropertyName" : "children",
    "sortPropertyName" : "id",
    "collapseAllOnLoad" : false
  } as IPioneerTreeConfiguration;

  /*******************************************************************************
    설  명 : 사용자정보 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  init_member_info() {
    this.member_info = {
      mem_no: '',
      id: '',
      level: '',
      password: '',
      name: '',
      email: '',
      birthdate: null,
      birth_sec: 'S',
      joindate: null,
      leavedate: null,
      dept_seq: '',
      dept_name: '',
      position_seq: '',
      position_name: '',
      dept_cd: '',
      tel: '',
      hp: '',
      extension_number: '',
      emergency_tel: '',
      auth_group_seq: '',
      picture: '',
      picture_url: '/assets/images/no_image.png',
      picture_origin: '',
      badge: '',
      sdate: '',
      edate: ''
    };
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private memberService: MemberService,
    private systemGroupService: SystemGroupService,
    private favoriteMenuService: FavoriteMenuService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private restfulService: RestfulService,
    private authService: AuthService,
    public utilService: UtilService,
    public notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    this.init_member_info();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getGroupEmployeeList();
    this.getUserMenuList();
  }

  /*******************************************************************************
    설  명 : 로그아웃
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  logout() {
    this.memberService.logout().then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '로그아웃' );
        this.authService.logout('');
        this.router.navigate(
          ['/login'],
          {
            queryParams: [],
            relativeTo: this.activatedRoute,
            queryParamsHandling: '', // remove to replace all query params by provided
          }
        );
      } else {
        this.toastrService.error( response.ResultMessage, '로그아웃' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 공지 기능
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openSnackBar() {
    this.isAlarm = ! this.isAlarm;

    this.message = [
      {id: '0', text: '상품 01002의 재고가 10개 이하입니다.'},
      {id: '1', text: '회원 3434의 메세지가 있습니다.'},
      {id: '2', text: '대표이사님의 공지가 있습니다.'},
      {id: '3', text: '상품 6454가 100개 입고되었습니다.'}
    ];

    if( this.isAlarm ) {
      this.message.map(row => {
        this.notifier.show({
          type: 'success',
          message: row.text,
          template: this.customNotificationTmpl,
          id: row.id
        });
      });
    } else {

    }

  }

  /*******************************************************************************
    설  명 : 공지 제거 버튼 기능
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  hideNotifier($event) {
    this.notifier.hide($event.id);
  }

  /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeList() {
    this.systemGroupService.getGroupEmployeeList().then( response => {
      if ( response.ResultCode ) {
        this.nodeData = response.data;
      } else {
        this.nodeData = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 노드 선택시 이벤트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {

    this.selectedTree = $event;

    if ( $event.mem_no != null ) {
      this.getEmployeeInfo( this.selectedTree.mem_no );

    } else {
      this.init_member_info();
    }

  }


  /*******************************************************************************
    설  명 : 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getUserMenuList() {
    this.favoriteMenuService.getFavoriteMenuList().then( response => {
      if ( response.ResultCode ) {
        this.menuData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 직원 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployeeInfo( seq ) {
    this.restfulService.isIndicator = false;

    this.employeeService.getEmployeeInfo( seq ).then( response => {
      this.member_info = response.data;

      this.restfulService.isIndicator = true;
    }).catch( e => {
      this.restfulService.isIndicator = true;
    });
  }

  /*******************************************************************************
    설  명 : 나만의 메뉴
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  favoriteMenu() {
    const modalRef = this.modalService.open(ModalFavoriteMenuComponent, optionsXL);
    modalRef.result.then((result) => {
      this.getUserMenuList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 헤더 검색 - 키보드 입력 후 엔터 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  topSearchEnter( event ) {
    if( event.key == 'Enter' ) {
      this.topSearchOrder();
    }
  }

  /*******************************************************************************
    설  명 : 헤더 검색 - 주문관리 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  topSearchOrder() {
    this.router.navigate(
    ['/order/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: { topSearchText: this.topSearchText },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
}
