import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RestfulService } from '@app/service/restful.service';
import { BehaviorSubject } from 'rxjs';

export interface MenuNode {
  menu_type: string;
  menu_name: string;
  path?: string;
  query?: string;
  menus?: MenuNode[];
  parentNode?: MenuNode;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public isSetup: boolean = false;

  public menuList$: BehaviorSubject<[]> = new BehaviorSubject([]);
  public currMenu$: BehaviorSubject<{}> = new BehaviorSubject({});

  public currMenu: MenuNode = {
    menu_type: '1000',
    menu_name: 'HOME',
    path: '/dashboard',
    query: '',
    menus: [],
    parentNode: null,
  };

  constructor(
    private restful: RestfulService,
    private domSanitizer: DomSanitizer,
  ) {
  }

  getInitCMS(): Promise<any> {
    return this.restful.get({
        program: 'o2ozcms',
        service: 'common',
        version: '1.0',
        action: 'getInitCMS'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 메뉴 리스트 가져오기
  getMenuList(grade:any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'common',
        version: '1.0',
        action: 'getMenuList'
      }, {grade:grade
      }
    ).then( response => {
      this.menuList$.next(response.data);

      return response || [];
    }).catch(this.restful.handleErrorPromise);
  }

  // 게시판 정보 리스트 가져오기
  getBoardInfoList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfoList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통코드 리스트 가져오기
  getCommonListCode( code: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonListCode'
      }, {
        code: code
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시판 리스트 가져오기
  getBoardList( params ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'board',
        version: '1.0',
        action: 'getBoardList'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 정보 가져오기
  getBoardView(board_id, board_seq, view_check ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'board',
        version: '1.0',
        action: 'getBoardView'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        view_check: view_check
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 로그인정보 가져오기
  getHomeMemberInfo(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'getMember'
      }, {
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통그룹코드 추가하기(POST방식)
  setAddCommonGroupCode( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'setAddCommonGroupCode'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  deleteCommonGroup( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'deleteCommonGroup'
      }, {
        seq: form.controls.seq.value
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통그룹코드 리스트 가져오기
  getCommonGroupList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonGroupList'
      }, {
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통그룹코드 상세 가져오기
  getCommonGroupView(seq: number): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonGroupView'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통코드 추가하기(POST방식)
  setAddCommonCode(form): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'setAddCommonCode'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통코드 삭제하기
  setCommonDelete(seq: number): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'setCommonDelete'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통코드 리스트 가져오기
  getCommonList(code: string): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonList'
      }, {
        code: code
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
}
