<div class="mt5 form-inline">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
    <i class="material-icons-outlined">refresh</i>
  </button>
  <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
    <i class="material-icons-outlined">search</i>
  </button>
  <input type="text" size="30" class="form-control form-control-small ml5" placeholder="주문번호" />
  <input type="text" size="15" readonly="readonly" id="sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
  <ng-template #footerTemplate1>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
  </ng-template>
  <span class="ml5 mr5">~</span>
  <input type="text" size="15" readonly="readonly" id="edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
  <ng-template #footerTemplate2>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
  </ng-template>  

</div>

<as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" class="mt5" style="height: calc(100vh - 130px);background-color:#fff;">
  <as-split-area size="50" minSize="40" maxSize="80">
    <as-split unit="percent" restrictMove="true" gutterSize="10">
      <as-split-area size="30" minSize="20" maxSize="60">
        <div>
          <div class="fl form-inline">
            <h4 class="mb0 fb">주문내역</h4>
            <div class="btn-group ml20">
              <button type="button" [ngClass]="{active: search.state=='1'}" class="btn btn-default btn-small" (click)="search.state='1'">미출고만</button>
              <button type="button" [ngClass]="{active: search.state==''}" class="btn btn-default btn-small" (click)="search.state=''">전체</button>
            </div>		          
          </div>
          <div class="fr right">
            <button type="button" class="btn btn-success btn-small">운송장번호관리</button>
          </div>
        </div>
    
        <div class="cb mb5"></div>
        <div style="height:calc(calc(100% - 55px))">
          <ag-grid-angular
            #myGrid
    
            style="width: 100%;height:100%;"
            class="ag-theme-balham"
    
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="orderList"
            [pagination]="false"
            [domLayout]="domLayout"
            [rowSelection]="rowSelection"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"

            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked($event)"
          >
          </ag-grid-angular>
        </div>       
      </as-split-area>
      <as-split-area size="70" minSize="20" maxSize="80">
        <div class="mb5">
          <div class="fl form-inline">
            <div class="btn-group">
              <button type="button" [ngClass]="{active: search.state1=='1'}" class="btn btn-default btn-small" (click)="search.state1='1'">매입만</button>
              <button type="button" [ngClass]="{active: search.state1==''}" class="btn btn-default btn-small" (click)="search.state1=''">전체</button>
            </div>   
            <div class="btn-group ml5">
              <button type="button" [ngClass]="{active: search.state2=='1'}" class="btn btn-default btn-small" (click)="search.state2='1'">미출고만</button>
              <button type="button" [ngClass]="{active: search.state2==''}" class="btn btn-default btn-small" (click)="search.state2=''">전체</button>
            </div>  
            <input type="text" size="15" readonly="readonly" id="odate" class="form-control form-control-small calendar-input ml5" placeholder="출고요청일" ngbDatepicker [footerTemplate]="footerTemplate1" #d3="ngbDatepicker" (click)="d3.toggle()" />
            <ng-template #footerTemplate1>
              <hr class="my-0">
              <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d3, true)">오늘</button>
              <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d3.close()">닫기</button>
            </ng-template>     
            <select class="form-control form-control-small ml5">
              <option>대구창고</option>
            </select>  					              
          </div>
          <div class="fr right">
            <button type="button" class="btn btn-primary btn-small">출고지시</button>
          </div>
          <div class="cb"></div>
        </div>
        
        <div style="height:calc(calc(100% - 55px))">
          <ag-grid-angular
            #myGrid
  
            style="width: 100%;height:100%;"
            class="ag-theme-balham"
  
            [columnDefs]="columnDefsDetail"
            [defaultColDef]="defaultColDef"
            [rowData]="orderDetailList"
            [pagination]="false"
            [domLayout]="domLayout"
            [rowSelection]="rowSelection"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"

            (gridReady)="onDetailGridReady($event)"
          >
          </ag-grid-angular>
        </div>      
        
      </as-split-area>
    </as-split>      
  </as-split-area>
  <as-split-area size="50" minSize="20" maxSize="60">
    <div class="mb5">
      <div class="fl w50p">
        <h4 class="mb0 fb">출고지시내역</h4>
      </div>
      <div class="fr w50p right">
        <button type="button" class="btn btn-default btn-small mr20">그리드저장</button>
        <button type="button" class="btn btn-primary btn-small ml5">저장하기</button>
        <button type="button" class="btn btn-danger btn-small ml5">삭제</button>          
      </div>
      <div class="cb"></div>
    </div>
    
    <div style="height:calc(calc(100% - 50px))">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefsDetail1"
        [defaultColDef]="defaultColDef"
        [rowData]="orderDetail1"
        [pagination]="false"
        [domLayout]="domLayout"
        [rowSelection]="rowSelection"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
      >
      </ag-grid-angular>
    </div>      
    
  </as-split-area>
</as-split>


