<form [formGroup]="formBarcode">

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">바코드 입력</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button> -->
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="hidden">바코드 입력</caption>

    <colgroup>
      <col style="width:25%;" />
      <col style="width:75%;" />
    </colgroup>

    <tbody>
    <tr *ngIf="product_seq">
      <th scope="row"><label for="product_name" class="control-label">상품명</label></th>
      <td>
        <inputEx type="text" name="product_name" [formGroup]="formBarcode" [formErrors]="formErrors" [readonly]="true"></inputEx>
      </td>
    </tr>
    <tr *ngIf="color_seq">
      <th scope="row"><label for="color_name" class="control-label">색상</label></th>
      <td>
        <inputEx type="text" name="color_name" [formGroup]="formBarcode" [formErrors]="formErrors" [readonly]="true"></inputEx>
      </td>
    </tr>
    <tr *ngIf="size_seq">
      <th scope="row"><label for="size_name" class="control-label">사이즈</label></th>
      <td>
        <inputEx type="text" name="size_name" [formGroup]="formBarcode" [formErrors]="formErrors" [readonly]="true"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="barcode" class="control-label">바코드 입력</label></th>
      <td>
        <inputEx type="textarea" name="barcode" [rows]="10" [formGroup]="formBarcode" [formErrors]="formErrors" (keyup)="checkAsterisk($event)"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn modal-btn blue-btn mr4" (click)="submit()">입력</button>
      <button type="button" class="btn btn-default modal-btn" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
    </div>
  </div>
</form>