import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/service/custom.validators';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { SmsService } from '@app/service/sms.service';

import { ToastrService } from 'ngx-toastr';

//import { MemberFindComponent } from '@page/member/member-find/member-find.component';
import { InputExComponent } from '@app/components/input-ex/input-ex.component';

import { ValueCache } from 'ag-grid-community';
import { write } from 'xlsx/types';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-modal-sms',
  templateUrl: './modal-sms.component.html',
  styleUrls: ['./modal-sms.component.scss']
})
export class ModalSmsComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 발송 휴대폰 리스트
  public sendList: any;

  public autoList: any = [];

  public formSMS: FormGroup;
  public formErrors = {
    message: ''
  };

  // 그리드 관련 선언
  selectedRows = 0;
  selectedRowsMember = 0;

  smsList: any = [];
  memberList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  gridApiMember: any;
  gridColumnApiMember: any;
  columnDefsMember: any;
  rowSelectionMember: any;

  public MAXIMUM = 1000; // 문자 동시 발송 최대치

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formSMS = this.formBuilder.group({
      // callback: [ '0221934300', [Validators.required, Validators.maxLength(20)] ],
      callback: [' '],
      message: [ '', [Validators.required, Validators.maxLength(80)] ],
      title: [ '', [Validators.required, Validators.maxLength(100)] ],
      receipt_tel: [ '', [Validators.required] ],
      send_type: [ 'N', [] ],
      send_date: [ '', [Validators.maxLength(20)] ]
    });

    this.formSMS.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formSMS, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private smsService: SmsService
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefsMember = [
      {
        headerName: '', field: 'mem_no', cellClass: 'cp right', width: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {headerName: '회원', field: 'name', cellClass: 'cp', width: 70, flex: 1 },
      {headerName: '휴대폰', field: 'mobile', cellClass: 'cp', width: 100, flex: 1 },
      {headerName: '아이디', field: 'id', cellClass: 'cp', width: 80, flex: 1 },
      // {headerName: '회사명', field: 'companyName', cellClass: 'cp', width: 80 },
      // {headerName: '구분', field: 'memberType', cellClass: 'cp', width: 80 },
      // {headerName: '만료일', field: 'eDay', hide: true },
    ];

    this.columnDefs = [
      { headerName: '번호', field: 'seq', cellClass: 'cp center', width: 85,
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
      },
      {headerName: '자동발송', field: 'common_name', cellClass: 'cp', width: 90 },
      {headerName: '제목', field: 'title', cellClass: 'cp', width: 100 },
      {headerName: '발송내용', field: 'contents', cellClass: 'cp', width: 150 },
      {headerName: '자동발송 코드', field: 'auto_seq', hide: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true
    };

    this.rowSelectionMember = 'multiple';
    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }


  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

    // SMS 발송 양식 가져오기
    this.getSMSFormList();

    const tmp: any = [];

    if( this.sendList.length < 1 ) {
      this.toastrService.error( 'SMS를 발송할 회원이 없습니다.', 'SMS발송' );
      this.activeModal.close();

      return false;
    }

    let wrongHP = 0;
    this.sendList.forEach( row => {
      if( row.hp.replace(/01[0-9]{1}-[0-9]{3,}-[0-9]{4}/g, '') && ++wrongHP ) {
      } else {
        tmp.push({
          // mem_no: row.memNo,
          // name: row.userName,
          // mobile: row.mobile,
          // id: row.userId,
          // eDay: row.eDay,
          // applDate: row.applDate,
          // payDate: row.payDate,
          // companyName: row.companyName,
          // memberType: row.memberType
          mem_no: row.mem_no,
          name: row.name,
          mobile: row.hp,
          id: row.id,

          eDay: row.eDay,
          applDate: row.applDate,
          payDate: row.payDate,
        });
      }
    });
    wrongHP ? this.toastrService.warning(`${wrongHP} 건의 올바르지 않은 번호가 있습니다. 이 번호들은 목록에서 제외됩니다.`, 'SMS발송') : '';

    this.memberList = tmp;

    this.formSMS.patchValue({
      receipt_tel: this.memberList
    });

  }

  /*******************************************************************************
    설  명 : SMS 발송양식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSMSFormList() {
    return this.smsService.getSMSFormList({searchText: ''}).then( response => {
      if ( response.ResultCode ) {
        this.smsList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage );
      }
    });
  }

  /*******************************************************************************
    설  명 : 한글 포함 문자열 갯수 처리
    입력값 : 문자열
    리턴값 : 문자수
  *******************************************************************************/
  getTextLength(str) {
    let len = 0;

    for (let i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length === 6) {
            len++;
        }
        len++;
    }
    return len;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.formSMS.patchValue({
      title: $event.data.title,
      message: $event.data.contents
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyMember(params) {
    this.gridApiMember = params.api;
    this.gridColumnApiMember = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedMember($event) {
    let filteredRowCount = 0;
    this.gridApiMember.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRowsMember = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 선택된 발송 회원 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteMember(auto = false) {
    const nodes: any = this.gridApiMember.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.error('삭제할 휴대폰 번호를 선택하세요.');

      return false;
    }

    if ( !auto && ! confirm('선택하신 휴대폰 번호를 삭제하시겠습니까?') ) {
      return false;
    }

    const removedRows: any = [];

    for (let node of nodes ) {
      removedRows.push( node );
      this.gridApiMember.updateRowData({remove: [node]});

      const idx = this.memberList.indexOf(node);
      if (idx > -1) {
        this.memberList.splice(idx, 1);
      }
    }
  }

  /*******************************************************************************
    설  명 : SMS 발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // submit(auto = false) {
  //   let smsMembers = this.gridApiMember.getSelectedRows();

  //   if (smsMembers.length < 1) {
  //     this.toastrService.error('발송할 회원을 선택하세요.');
  //     return;
  //   }
  //   else if(smsMembers.length > this.MAXIMUM) {
  //     this.toastrService.warning(`${this.MAXIMUM} 건까지만 순차적으로 발송합니다.`);
  //     this.gridApiMember.forEachNode((e,i) => {
  //       e.setSelected(i < this.MAXIMUM);
  //     });
  //     smsMembers = this.gridApiMember.getSelectedRows();
  //   }

  //   this.utilService.makeFormDirtyAndUpdateErrors(this.formSMS, this.formErrors);

  //   if(this.formSMS.valid) {
  //     const params = {
  //       seq: typeof this.gridApi.getSelectedRows()[0] === 'undefined' ? '' :  this.gridApi.getSelectedRows()[0].auto_seq,
  //       title: this.formSMS.controls.title.value,
  //       message: this.formSMS.controls.message.value,
  //       members: smsMembers,
  //       callback: this.formSMS.controls.callback.value,
  //     };

  //     this.smsService.setSendSMS(params).then( response => {
  //       if (response.ResultCode) {
  //         this.deleteMember(true);
  //         this.toastrService.success(response.ResultMessage, 'SMS 발송');
          
  //         if(auto) {
  //           this.gridApiMember.selectAll();
  //           this.submit(this.gridApiMember.getSelectedRows().length);
  //         }
  //       } else {
  //         this.toastrService.error(response.ResultMessage, 'SMS 발송');
  //       }
  //     });

  //   } else {
  //     this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', 'SMS 발송');
  //   }
  // }

  /*******************************************************************************
    설  명 : 대량 자동 발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  autoSend() {
    if(!confirm('Warning! 회원 전체에게 문자를 보냅니다.')) return;
    this.gridApiMember.selectAll();
    this.submit(true);
  }

  /*******************************************************************************
    설  명 : directsend 문자발송
  *******************************************************************************/
  submit(auto = false) {
    const smsMembers = this.gridApiMember.getSelectedRows()

    if (!smsMembers.length) {
      this.toastrService.error('발송할 회원을 선택하세요.')
      return
    } else if(smsMembers.length > this.MAXIMUM) {
      this.toastrService.warning(`${this.MAXIMUM} 건까지만 순차적으로 발송합니다.`)
      this.gridApiMember.forEachNode((e,i) => {
        e.setSelected(i < this.MAXIMUM)
      })
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.formSMS, this.formErrors)
    if(!this.formSMS.valid) {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.')
      return
    }

    this.smsService.sendSMS_directsend({
      title: this.formSMS.value['title'],
      message: this.formSMS.value['message'].replace(/(#고객명)/g, '[$NAME]'),
      receiver: smsMembers.map(e => ({ ...e, mobile : e['mobile'].replace(/-/g, '') }))
    }).then( response => {
      if (response.ResultCode) {
        this.deleteMember(true)
        this.toastrService.success(response.ResultMessage)
        
        if(auto) {
          this.gridApiMember.selectAll()
          this.submit(this.gridApiMember.getSelectedRows().length)
        }
      } else this.toastrService.error(response.ResultMessage)
    })
  }

}
