/*******************************************************************************
  설  명 : 주문관리 - 업체품절 메모
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateStruct, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { StockService } from '@app/service/stock.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import * as moment from 'moment';

const pageRow: number = 1000;

@Component({
  selector: 'app-order-outofstock-memo',
  templateUrl: './order-outofstock-memo.component.html',
  styleUrls: ['./order-outofstock-memo.component.scss']
})
export class OrderOutofstockMemoComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    seq: '',
    pageNo: 1,
    pageRow: pageRow,
    pageNo1: 1,
    pageRow1: pageRow,
    sdate: '',
    edate: '',
    searchText: '',
    searchTerm: 'A',
  };

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    totalCount1: 0,
    searchCount1: 0
  }

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
  ];

  outofStockList: any = [];
  scmMemoList: any = [];

  activeTab: number = 0;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  // 그리드 관련 선언
  gridApiSCM: any;
  gridColumnApiSCM: any;
  columnDefsSCM: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private calendar: NgbCalendar,
    private stockService: StockService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '번호', field: 'seq', width: 120, cellClass: 'cp center',headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '결제일자', field: 'pay_date', width: 120, cellClass: 'cp center' },
      { headerName: '주문일시', field: 'order_date', width: 150, cellClass: 'cp center' },
      { headerName: '몰타입', field: 'name', width: 120, cellClass: 'cp center' },
      { headerName: '비고', field: 'r_comment', width: 300, cellClass: 'cp ' },
      { headerName: '재고여부', field: 'networks_status_name', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let badge = 'default';

          if ( params.data.networks_status == '1' ) badge = 'danger';
          else if ( params.data.networks_status == '2' ) badge = 'warning';
          else if ( params.data.networks_status == '3' ) badge = 'success';
          else if ( params.data.networks_status == '4' ) badge = 'danger';

          return '<span class="f11 badge badge-' + badge + '">' + params.data.networks_status_name + '</span>';
        }
      },
      { headerName: '상품명', field: 'product_name', width: 400, cellClass: 'cp' },
      { headerName: '품절확인', field: '', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return '<button type="button" class="btn btn-default btn-small btn-xsmall">품절확인</button>';
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefsSCM = [
      { headerName: '번호', field: 'seq', width: 120, cellClass: 'cp center',headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '몰타입', field: 'malltype', width: 150, cellClass: 'cp center' },
      { headerName: '요청사항', field: 'comment', width: 800, cellClass: 'cp' },
      { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.searchInit();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onTabChange(value: any) {
    this.activeTab = value;

    this.getList();
  }

  /*******************************************************************************
    설  명 : 업체 품절메모 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOutofstockMemoList() {
    this.stockService.getOutofstockMemoList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.outofStockList = response.data;
        this.statistic.searchCount = response.statistic.searchCount;
        this.statistic.totalCount = response.statistic.totalCount;
      } else {
        this.outofStockList = []
      }
    });
  }

  /*******************************************************************************
    설  명 : 업체 요청사항 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSCMMemoList() {
    this.stockService.getSCMMemoList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.scmMemoList = response.data;
        this.statistic.searchCount1 = response.statistic.searchCount;
        this.statistic.totalCount1 = response.statistic.totalCount;
      } else {
        this.scmMemoList = []
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      seq: '',
      pageNo: 1,
      pageRow: pageRow,
      pageNo1: 1,
      pageRow1: pageRow,
      sdate: '',
      edate: '',
      searchText: '',
      searchTerm: 'A',
    };

    this.getList();
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }

    this.getList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getList();
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : page = 페이지번호
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getList();
  }

  /*******************************************************************************
    설  명 : 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getList() {
    if( this.activeTab == 0 ) {
      this.getSCMMemoList();
    } else {
      this.getOutofstockMemoList();
    }
  }

  /*******************************************************************************
  설  명 : 업체품절메모탭 ag grid 행 클릭 시 처리 - 주문 정보 조회
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field === 'seq' || $event.colDef.field === 'pay_date' || $event.colDef.field === 'order_date' || $event.colDef.field === 'networks_status_name') {
      this.goDetail( $event.data.seq );
    }
  }

  /*******************************************************************************
    설  명 : 업체요청사항탭 ag grid 행 클릭 시 처리 - 주문 정보 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked1($event) {
    if( $event.colDef.field === 'seq' || $event.colDef.field === 'comment' ) {
      this.goDetail( $event.data.order_seq );
    }
  }

  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( seq: any ) {
    const url = '/order/detail?seq=' + seq;
    window.open("about:blank").location.href = url;
  }
}
