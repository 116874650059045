/*******************************************************************************
  설  명 : 상품관리 - 상품 순서관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { ProductService } from '@app/service/product.service';

import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-product-sort',
  templateUrl: './product-sort.component.html',
  styleUrls: ['./product-sort.component.scss']
})
export class ProductSortComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public productList: any = [];
  public categoryList: any = [];
  public brandList: any = [];

  public params: any = {
    category_seq: '',
    brand_seq: '',
    searchField: 'product_name',
    searchText: '',
    status: '',
  };

  public activeTab: any;
  public components: any;

  // 그리드 관련 선언
  gridApiCategory: any;
  gridColumnApiCategory: any;
  columnDefsCategory: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiBrand: any;
  gridColumnApiBrand: any;
  columnDefsBrand: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private productService: ProductService,
    private agGridExComponent: AgGridExComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefsCategory = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '카테고리명', field: 'category_name_tree', width: 300, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 100, cellClass: 'cp right' },
    ];

    // ag grid 컬럼 선언
    this.columnDefsBrand = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '브랜드명', field: 'brand_name', width: 300, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 100, cellClass: 'cp right' },
    ];

    // ag grid 컬럼 선언
    this.columnDefs = [
      // { headerName: '', field: 'mem_no', width: 50, cellClass: 'cp center',
      // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '이동', field: '', width: 50, cellClass: 'cp center', rowDrag: true, filter: false },
      { headerName: '정렬번호', field: 'sort_order', width: 90, cellClass: 'cp center ag-cell-edit', editable: true, singleClickEdit: true, cellEditor: "numericCellEditor" },
      { headerName: '이미지', field: 'thumbnail_url', width: 130, cellClass: 'cp center', cellRenderer: 'agGridImageComponent',
        cellRendererParams: {
          isTooltip: true
        }
      // cellRendererFramework: ToolTipComponent,
      // cellRendererParams: (params: ICellRendererParams) => this.formatToolTip(params.data)
      },
      // { headerName: '이미지', field: 'thumbnail_url', width: 140, cellClass: 'cp center' },
      { headerName: '카테고리', field: 'category_name', width: 200, cellClass: 'cp center' },
      { headerName: '메이커', field: 'brand_name', width: 100, cellClass: 'cp center' },
      { headerName: '모델명', field: 'product_name', width: 350, cellClass: 'cp' },
      { headerName: '구매가격', field: 'buy_price', width: 100, cellClass: 'cp right',
        valueFormatter: currencyFormatter,
        valueGetter: numberGetter
      },
      { headerName: '상태', field: 'status', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status == '0' ) {
            return '<span class="badge badge-secondary f12">대기상품</span>';
          } else if ( params.data.status == '1' ) {
            return '<span class="badge badge-success f12">판매중</span>';
          } else if ( params.data.status == '2' ) {
            return '<span class="badge badge-warning f12">안전재고위험</span>';
          } else if ( params.data.status == '3' ) {
            return '<span class="badge badge-danger f12">재고없음</span>';
          } else {
            return '';
          }
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor()
    };

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      const value = params.data[ params.column.colId ];
      return ( value ) ? parseInt( value ) : value;
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCategoryListForSort();
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
   getCategoryListForSort() {
    this.categoryService.getCategoryListForSort().then( response => {
      // console.log(response);
      if( response.ResultCode ) {
        this.categoryList = response.data;

        if( this.categoryList.length > 0 ) {
          let index = 0;
          setTimeout(() => {
            this.gridApiCategory.forEachNodeAfterFilter(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }, 0);

          this.params.category_seq = this.categoryList[0].seq;
          this.params.brand_seq = '';

          this.getProductListForSort();
        }
      } else {
        this.categoryList = [];
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandListForSort() {
    this.brandService.getBrandListForSort().then( response => {
      // console.log(response);
      if( response.ResultCode ) {
        this.brandList = response.data;

        if( this.brandList.length > 0 ) {
          let index = 0;
          setTimeout(() => {
            this.gridApiBrand.forEachNodeAfterFilter(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }, 0);

          this.params.category_seq = '';
          this.params.brand_seq = this.brandList[0].seq;

          this.getProductListForSort();
        }
      } else {
        this.brandList = [];
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductListForSort() {
    this.productService.getProductListForSort( this.params ).then( response => {
      // console.log(response);
      if( response.ResultCode ) {
        this.productList = response.data;
      } else {
        this.productList = [];
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 정렬번호 변경 시 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSortOrderSave( data: any ) {
    this.productService.setSortOrderSave( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');

        this.getProductListForSort();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 순서 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSortOrderSaveAll() {
    const data: any = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(node) {
      data.push( node.data.seq );
    });

    if ( confirm('해당 순서로 저장하시겠습니까?') ) {
      this.productService.setSortOrderSaveAll( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductListForSort();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCategory(params) {
    this.gridApiCategory = params.api;
    this.gridColumnApiCategory = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyBrand(params) {
    this.gridApiBrand = params.api;
    this.gridColumnApiBrand = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 공통코드 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCategoryGridRowClicked(event: any) {
    this.params.brand_seq = '';
    this.params.category_seq = event.data.seq;

    this.getProductListForSort();
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onBrandGridRowClicked(event: any) {
    this.params.category_seq = '';
    this.params.brand_seq = event.data.seq;

    this.getProductListForSort();
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 공통코드 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onTabChange(value: any) {
    this.activeTab = value;

    if( this.activeTab === 0 ) {
      this.getCategoryListForSort();
    } else {
      this.getBrandListForSort();
    }
  }

  /*******************************************************************************
    설  명 : ag grid 셀 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStarted( event: any ) {
    event.data['old_sort_order'] = event.value;
  }

  onCellEditingStopped( event: any ) {
    if( event.value != event.data.old_sort_order ) {
      this.setSortOrderSave( event.data );
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.params.searchField = 'product_name';
    this.params.searchText = '';
    this.params.status = '';

    this.getProductListForSort();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductListForSort();
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼에서 클릭 시 처리
    입력값 : key = this.params 키 값, value = 적용 값
    리턴값 : 없음
  *******************************************************************************/
  searchParams( key, value ) {
    this.params[key] = value;

    this.getProductListForSort();
  }

}
