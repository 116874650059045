  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{orderInitialList.length}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>
  
  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" class="mt5" style="height: calc(100vh - 150px);background-color:#fff;">
    <as-split-area size="50" minSize="40" maxSize="80">
		  <div class="mt5">
		    <div class="fl form-inline">
		      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
		        <i class="material-icons-outlined">refresh</i>
		      </button>
		      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getOrderInitialList()">
		        <i class="material-icons-outlined">search</i>
		      </button>
		      <input type="text" size="30" class="form-control form-control-small ml5" (keypress)="searchList($event)" placeholder="초도주문명칭" [(ngModel)]="search.orderText"/>
		    </div>
		    <div class="fr right">
		      <button type="button" class="btn btn-default btn-small">그리드저장</button>
		      <button type="button" class="btn btn-success btn-small ml5" (click)="adminOrderAdd()">관리자 주문등록</button>
		      <button type="button" class="btn btn-primary btn-small ml5" (click)="orderAdd(0)">초도주문등록</button>
		      <button type="button" class="btn btn-danger btn-small ml5" (click)="delOrderInitialList()">초도주문삭제</button>          
		    </div>
		  </div>
	
	    <div class="cb mb5"></div>
	    <div style="height:calc(calc(100% - 100px))">
	      <ag-grid-angular
	        #myGrid
	
	        style="width: 100%;height:100%;"
	        class="ag-theme-balham"
	
	        [columnDefs]="columnDefs"
	        [defaultColDef]="defaultColDef"
	        [rowData]="orderInitialList"
	        [pagination]="false"
	        [domLayout]="domLayout"
	        [rowSelection]="rowSelection"
	        [overlayNoRowsTemplate]="noRowsTemplate"
	        [frameworkComponents]="frameworkComponents"

					(gridReady)="onGridReady($event)"
					(rowClicked)="onRowClicked($event)"
					(cellClicked)="onCellClicked($event)"

	      >
	      </ag-grid-angular>
		    <section class="pagination mt10">
		      <ngb-pagination
		        [page]="1"
		        [pageSize]="10"
		        [collectionSize]="10"
		        [maxSize]="20"
		        [rotate]="true"
		        [boundaryLinks]="true"
		        >
		        <ng-template ngbPaginationFirst>처음</ng-template>
		        <ng-template ngbPaginationLast>마지막</ng-template>
		        <ng-template ngbPaginationPrevious>이전</ng-template>
		        <ng-template ngbPaginationNext>다음</ng-template>
		      </ngb-pagination>
		    </section>	      
	    </div>       
    </as-split-area>
    <as-split-area size="50" minSize="20" maxSize="60">
      <div class="mb5">
        <div class="fl w50p">
          <h4 class="mb0 fb">주문 상세정보</h4>
        </div>
        <div class="fr w50p right">
          <button type="button" class="btn btn-default btn-small">그리드저장</button>
          <button type="button" class="btn btn-success btn-small ml5" (click)="priceChange()">판매단가일괄변경</button>
		      <button type="button" class="btn btn-primary btn-small ml5" (click)="productAdd()">상품추가</button>
		      <button type="button" class="btn btn-primary btn-small ml5" (click)="setInitialOrderSave()">저장하기</button>
		      <button type="button" class="btn btn-danger btn-small ml5" (click)="deleteProduct()">상품삭제</button>
					      
        </div>
        <div class="cb"></div>
      </div>
      
      <div style="height:calc(calc(100% - 50px))">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsDetail"
          [defaultColDef]="defaultColDef"
          [rowData]="form.controls.detail.value"
					[getRowHeight]="getRowHeight"
					[stopEditingWhenGridLosesFocus]="true"  
          [pagination]="false"
					[components]="components"
          [domLayout]="domLayout"
          [rowSelection]="rowSelectionDetail"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

					(gridReady)="onGridApiDetail($event)"
        >
        </ag-grid-angular>
      </div>      
      
    </as-split-area>
  </as-split>

