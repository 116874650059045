<div class="fl w30p">
  <div class="fb mt5"><i class="material-icons cred f11 lh20">star</i> 품절체크</div>
</div>
<div class="mb5">
  <div class="fl w70p form-inline">
  </div>
  <div class="fr w30p right">
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
    <!-- <button type="button" class="btn btn-default btn-small ml5" (click)="list()">목록으로</button> -->
    <button type="button" class="btn btn-primary btn-small ml5" (click)="setProductSoldOutSave()">일괄저장</button>
  </div>
  <div class="cb"></div>
</div>

<div style="height:calc(100% - 35px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="soldOutCheckList"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [pagination]="false"
    [getRowHeight]="getRowHeight"
    [domLayout]="domLayout"
    [suppressRowTransform]="true"
    [stopEditingWhenGridLosesFocus]="true"   
    [frameworkComponents]="frameworkComponents"
    [components]="components"
    [singleClickEdit]="true"
    
    (columnResized)="onColumnResized($event)"
    (columnVisible)="onColumnVisible($event)"
    (gridReady)="onGridReady($event)"
    >
    
  </ag-grid-angular>
</div>