<div class="modal-header">
  <h4 class="modal-title fb mb0">결제 확인</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

  <table class="table table-bordered table-small mt50 mb50">
  <colgroup>
    <col style="width:25%" />
    <col style="width:75%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="col1" class="control-label">변경방법</label></th>
    <td>
      <div class="btn-group form-control form-control-small">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_method.value == '0003'}" (click)="form.get('pay_method').setValue('0003')">계좌이체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_method.value == '0007'}" (click)="form.get('pay_method').setValue('0007')">외상</button>
      </div>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="col1" class="control-label">결제일자</label></th>
    <td>
      <inputEx type="date" name="pay_date" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr>    
  </tbody>
  </table>

  </form>


</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small mr5" (click)="setOrderPayComplete()">저장하기</button>
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
  </div>
</div>

