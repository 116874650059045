import { Component, OnInit, Input } from '@angular/core';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'ag-grid-excel',
  templateUrl: './ag-grid-excel.component.html',
  styleUrls: ['./ag-grid-excel.component.scss']
})
export class AgGridExcelComponent implements OnInit {

  @Input() gridApi: any;
  @Input() selected: string;
  @Input() filename: string;
  @Input() title: string;
  @Input() type: any;

  private excelFileName: string;
  private dataList = [];
  private dataStr: string = '';

  constructor(
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.excelFileName = this.filename + "_" + moment().format('YYYYMMDD') + '.xlsx';
  }

  excelExport() {
    let selectCheck = ( this.selected == 'true' ) ? true : false;
    let obj = this.gridApi.rowModel.nodeManager.allNodesMap;
    let header = this.gridApi.columnController.gridColumns;
    let columnWidth = [];

    this.dataList = [];

    for( const index in obj ) {
      let addCheck = false;

      if( selectCheck ) {
        if( obj[index].selected ) addCheck = true;
      } else {
        addCheck = true;
      }

      if( addCheck ) {
        let tmp = {};
        for( let i in header ) {
          tmp[header[i].colDef.headerName] = obj[index].data[ header[i].colDef.field ];
          columnWidth.push( { wpx: header[i].actualWidth } );
        }

        this.dataList.push( tmp );
      }
    }

    if( this.dataList.length < 1 ) {
      this.toastrService.error( '엑셀로 출력할 데이터가 없습니다.', '' );
      return false;
    } else {

      let title = [ [ this.title ], [] ];
      var worksheet: XLSX.WorkSheet;

      // 제목 추가
      worksheet = XLSX.utils.sheet_add_aoa( worksheet, title );
      // 색상변경 등은 프로 유료 버전에서만 지원
      //worksheet['A1'] = {v: this.title, t: "s", s: { alignment: 'center', font: {size: 14, bold: true, color: '#FF00FF' }}};

      /*
        s: { alignment: {textRotation: 90 },
        font: {sz: 14, bold: true, color: #FF00FF }
      */

      let index = 0;
      for(let item of this.dataList ) {
        for(let key in item ) {
          if( this.isNumeric( item[key] ) ) {
            this.dataList[index][key] = parseInt(String(this.dataList[index][key]).replace(',',''));
          }
        }

        index++;
      }

      // 데이터 추가
      worksheet = XLSX.utils.sheet_add_json( worksheet, this.dataList, {origin: "A3"} );

      // 워크북 생성
      var workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

      // 컬럼 사이즈 지정
      worksheet['!cols'] = columnWidth;
      /*
      열 머지 처리
      worksheet['!merges'] = [
        {s:{r:0,c:0},e:{r:0,c: header.length } },
        {s:{r:1,c:0},e:{r:1,c: header.length } }
      ];
      */

      // 엑셀 버퍼로 보내기
      var excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // 파일로 저장
      this.saveAsExcelFile(excelBuffer, this.excelFileName);

      return true;
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});

     FileSaver.saveAs( data, fileName );
  }


  isNumeric(num, opt = null){
    // 좌우 trim(공백제거)을 해준다.
    num = String(num).replace(/^\s+|\s+$/g, "");

    // 모든 10진수 (부호 선택, 자릿수구분기호 선택, 소수점 선택)
    if(typeof opt == "undefined" || opt == null || opt == "1") {
      var regex = /^[+\-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;

    // 부호 미사용, 자릿수구분기호 선택, 소수점 선택
    } else if(opt == "2") {
      var regex = /^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;

    // 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
    } else if(opt == "3") {
      var regex = /^[0-9]+(\.[0-9]+)?$/g;

    // only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
    } else {
      var regex = /^[0-9]$/g;
    }

    if( regex.test(num) ) {
      num = num.replace(/,/g, "");
      return isNaN(num) ? false : true;
    } else {
      return false;
    }
  }

}
