<div class="modal-header pb10">
  <h4 class="modal-title fb">속성추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="setDismiss()">&times;</button>
</div>

<div class="modal-body p10 mb5">
  <div class="header">
    <div class="fl w50p">
      <span class="f12"></span>
    </div>
    <div class="fr w50p form-inline">
      <select [(ngModel)]="search.category_code" class="form-control form-control-small ml5 mb5" (change)="getSizeList()">
        <option value="">카테고리 전체</option>
        <option *ngFor="let item of sizeCategoryList" value="{{item.category_code}}">{{item.category_nm}}</option>
      </select>
    </div>
    <div class="cb"></div>
  </div>

  <div class="h100">
    <div style="height: 100%;">
      <ag-grid-angular
        #myGrid

        style="width: calc(50% - 5px); height: 500px; float: left; margin-right: 10px;"
        class="ag-theme-balham"

        [columnDefs]="colorColumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="colorList"
        [pagination]="false"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (gridReady)="onColorGridReady($event)"
        >
      </ag-grid-angular>
    </div>

    <div style="height: 100%;">
      <ag-grid-angular
        #myGrid

        style="width: calc(50% - 5px); height: 500px; float: left;"
        class="ag-theme-balham"

        [columnDefs]="sizeColumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="sizeList"
        [pagination]="false"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (gridReady)="onSizeGridReady($event)"
        >
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="modal-footer p10">
  <button type="button" class="btn btn-default btn-small f15 mr10" aria-label="Close" (click)="setDismiss()">창닫기</button>
  <button type="button" class="btn btn-primary btn-small f15" (click)="setPropertySave()">추가하기</button>
</div>
