  <div class="modal-header">
    <h4 class="modal-title fb mb0">판매가 일괄변경</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <form [formGroup]="form">

    <table class="table table-bordered table-small mt50 mb50">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="col1" class="control-label">변경방법</label></th>
      <td>
        <div class="btn-group form-control form-control-small">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.method.value == '1'}" (click)="form.get('method').setValue('1')">변경금액선택</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.method.value == '2'}" (click)="form.get('method').setValue('2')">금액수동입력</button>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="col1" class="control-label">변경금액</label></th>
      <td>
        <div class="btn-group form-control form-control-small" *ngIf="form.controls.method.value == '1'">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.price_kind.value == '1'}" (click)="form.get('price_kind').setValue('1')">상사가</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.price_kind.value == '2'}" (click)="form.get('price_kind').setValue('2')">프렌차이즈가</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.price_kind.value == '3'}" (click)="form.get('price_kind').setValue('3')">협력가</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.price_kind.value == '4'}" (click)="form.get('price_kind').setValue('4')">소비자가</button>
        </div>
        <label class="f12 form-inline amt" *ngIf="form.controls.method.value == '2'">금액 
          <input type="text" class="form-control form-control-small ml10" formControlName="price" [ngClass]="{formErrors:formErrors['price']}" >
        </label>
      </td>
    </tr>    
    </tbody>
    </table>

    </form>


  </div>

  <div class="modal-footer">
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small mr5" (click)="priceChangeSave()">저장하기</button>
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
  </div>

