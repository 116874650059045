<ng-container *ngIf="!type">
  <button type="submit" class="btn btn-default btn-small f12 ml5" (click)="excelExport()">
    <span *ngIf="selected=='true'">엑셀다운</span>
    <span *ngIf="selected=='false'">전체엑셀다운</span>
  </button>
</ng-container>

<ng-container *ngIf="type=='DORMANT'">
  <button type="submit" class="btn btn-danger btn-small f12 ml5" (click)="excelExport()">
    <span *ngIf="selected=='false'">휴면예정 엑셀다운</span>
  </button>
</ng-container>
