import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ScmStockService {

  constructor(
    private restful: RestfulService,
  ) {
  }

  // 상품 재고 정보 get
  getProductStockList( search: any ): Promise<any> {
    return this.restful.get({
      program: 'scm',
      service: 'stock',
      version: '1.0',
      action: 'getProductStockList'
    }, search
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 상품 정보 get
  getProduct( params ): Promise<any> {
    return this.restful.get({
      program: 'scm',
      service: 'product',
      version: '1.0',
      action: 'getProduct'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 상품 상세 정보 get
  getProductDetail( params ): Promise<any> {
    return this.restful.get({
      program: 'scm',
      service: 'product',
      version: '1.0',
      action: 'getProductDetail'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 상품 추가
  setProduct( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'product',
      version: '1.0',
      action: 'setProduct'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 품절 처리
  setSoldOut( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'product',
      version: '1.0',
      action: 'setSoldOut'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 위젯 가져오기
  getWidget( params ): Promise<any> {
    return this.restful.get({
      program: 'scm',
      service: 'product',
      version: '1.0',
      action: 'getWidget'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 상품 메모 추가하기
  setMemo( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'product',
      version: '1.0',
      action: 'setMemo'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 상품 상세 품절 처리
  setSoldOutProperty( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'product',
      version: '1.0',
      action: 'setSoldOutProperty'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 입점업체 사용중인 카테고리 리스트 가져오기
  getProductCategoryList(): Promise<any> {
    return this.restful.get({
        program: 'scm',
        service: 'product',
        version: '1.0',
        action: 'getProductCategoryList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 대표이미지 설정
  setProductImageDefault( file: any ): Promise<any> {
    return this.restful.post({
        program: 'scm',
        service: 'product',
        version: '1.0',
        action: 'setProductImageDefault'
      }, file
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 색상 및 사이즈 변경 시 자동 저장
  setPropertySave( data: any ): Promise<any> {
    return this.restful.post({
        program: 'scm',
        service: 'product',
        version: '1.0',
        action: 'setPropertySave'
      }, data
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
