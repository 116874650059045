import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuSearch'
})
export class MenuSearchPipe implements PipeTransform {
  constructor() {
  }

  public transform(value: any, search: string) {
    let tmp: any = [];
    let rootNode: any = [];

    if( search != '' ) {
      for(let nodes of value.data ) {
        if( this.checkChildren( nodes, search ) ) {
          rootNode = Object.assign({}, nodes); // Object.assign IE 미지원
          rootNode.menus = [];

          for( let node of nodes.menus ) {
            if( 
              node.menu_name.indexOf( search ) > -1 ||
              node.tag?.indexOf( search ) > -1
            ) rootNode.menus.push( node );
          }

          tmp.push( rootNode );
        }
      }
    } else {
      tmp = value;
    }

    return tmp;
  }

  checkChildren( nodes: any, search: string ) {
    if( typeof nodes.menus == 'undefined' ) return false;

    for( let node of nodes.menus ) {
      if( 
        node.menu_name.indexOf( search ) > -1 ||
        node.tag?.indexOf( search ) > -1
      ) return true;
    }

    return false;
  }

}
