<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="mt5" style="height:calc(100% - 35px)">
    <div class="">
      <table class="table table-bordered table-small1 mb0">
      <colgroup>
        <col style="width:30%" />
        <col style="width:70%" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row" class="active"><label for="picture">사원 이미지</label></th>
        <td>
          <div class="fl mr5" style="width:50px;">
            <img *ngIf="form.controls.picture_url.value != null" [src]="form.controls.picture_url.value" style="width:40px;height:40px;border-radius:100%;" />
            <img *ngIf="form.controls.picture_url.value == null" src="/assets/images/no_image.png" style="width:40px;height:40px;border-radius:100%;" />
            <div *ngIf="form.controls.picture_url.value != '/assets/images/no_image.png' && form.controls.picture_url.value != null" class="mt5">
              <button type="button" class="btn btn-danger btn-small" (click)="deleteImage();">삭제</button>
            </div>
          </div>

          <div class="fl" style="width: calc(100% - 55px);">
            <input type="file" name="picture" id="picture" formControlName="picture" ng2FileSelect (change)="handleUploadFileChanged($event)" style="width: 100%;"/>

            <input type="hidden" name="picture_url" id="picture_url" formControlName="picture_url" />
            <input type="hidden" name="picture_origin" id="picture_origin" formControlName="picture_origin" />
          </div>

          <div class="cb"></div>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="id"><i class="material-icons cred f11">star</i> 사원아이디</label></th>
        <td>
          <inputEx type="text" name="id" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="name"><i class="material-icons cred f11">star</i> 사원명</label></th>
        <td>
          <inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="email">이메일</label></th>
        <td>
          <inputEx type="text" name="email" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="birthdate">생년월일</label></th>
        <td>
          <inputEx type="date" name="birthdate" [formGroup]="form" [formErrors]="formErrors" placeholder="생년월일을 선택하세요"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="birth_sec">양력/음력</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.birth_sec.value=='S'}" (click)="form.patchValue({birth_sec:'S'})">양력</button>
            <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.birth_sec.value=='M'}" (click)="form.patchValue({birth_sec:'M'})">음력</button>
          </div>
          <inputEx type="hidden" name="birth_sec" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="tel">전화</label></th>
        <td>
          <inputEx type="text" name="tel" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="hp">휴대폰</label></th>
        <td>
          <inputEx type="text" name="hp" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="extension_number">내선번호</label></th>
        <td>
          <inputEx type="text" name="extension_number" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="emergency_tel">비상연락망</label></th>
        <td>
          <inputEx type="text" name="emergency_tel" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="zipcode">우편번호</label></th>
        <td>
          <div class="form-inline">
            <inputEx type="text" name="zipcode" readonly="true" size="10" class="mr5" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="address">주소</label></th>
        <td>
          <inputEx type="text" name="address" readonly="true" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="address_detail">상세주소</label></th>
        <td>
          <inputEx type="text" name="address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>

    </div>
    <div class="cb"></div>

    <div class="right">
      <button type="submit" class="btn btn-primary btn-small ml5">저장하기</button>
    </div>
  </div>
</form>