/*******************************************************************************
  설  명 : 그리드 컬럼 변경 정보를 저장한다.
  작성일 : 2021-05-17
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AgGridService } from '@app/service/ag-grid.service';
import { CommonService } from '@app/service/common.service';

import * as $ from 'jquery';

@Component({
  selector: 'btn-ag-grid-save',
  templateUrl: './ag-grid-save.component.html',
  styleUrls: ['./ag-grid-save.component.scss']
})
export class AgGridSaveComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() gridColumnsApi: any;
  @Input() gridNumber: number;
  @Input() btnGroup: number;
  @Input() currMenu: any;

  // 현재 페이지 메뉴번호
  private menuSeq: any = 0;
  
  private currMenuSubscription: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private agGridService: AgGridService,
    private commonService: CommonService
  ) {

  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 현재 페이지가 변경될 경우 처리
    this.currMenuSubscription = this.commonService.currMenu$.subscribe( data => {
      this.menuSeq = data['seq'];

      if( this.menuSeq ) this.gridLoad();
    });
  }

  ngOnDestroy() {
    this.currMenuSubscription.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 현재 메뉴번호에 저장된 그리드를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  gridLoad() {
    this.agGridService.getGridInfo( this.menuSeq ).then( response => {
      if( response.ResultCode ) {
        setTimeout (() => {
          for( let index in response.data ) {
            // 그리드 동기화
            if( response.data[index].grid_num == this.gridNumber ) {
              let json = $.parseJSON( response.data[index].json );
              let moveCol = [];

              // 컬럼 순서 저장
              for( let i in json ) moveCol.push( json[i].field );

              // 컬럼 순서 변경
              try {
                if( typeof this.gridColumnsApi !== 'undefined' ) {
                  this.gridColumnsApi.moveColumns( moveCol, 0 );

                  // 컬럼 사이즈 조정
                  for( let i in json ) this.gridColumnsApi.setColumnWidth( json[i].field, json[i].actualWidth );
                }
              } catch(e) {
                console.log( e, this.gridColumnsApi, moveCol );
              }
              break;
            }
          }
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 현재 메뉴번호의 그리드 정보를 저장한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  gridSave() {
    if( typeof this.gridColumnsApi == 'undefined' ) {
      this.toastrService.error( '그리드 컬럼 API가 연결되지 않았습니다. 해당 값을 먼저 선언하세요.', '');
      return false;
    } else {
      let saveJSON = [];

      for( let index in this.gridColumnsApi.columnController.allDisplayedColumns ) {
        let column = this.gridColumnsApi.columnController.allDisplayedColumns[index];

        let json = { actualWidth: column.actualWidth, field: column.colId };

        saveJSON.push( json );
      }

      this.agGridService.setGridInfo(
        this.menuSeq,
        this.gridNumber,
        JSON.stringify(saveJSON)
      ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( '그리드 정보를 성공적으로 저장하였습니다.', '');
          return true;
        } else {
          this.toastrService.error( '그리드 정보를 저장하는데 실패하였습니다.', '');
          return false;
        }
      });
    }
  }

}
