  <header>
    <div class="logo-div">
      <a class="logo" routerLink="/dashboard">bikemart</a>
    </div>

    <div class="header">
      <div class="fl">
        <!-- <div class="top-search-div">
          <input type="text" class="top-search" placeholder="회원이름/아이디/휴대폰번호/주문번호" [(ngModel)]="topSearchText" (keypress)="topSearchEnter($event)" />

          <span class="top-search-icon material-icons cp" (click)="topSearchOrder()">search</span>
        </div>

        <div class="header-menu">
          <button mat-button class="my-menu-setting" (click)="favoriteMenu()">
            나만의메뉴
            <mat-icon class="material-icons-outlined">settings</mat-icon>
          </button>

          <app-mat-menu-trigger [menuData]="menuData"></app-mat-menu-trigger>
        </div> -->
      </div>

      <div class="fr">
        <!-- 알림 메시지 -->
        <!-- <a mat-button class="top-btn btn-alarm cp pt2" (click)="openSnackBar()">
          <mat-icon class="material-icons-outlined" matBadge="1" matBadgeColor="warn" matBadgeSize="small">notifications</mat-icon>
        </a> -->

        <!-- 조직정보 -->
        <!-- <a mat-button class="top-btn btn-person" (click)="isOrganization=!isOrganization">
          <span class="material-icons-outlined">person_outline</span>
        </a> -->

        <!-- 구글접속통계 링크 -->
        <!-- <a href="https://analytics.google.com/analytics/web/?hl=ko" target="_blank" mat-button class="top-btn btn-person">
          <span class="material-icons-outlined">analytics</span>
        </a> -->

        <!-- A/S -->
        <a mat-button class="top-btn btn-as" href="https://gw.o2oz.net" target="_blank">
          <span class="material-icons-outlined">headset_mic</span>
        </a>

        <!-- 홈페이지 -->
        <!-- <a mat-button class="top-btn btn-home" href="http://www.bikemart.co.kr" target="_blank">
          <span class="material-icons-outlined">home</span>
        </a> -->

        <!-- 로그아웃 -->
        <a mat-button class="top-btn btn-logout" (click)="logout()">
          <span class="material-icons-outlined">exit_to_app</span>
        </a>
      </div>

      <div class="cb"></div>
    </div>

    <div class="alarm-div" [ngClass]="{'alarm-div-active': isAlarm===true}" style="overflow:auto;overflow-x: hidden;">
      <div class="alarm-alert">
        <div mat-card-avatar class="example-header-image">
          <ng-template #customNotification let-notificationData="notification">
            <div type="notificationData.type" class="notifier_message">{{notificationData.message}}</div>
            <button type="button" (click)="hideNotifier(notificationData)" aria-label="Close" class="close">&times;</button>
          </ng-template>
          <notifier-container></notifier-container>
        </div>
      </div>
    </div>

    <!-- <div class="organization-div" [ngClass]="{'organization-div-active': isOrganization===true}">
      <div class="organization-inner">
        <mat-tab-group>
          <mat-tab label="조직도">
            <div style="height:calc(100% - 200px)">
              <app-tree-view
                [useSort]='false'
                [useAddNode]='false' 
                [nodeData]='nodeData'
                [picture]='true'
                [badge]='true'
                (onSelected)='onSelectNode($event)'                
                >
              </app-tree-view>
            </div>
            
            <ng-container *ngIf="member_info.mem_no != ''">
              <section style="height: 195px;">
                <div class="header-member-selected">
                  <div class="header-member-picture">
                    <img *ngIf="member_info.picture_url !== '' && member_info.picture_url !== null" [src]="member_info.picture_url" alt="{{member_info.name}}" onerror="this.src='/assets/images/no_image.png'" />
                    <img *ngIf="member_info.picture_url == '' || member_info.picture_url == null" src="/assets/images/no_image.png" alt="{{member_info.name}}" />
                  </div>

                  <div class="header-member-info">
                    <p class="header-dept-name">
                      {{member_info.dept_name}}
                    </p>
                    <p class="header-member-name">
                      {{member_info.position_name}} {{member_info.name}}({{member_info.id}})
                    </p>
                    <p class="header-member-tel">
                      {{member_info.tel}}({{member_info.extension_number}})
                    </p>
                    <p class="header-member-hp">
                      {{member_info.hp}}
                    </p>
                    <p class="header-member-email">
                      {{member_info.email}}
                    </p>
                    <ng-container *ngIf="member_info.badge !== '' && member_info.badge !== null">
                      <p class="header-member-work">
                        <span [innerHTML]="member_info.badge"></span> 
                        {{member_info.sdate}}({{utilService.getDateType('dd', member_info.sdate)}}) 
                        ~ 
                        {{member_info.edate}}({{utilService.getDateType('dd', member_info.edate)}}) 
                      </p>
                    </ng-container>
                  </div>
                </div>
              </section>
            </ng-container>
          </mat-tab>
          <mat-tab label="내정보수정">
            <app-modify-myinfo></app-modify-myinfo>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div> -->
  </header>