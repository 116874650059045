import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import 'jqueryui';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AgGridExComponent {

  constructor() {
  }

  /*******************************************************************************
    설  명 : 셀 숫자 에디터
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    
    function isCharDecimal(charStr) {
      return '.'.indexOf(charStr) === 0;
    }

    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr) || isCharDecimal(charStr);
    }

    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }

    function NumericCellEditor() {}

    NumericCellEditor.prototype.init = function(params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.style.textAlign = "right";
      this.eInput.type = 'number';

      var tmp = String(params.value);
      this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : tmp.replace(',', '');

      var that = this;
      this.eInput.addEventListener("keypress", function(event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };

    NumericCellEditor.prototype.getGui = function() {
      return this.eInput;
    };

    NumericCellEditor.prototype.afterGuiAttached = function() {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };

    NumericCellEditor.prototype.isCancelBeforeStart = function() {
      return this.cancelBeforeStart;
    };

    NumericCellEditor.prototype.isCancelAfterEnd = function() {};

    NumericCellEditor.prototype.getValue = function() {
      return this.eInput.value;
    };

    NumericCellEditor.prototype.focusIn = function() {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
    };

    NumericCellEditor.prototype.focusOut = function() {
    };

    return NumericCellEditor;
  }

  /*******************************************************************************
    설  명 : 셀 날짜 에디터
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public getDatePicker() {
    function Datepicker() {}

    Datepicker.prototype.init = function(params) {
      this.eInput = document.createElement("input");
      this.eInput.value = params.value;
      this.eInput.style.width = '100%';

      (<any>$(this.eInput)).datepicker({
        dateFormat: 'yy-mm-dd',
        prevText: '이전 달',
        nextText: '다음 달',
        closeText: "닫기",
        currentText: "오늘",
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        dayNames: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        yearSuffix: '년',
        showOtherMonths: true, //빈 공간에 현재월의 앞뒤월의 날짜를 표시
        showMonthAfterYear:true, //년도 먼저 나오고, 뒤에 월 표시
        changeYear: true, //콤보박스에서 년 선택 가능
        changeMonth: true, //콤보박스에서 월 선택 가능
        showButtonPanel: true,
        onSelect: function() {
          params.stopEditing();
        }
      });
    };
    Datepicker.prototype.getGui = function() {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function() {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function() {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function() {};
    Datepicker.prototype.isPopup = function() {
      return false;
    };
    return Datepicker;
  }

  /*******************************************************************************
    설  명 : 셀 선택 에디터, 기존의 agRichSelectCellEditor는 enterprise 버전에서만 지원
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public getSelectCellEditor() {
    function SelectCellEditor() {}

    SelectCellEditor.prototype.init = function(params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("select");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.options = params.values;

      let option = document.createElement("option");
      option.value = '';
      option.text = '선택';
      this.eInput.appendChild(option);

      for( let item of params.values ) {
        let option = document.createElement("option");
        option.value = item.seq;
        option.text = item.name;
        this.eInput.appendChild(option);
      }

      var that = this;

      this.eInput.addEventListener("keypress", function(event) {
        that.eInput.focus();
        if (event.preventDefault) event.preventDefault();
      });
    }

    SelectCellEditor.prototype.getGui = function() {
      return this.eInput;
    };

    SelectCellEditor.prototype.afterGuiAttached = function() {
      if (this.focusAfterAttached) {
        this.eInput.focus();
      }
    };

    SelectCellEditor.prototype.isCancelBeforeStart = function() {
      return this.cancelBeforeStart;
    };

    SelectCellEditor.prototype.isCancelAfterEnd = function() {};

    SelectCellEditor.prototype.getValue = function() {
      return this.eInput.value;
    };

    SelectCellEditor.prototype.focusIn = function() {
      var eInput = this.getGui();
      eInput.focus();
    };

    SelectCellEditor.prototype.focusOut = function() {

    };

    return SelectCellEditor;
  }


  /*******************************************************************************
    설  명 : 셀 선택 에디터 (선택 옵션 X)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    public getSelectEditor() {
      function SelectCellEditor() {}
  
      SelectCellEditor.prototype.init = function(params) {
        this.focusAfterAttached = params.cellStartedEdit;
        this.eInput = document.createElement("select");
        this.eInput.style.width = "100%";
        this.eInput.style.height = "100%";
        this.options = params.values;
  
        for( let item of params.values ) {
          let option = document.createElement("option");
          option.value = item.seq;
          option.text = item.name;
          if( item.seq == params.data[ params.colDef.field ] ) option.selected = true;
          this.eInput.appendChild(option);
        }
  
        var that = this;
  
        this.eInput.addEventListener("keypress", function(event) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        });
      }
  
      SelectCellEditor.prototype.getGui = function() {
        return this.eInput;
      };
  
      SelectCellEditor.prototype.afterGuiAttached = function() {
        if (this.focusAfterAttached) {
          this.eInput.focus();
        }
      };
  
      SelectCellEditor.prototype.isCancelBeforeStart = function() {
        return this.cancelBeforeStart;
      };
  
      SelectCellEditor.prototype.isCancelAfterEnd = function() {};
  
      SelectCellEditor.prototype.getValue = function() {
        return this.eInput.value;
      };
  
      SelectCellEditor.prototype.focusIn = function() {
        var eInput = this.getGui();
        eInput.focus();
      };
  
      SelectCellEditor.prototype.focusOut = function() {
  
      };
  
      return SelectCellEditor;
    }

  /*******************************************************************************
    설  명 : 셀 버튼 에디터
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public getBtnCellRenderer() {
    function BtnCellRenderer() {}

    BtnCellRenderer.prototype.init = function(params) {
      this.params = params;

      this.label = this.params.label || null;
      this.btnClass = this.params.btnClass || 'btn btn-default btn-small';

      this.eGui = document.createElement('button');
      this.eGui.className = this.btnClass;
      this.eGui.innerHTML = this.label;

      this.btnClickedHandler = this.btnClickedHandler.bind(this);
      this.eGui.addEventListener('click', this.btnClickedHandler);
    };

    BtnCellRenderer.prototype.getGui = function() {
      return this.eGui;
    };

    BtnCellRenderer.prototype.destroy = function() {
      this.eGui.removeEventListener('click', this.btnClickedHandler);
    };

    BtnCellRenderer.prototype.btnClickedHandler = function(event) {
      this.params.clicked(this.params.data);
    };

    return BtnCellRenderer;
  }

  /*******************************************************************************
    설  명 : 한번 클릭으로 셀 수정하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public getsingleClickEditRenderer() {
    function CellRenderer() {}

    CellRenderer.prototype.createGui = function() {
      const template =
        '<span><button id="theButton" class="btn btn-secondary fl f12 mt3" style="padding: 0 3px 0 3px !important; vertical-align: text-bottom !important;"><i class="material-icons-outlined f12">edit</i></button><span id="theValue" style="padding-left: 4px;"></span></span>';
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = template;
      this.eGui = tempDiv.firstElementChild;
    };

    CellRenderer.prototype.init = function(params) {
      this.createGui();
      this.params = params;
      const eValue = this.eGui.querySelector('#theValue');
      eValue.innerHTML = params.value;
      this.eButton = this.eGui.querySelector('#theButton');
      this.buttonClickListener = this.onButtonClicked.bind(this);
      this.eButton.addEventListener('click', this.buttonClickListener);
    };

    CellRenderer.prototype.onButtonClicked = function() {
      const startEditingParams = {
        rowIndex: this.params.rowIndex,
        colKey: this.params.column.getId(),
      };

      this.params.api.startEditingCell(startEditingParams);
    };

    CellRenderer.prototype.getGui = function() {
      return this.eGui;
    };

    CellRenderer.prototype.destroy = function() {
      this.eButton.removeEventListener('click', this.buttonClickListener);
    };

    return CellRenderer;
  }

  /*******************************************************************************
    설  명 : 1000 단위 콤마
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public currencyFormatter(params: any) {
    if( params.value == '' || params.value == undefined ) return 0;
    else return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 숫자형으로 리턴
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public numberGetter(params: any) {
    const value = params.data[ params.column.colId ];
    return ( value ) ? parseInt( value ) : value;
  }

}
