/*******************************************************************************
  설  명 : 재고현황조회
  작성일 : 2024.07.14
  작성자 : 권세창
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions , NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';

import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ScmStockService } from '@app/service/scm.stock.service'
import { DashboardComponent } from '@page/dashboard/dashboard.component';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {
/*******************************************************************************
    전역 선언부
  *******************************************************************************/

  
    productList = []
    categoryList = []
    brandList = []
  
    // 그리드 관련 선언
    gridApi: any;
    gridColumnApi: any;
    columnDefs: any;
  
    defaultColDef: any;
    domLayout: any;
    rowSelection: any;
    paginationPageSize: any = 100;
  
    noRowsTemplate: string;
  
    // 그리드 이미지 처리
    frameworkComponents = {
      agGridImageComponent: AgGridImageComponent,
      agGridHtmlComponent: AgGridHtmlComponent
    };
  
    search_protoType: any = {
      product_seq: '',
      pageNo: 1,
      pageRow: 20,
      totalCount: 0,
      searchText: '',
      category_code: '',
      category_seq: '',
      brand_seq: '',

    }
  
    search = { ...this.search_protoType }
  
    currencyFormatter = (params: any) => Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    numberGetter = (params: any) => {
      const value = params.data[ params.column.colId ]
      return ( value ) ? parseInt( value ) : value
    }
  
    /*******************************************************************************
      설  명 : 생성자
    *******************************************************************************/
    constructor(
      public authService: AuthService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private utilService: UtilService,
      private categoryService: CategoryService,
      private brandService: BrandService,
      private toastrService: ToastrService,
      private scmStockService: ScmStockService,
      private dashboardComponent: DashboardComponent,
    ) {
      // ag grid 컬럼 선언
      this.columnDefs = [
        this.dashboardComponent.checkboxColumn,
        { headerName: '상품번호/옵션번호', field: 'seq', width: 200, cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
          valueGetter(params: any) {
            let productSeq = params.data.product_seq;
            let propertySeq = params.data.property_seq;
  
            return productSeq + ( propertySeq !== undefined ? '<br/>' + propertySeq : '' );
          }
        },
        { headerName: '품절여부', field: 'status', width: 120, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
          valueGetter(params: any) {
            let childStatus: any = params.data.status;
            let childBadge: any;
  
            if ( childStatus == '0' ) childBadge = '<span class="badge badge-success f12 mt5">판매중</span>';
            else if ( childStatus == '1' ) childBadge = '<span class="badge badge-warning f12 mt5">일시품절</span>';
            else if ( childStatus == '2' ) childBadge = '<span class="badge badge-danger f12 mt5">영구품절</span>';
            else childBadge = '';
  
            return ( childStatus !== undefined ? childBadge : '' );
          }
        },
        { headerName: '이미지', field: 'thumbnail_url', width: 150, cellClass: 'cp center ag-cell100h', cellRenderer: 'agGridImageComponent' },
        { headerName: '카테고리', field: 'category_name', width: 200, cellClass: 'ag-cell80h-br cell-wrap-text' },

        { headerName: '브랜드/상품명', field: '', width: 350, cellClass: 'ag-cell80h-br cell-wrap-text', autoHeight: true, cellRenderer: 'agGridHtmlComponent',
          valueGetter(params: any) {
            let brand_name = ( params.data.brand_name ) ? '[ ' + params.data.brand_name + ' ]<br/>' : '';
            let product_name = ( params.data.product_name ) ? params.data.product_name : '';
  
            return brand_name + product_name;
          }
        },
        { headerName: '색상/사이즈', field: '', width: 193, cellClass: 'left ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
          valueGetter(params: any) {
            let display_color_name = ( params.data.display_color_name ) ? '<span class="fb">' + params.data.display_color_name + '</span>' : '';
            let display_size_name = ( params.data.display_size_name ) ? '<span class="fb">' + params.data.display_size_name + '</span>' : '';
            return display_color_name + ' <br/> ' + display_size_name;
          }
        },
        { headerName: '재고유무', field: 'stock_status', width: 100, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let childStatus: any = params.data.stock_status;
          let childBadge: any;

          if ( childStatus == '재고있음' ) childBadge = '<span class="badge badge-success f12 mt5">재고있음</span>';
          else  childBadge = '<span class="badge badge-warning f12 mt5">재고없음</span>';

          return ( childStatus !== undefined ? childBadge : '' );
        }   },
      ]
  
      this.defaultColDef = {
        sortable: true,
        filter: false,
        resizable: true,
        autoHeight: true,
      }
      
      this.rowSelection = 'multiple'
      this.noRowsTemplate = this.dashboardComponent.noRowsTemplate
    }
  
    /*******************************************************************************
      설  명 : 그리드 높이 설정
    *******************************************************************************/
    getRowHeight = () => 80
  
    /*******************************************************************************
      설  명 : 데이터 로딩
    *******************************************************************************/
    ngOnInit(): void {
      // 검색 카테고리 리스트가져오기
      this.getCategoryList();
      // 검색 브랜드 리스트 가져오기 
      this.getBrandList();

      this.activatedRoute.queryParams.subscribe( async params => {
        this.search = {
          pageNo: ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo,
          pageRow: ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow,
          totalCount: ( typeof params.totalCount == 'undefined' ) ? '0' : params.totalCount,
          searchText : params.searchText,
          category_code : params.category_code,
          brand_seq : params.brand_seq,
          brand_name : '',
        }
        // 화면 오픈 시 자동 검색은 하지 않는다. 
        // 상품 데이터 불러오기
//        this.getProductStock();
      })
      // this.toastrService.info('상품을 클릭하면 상세정보로 이동합니다.')
    }
  
  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
  *******************************************************************************/
    getCategoryList() {
      this.categoryService.getCategoryList2().then( response => {
        if( response.ResultCode ) {
          // this.category_list = response.data.map(e => ({
          //   seq : e['seq'],
          //   category_code : e['category_code'],
          //   category_nm : e['category_nm']
          // }));
          let tmp: any = [];
          tmp.push({id: '0', text: '카테고리 전체', code: ''});
  
          response.data.forEach( row => {
            tmp.push({
              id: row.seq,
              text: row.category_nm,
              code: row.category_code
            });
          });
  
          this.categoryList = tmp;
        } else {
          this.categoryList = []
        }
      });
    }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getBrandList() {
      this.brandService.getBrandListForselectBox().then( response => {
        if( response.ResultCode ) {
          // this.brandList = response.data;
          let tmp: any = [];
          tmp.push({id: '0', text: '브랜드 전체'});
  
          response.data.forEach( row => {
            tmp.push({
              id: row.seq,
              text: row.brand_name
            });
          });
  
          this.brandList = tmp;
        } else {
          this.brandList = [];
        }
      });
    }    
  

    
    /*******************************************************************************
      설  명 : 상품 재고 리스트 가져오기
    *******************************************************************************/
    getProductStock() {

      if (this.search.searchText == '' || this.search.searchText == null || this.search.searchText == undefined) 
      {
        this.toastrService.error('상품명 또는 상품번호를 반드시 입력하여야합니다.');
        return;  
      } 

      this.scmStockService.getProductStockList({
        ...this.search,
        mem_no : this.dashboardComponent.getUser()
      }).then(response => {
        if(response.ResultCode) {
          this.productList = response.data
          this.search.totalCount = parseInt(response.count) || 0;
        } else this.toastrService.error(response.ResultMessage)
      })
    }

    /*******************************************************************************
      설  명 : 검색 필터 버튼
    *******************************************************************************/
    setSearch(key, value) {
      this.search[key] = value;
   //   this.getProductStock();
    }
  
    /*******************************************************************************
      설  명 : ag grid ready 시 처리
    *******************************************************************************/
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
  

    
  
    /*******************************************************************************
      설  명 : 검색 버튼에서 클릭 시 처리
      입력값 : key = this.params 키 값, value = 적용 값
      리턴값 : 없음
    *******************************************************************************/
    searchParams( key, value ) {
      this.search[key] = value;
      this.getProductStock();
    }
    
    /*******************************************************************************
      설  명 : 검색 초기화
    *******************************************************************************/
    searchInit() {
      this.search = { ...this.search_protoType }
//      this.getProductStock()
    }
  
    /*******************************************************************************
      설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    *******************************************************************************/
    searchList( $event ) {
      if($event.key == 'Enter') this.getProductStock()
    }
  
    /*******************************************************************************
      설  명 : 카테고리 선택 입력
    *******************************************************************************/
    setCategory($event) {
      this.setSearch('category_code', $event.target.value)
    }
    /*******************************************************************************
    설  명 : 카테고리 선택
    *******************************************************************************/
    selectCategory(event: any) {
      ( event.id != '0' ) ? this.search.category_seq = event.id : '';
      ( event.id != '0' ) ? this.search.category_code = event.code : '';
      if (this.search.category_seq != '') {
          this.getProductStock();
      }    
    }

    /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    *******************************************************************************/
   selectBrand( event: any ) {

     ( event.id != '0' ) ? this.search.brand_seq = event.id : '';
/*
     if (this.search.brand_seq != '') {
      this.getProductStock();

     }
   */  
    }
  
    /*******************************************************************************
      설  명 : 셀 리사이즈 처리
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    onColumnResized(params) {
      setTimeout(() => {
        params.api.resetRowHeights();
      }, 0);
    }
  
    onColumnVisible(params) {
      setTimeout(() => {
        params.api.resetRowHeights();
      }, 0);
    }
    
    
    /*******************************************************************************
      설  명 : 오늘 선택 시 처리
    *******************************************************************************/
    getToday( obj: NgbInputDatepicker, check: boolean ) {
      if( check ) {
        this.search.s_date = this.utilService.getDate( '' );
        obj.close();
      } else {
        this.search.e_date = this.utilService.getDate( '' );
        obj.close();
      }
    }
 
    /*******************************************************************************
      설  명 : 페이지 선택
    *******************************************************************************/
    loadPage(event: any) {
      this.search.pageNo = event;

      if (this.search.brand_seq != undefined || this.search.category_seq != undefined ) {
        this.getProductStock() ; 
      }
    }  
  }
  