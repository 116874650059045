import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { OrderService } from '@app/service/order.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-payment-add',
  templateUrl: './order-payment-add.component.html',
  styleUrls: ['./order-payment-add.component.scss']
})
export class OrderPaymentAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public orderSeq: any;
  public item: any;
  public pay_amt: any;

  private dateModel: NgbDateStruct;
  private date: NgbDateStruct = this.utilService.getDate('');

  public pgGroupList: any = [];
  public payMethodList: any = [];
  public paymentGatewayCodeList: any = [];
  public cardCodeList: any = [];

  public title: any = '추가';

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      orderSeq: ['', [Validators.required] ],
      seq: ['', [] ],
      pg_group: ['', [] ],
      pg_group_name: ['', [] ],
      pg_group_ename: ['', [] ],
      pay_method: ['', [Validators.required] ],
      pay_method_name: ['', [] ],
      pay_method_ename: ['', [] ],
      app_gbn: ['0', [Validators.required] ],
      pay_date: [this.date , [Validators.required] ],
      pay_amt: [ '', [Validators.required] ],
      mid: [ '', [] ],
      app_no: [ '', [] ],
      app_date: [ '', [] ],
      app_time: [ '', [] ],
      oid: [ '', [] ],
      tid: [ '', [] ],
      card_no: [ '', [] ],
      installment_period: [ '', [] ],
      card_code1: [ '', [] ],
      card_name1: [ '', [] ],
      card_code2: [ '', [] ],
      card_name2: [ '', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private orderService: OrderService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      orderSeq: this.orderSeq,
    });

    if( this.item !== null ) {
      this.title = '수정';

      // 결제일자
      if( this.item.pay_date != '' && this.item.pay_date != null ) {
        if( typeof this.item.pay_date != 'object' ) {
          this.dateModel = {
            year: parseInt( this.item.pay_date.substr( 0, 4 ) ),
            month: parseInt( this.item.pay_date.substr( 5, 2 ) ),
            day: parseInt( this.item.pay_date.substr( 8, 2 ) )
          };

          this.item.pay_date = this.dateModel;
        }
      } else {
        this.item.pay_date = null;
      }

      this.form.patchValue( this.item );
    }

    if( typeof this.pay_amt !== 'undefined' ) {
      this.form.patchValue({
        pay_amt: this.pay_amt
      });
    }

    // 공통코드 가져오기
    this.getCommonList();

    // PG결제 코드 가져오기
    this.getPaymentGatewayCodeList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // PG 그룹사
    this.commonService.getCommonListCode('PGG').then( response => {
      if ( response.ResultCode ) {

        this.pgGroupList.push({value: '', title: 'PG그룹사를 선택하세요'});

        response.data.forEach( row => {
          this.pgGroupList.push({
            value: row.common_code,
            title: row.common_name + ( row.common_ename ? '(' + row.common_ename + ')' : ''),
            etitle: row.common_ename
          });
        });

      } else {
        this.pgGroupList = [];
      }
    });

    // 결제수단
    this.commonService.getCommonListCode('PMD').then( response => {
      if ( response.ResultCode ) {

        this.payMethodList.push({value: '', title: '결제수단을 선택하세요'});

        response.data.forEach( row => {
          this.payMethodList.push({
            value: row.common_code,
            title: row.common_name,
            etitle: row.common_ename
          });
        });

      } else {
        this.payMethodList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : PG결제 코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPaymentGatewayCodeList() {
    this.orderService.getPaymentGatewayCodeList().then( response => {
      if ( response.ResultCode ) {
        this.paymentGatewayCodeList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 결제수단 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changePayMethod() {
    const payMethod = this.payMethodList.filter(item => item.value === this.form.controls.pay_method.value);

    if( payMethod[0].value ) {
      this.form.patchValue({
        pay_method_name: payMethod[0].title,
        pay_method_ename: payMethod[0].etitle
      });

      this.changePaymentGatewayCodeList();
    }
  }
  
  /*******************************************************************************
    설  명 : PG그룹사 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changePgGroup() {
    const pgGroup = this.pgGroupList.filter(item => item.value === this.form.controls.pg_group.value);

    if( pgGroup[0].value ) {
      this.form.patchValue({
        pg_group_name: pgGroup[0].title,
        pg_group_ename: pgGroup[0].etitle
      });

      this.changePaymentGatewayCodeList();
    }
  }
  
  /*******************************************************************************
    설  명 : 카드종류1 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeCardCode1() {
    const cardCode = this.cardCodeList.filter(item => item.value === this.form.controls.card_code1.value);

    this.form.patchValue({
      card_name1: cardCode[0].title
    });
  }
  
  /*******************************************************************************
    설  명 : 카드종류2 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeCardCode2() {
    const cardCode = this.pgGroupList.filter(item => item.value === this.form.controls.card_code2.value);

    this.form.patchValue({
      card_name2: cardCode[0].title
    });
  }
    
  /*******************************************************************************
    설  명 : 선택한 PG사에 맞는 코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changePaymentGatewayCodeList() {
    this.cardCodeList = [];

    if( this.form.controls.pay_method_ename.value && this.form.controls.pg_group_ename.value ) {
      this.cardCodeList.push({value: '', title: '카드종류를 선택하세요'});

      this.paymentGatewayCodeList.forEach(item => {
        if( item.group == this.form.controls.pg_group_ename.value && item.method == this.form.controls.pay_method_ename.value ) {
          this.cardCodeList.push({
            value: item.code,
            title: item.name
          });          
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 결제정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderPaySave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.orderService.setOrderPaySave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

}
