/*******************************************************************************
  설  명 : 주문관리 - 주문관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateStruct, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { CommonService } from '@app/service/common.service';

import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import * as moment from 'moment';

import { OrderPaymentComponent } from '@page/order/order- payment/order-payment.component';
import { ScmOrderService } from '@app/service/scm.order.service'
import { DashboardComponent } from '@page/dashboard/dashboard.component'
import { ModalDeliveryComponent } from '@page/order/modal-delivery/modal-delivery.component'
import { ExportExcelComponent } from '@app/components/export-excel/export-excel.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  search_protoType = {
    mng_out_seq: '',
    index: 1,
    totalCount: 0,
    searchField: 'o_name',
    searchText: '',
    sdate: this.utilService.getDate(moment().subtract(90, 'day')),
    edate: this.utilService.getDate(''),
    searchTerm: this.getDays(90),
    status: '',
    size:25 ,
  }
  search = { ...this.search_protoType }

  widget = {
    all : 0,
    today : 0,
    not : 0,
    cancel : 0
  }

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
    {title: '150일', value: this.getDays(150)},
    {title: '200일', value: this.getDays(200)},
    {title: '365일', value: this.getDays(365)},
  ];

  public orderList: any = [];
  orderDetailList = []

  // 그리드 관련 선언
  gridApi: any
  gridColumnApi: any
  columnDefs: any

  defaultColDef: any
  domLayout: any
  rowSelection: any
  paginationPageSize: any = 100
  noRowsTemplate: string
  rowClassRules: any
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private orderService: OrderService,
    private agGridExComponent: AgGridExComponent,
    private scmOrderService: ScmOrderService,
    private dashboardComponent: DashboardComponent,
    private exportExcelComponent: ExportExcelComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      this.dashboardComponent.checkboxColumn,
      { headerName: '주문번호', field: 'order_seq', width: 80, cellClass: 'center' },
      { headerName: '', field: 'btnDetail', width: 90, cellStyle: {'text-overflow': 'clip'},
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'orderDetail',
          btnName: '상세정보'
        }
      },
      { headerName: '주문자', field: 'o_name', width: 200, flex: 1 },
      { headerName: '수신자', field: 'r_name', width: 200, flex: 1 },
      { headerName: '출고지시번호', field: 'mng_out_seq', width: 100, cellClass: 'center' },
      { headerName: '출고지시일', field: 'out_order_date', width: 100, cellClass: 'center' },
      { headerName: '출고상태', field: 'status_name', width: 100, cellClass: 'center', cellRenderer: 'agGridHtmlComponent',
        valueGetter : (params) => `<span class="badge badge-${params.data.status == '2000' ? 'success': 'secondary'} f12">${params.data.status_name}</span>`
      },
      { headerName: '배송일', field: 'out_date', width: 150, cellClass: 'center', cellRenderer: 'agGrid' },
      { headerName: '택배사', field: 'delivery_company', width: 150, cellClass: 'center' },
      { headerName: '송장번호', field: 'delivery_no', width: 150 },
      { headerName: '', field: '', width: 90, cellStyle: {'text-overflow': 'clip'},
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'orderDetail',
          btnName: '출고처리',
          listaction: $event => {
            if($event.data.delivery_no) if(!confirm('출고처리한 주문입니다. \r\n배송정보를 변경하시겠습니까?')) return
            this.setDelivery($event.data['mng_out_seq'], $event.data['mng_out_delivery_seq'], $event.data['order_seq'])
          }
        } 
      },
      { headerName: '', field: '', width: 90, cellStyle: {'text-overflow': 'clip'},
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'orderDetail',
          btnName: '출고취소',
          listaction: $event => {
            if(!$event.data.delivery_no && this.toastrService.warning('출고상태가 출고가 아닙니다.')) return
            else if(!confirm('출고처리를 취소하시겠습니까?')) return
            this.setCancelDelivery($event.data['mng_out_seq'], $event.data['mng_out_delivery_seq'], $event.data['order_seq'])
          }
        } 
      },

    ]

    this.defaultColDef = this.dashboardComponent.defaultColDef
    this.rowSelection = 'multiple' //this.dashboardComponent.rowSelection
    this.noRowsTemplate = this.dashboardComponent.noRowsTemplate

    this.rowClassRules = {
      'status1': function (params) {
        return params.data.order_status == '1';
      },
      'status2': function (params) {
        return params.data.order_status == '2';
      },
      'status3': function (params) {
        return params.data.order_status == '3' || params.data.order_status == '5' || params.data.order_status == '7';
      },
      'status4': function (params) {
        return params.data.order_status == '4' || params.data.order_status == '6';
      },
      'status8': function (params) {
        return params.data.order_status == '8' || params.data.order_status == '9';
      },
      'gridBorderBottom': function (params) {
        return params.data.order_status != null;
      }
    };

  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 리스트 가져오기
    // this.getCommonList();

    this.activatedRoute.queryParams.subscribe( async params => {
      this.search.index = ( typeof params.index == 'undefined' || params.index == '' ) ? '1' : params.index;
      this.search.totalCount = ( typeof params.totalCount == 'undefined' || params.totalCount == '' ) ? '' : params.totalCount;
      this.search.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? 'o_name' : params.searchField;
      this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.search.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? this.utilService.getDateStr(this.utilService.getDate(moment().subtract(90, 'day'))) : params.sdate;
      this.search.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? this.utilService.getDateStr(this.utilService.getDate('')) : params.edate;
      this.search.searchTerm = ( typeof params.searchTerm == 'undefined' ) ? this.getDays(90) : params.searchTerm;
      this.search.status = ( typeof params.status == 'undefined' ) ? '' : params.status;

      this.search.sdate = this.utilService.getDate(this.search.sdate);
      this.search.edate = this.utilService.getDate(this.search.edate);
      this.search.size = ( typeof params.size== 'undefined' || params.size == '' ) ? 25: params.size;
 
      this.getWidget();
      this.getOrderList();
    });
  }

  /*******************************************************************************
    설  명 : 주문 리스트 가져오기
  *******************************************************************************/
  getOrderList() {
    this.scmOrderService.getOrder({
      mem_no : this.dashboardComponent.getUser(),
      s_date : this.search.sdate,
      e_date : this.search.edate,
      index : this.search.index - 1,
      searchField: this.search.searchField,
      searchText: this.search.searchText,
      status: this.search.status , 
      size : this.search.size
    }).then( response => {
      if( response.ResultCode ) {
        this.orderList = response.data || []
        this.search.totalCount = response.count || 0
      } else this.toastrService.error(response.ResultMessage)
    })
  }
  
  /*******************************************************************************
    설  명 : 위젯 가져오기
  *******************************************************************************/
  getWidget() {
    this.scmOrderService.getWidget({
      mem_no : this.dashboardComponent.getUser()
    }).then( response => {
      if( response.ResultCode ) {
        this.widget = {
          all : response.data['all'],
          today : response.data['today'],
          not : response.data['not'],
          cancel : response.data['cancel'],
        }
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 출고처리
  *******************************************************************************/
  setDelivery(mng_out_seq, mng_out_delivery_seq, order_seq) {
    const modalRef = this.modalService.open(ModalDeliveryComponent, options)
    modalRef.componentInstance.mng_out_seq = mng_out_seq
    modalRef.componentInstance.mng_out_delivery_seq = mng_out_delivery_seq
    modalRef.componentInstance.order_master_seq = order_seq
    modalRef.result.then(result => {
      this.getOrderList()
    }, (reason) => {})
  }
  
  /*******************************************************************************
    설  명 : 출고취소
  *******************************************************************************/
  setCancelDelivery(mng_out_seq, mng_out_delivery_seq, order_seq) {
    this.scmOrderService.setCancelDelivery({
      mng_out_seq : mng_out_seq, 
      mng_out_delivery_seq : mng_out_delivery_seq, 
      order_master_seq : order_seq,
      mem_no : this.dashboardComponent.getUser()
      }).then(res => {
        if(res.ResultCode) {
          this.toastrService.success('출고취소하였습니다.')
          this.getOrderList()
        } else this.toastrService.error(res.ResultMessage)
      }).catch(e => this.toastrService.error(e))
  }

  /*******************************************************************************
    설  명 : 인덱스 처리
  *******************************************************************************/
  loadPage(event: any) {
    this.search.index = event;

    this.router.navigate(
      ['/order/list'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          mng_out_seq: this.search.mng_out_seq,
          index: this.search.index,
          searchField: this.search.searchField,
          searchText: this.search.searchText,
          sdate: this.utilService.getDateStr(this.search.sdate),
          edate: this.utilService.getDateStr(this.search.edate),
          searchTerm: this.search.searchTerm,
          status: this.search.status
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = { ...this.search_protoType }
    this.getOrderList()
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getOrderList();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field === 'btnDetail' ) {
      this.goDetail($event.data.mng_out_seq);
    }
  }
  
  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( seq: any ) {
    this.search.mng_out_seq = seq;

    this.search.sdate = this.utilService.getDateStr(this.search.sdate);
    this.search.edate = this.utilService.getDateStr(this.search.edate);

    this.router.navigate(
    ['/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // goDetail( seq: any ) {
  //   this.search.seq = seq;

  //   this.search.sdate = this.utilService.getDateStr(this.search.sdate);
  //   this.search.edate = this.utilService.getDateStr(this.search.edate);

  //   this.router.navigate(
  //   ['/order/detail'],
  //   {
  //     relativeTo: this.activatedRoute,
  //     queryParams: this.search,
  //     queryParamsHandling: '', // remove to replace all query params by provided
  //   });
  // }

  printExcel() {
    const getSelectedRows = this.gridApi.getSelectedRows()
    if(!getSelectedRows.length) return
    const getSelectedRowsDetailResult: Array<any> = []
    const getUser = this.dashboardComponent.getUser()
    const _this = this

    async function getSelectedRowsDetail() {
      for(let i = 0; i < getSelectedRows.length; i++) {
        await _this.scmOrderService.getOrderDetail({
          mem_no : getUser,
          mng_out_seq : getSelectedRows[i].mng_out_seq
        }).then(res => {
          if(res.ResultCode) {
            getSelectedRowsDetailResult.push(...res.data)
          } else _this.toastrService.error(res.ResultMessage)
        })
      }
    }

    getSelectedRowsDetail().then(() => {
      this.exportExcelComponent.exportAsExcelFile(
        getSelectedRowsDetailResult.map(e => ({
          '받는분성명': e.r_name,
          '받는분전화번호': e.r_hp,
          '받는분주소': `${e.r_zipcode} ${e.r_address} ${e.r_address_detail}`,
          '품목명': `${e.product_name} / ${e.display_color_name} / ${e.display_size_name}`,
          '수량': `${e.out_order_qty}`,
          '배송요청사항': e.r_comment
        })), 
        '선택한 주문의 배송정보 리스트',
        'sheet1',
        [100, 100, 380, 480, 50, 120]
        )

    })
  }

  printExcel1() {
    const getSelectedRows = this.gridApi.getSelectedRows()
    if(!getSelectedRows.length) return
    const getSelectedRowsDetailResult: Array<any> = []
    const getUser = this.dashboardComponent.getUser()
    const _this = this

    async function getSelectedRowsDetail() {
      for(let i = 0; i < getSelectedRows.length; i++) {
        await _this.scmOrderService.getOrderDetail({
          mem_no : getUser,
          mng_out_seq : getSelectedRows[i].mng_out_seq
        }).then(res => {
          if(res.ResultCode) {
            getSelectedRowsDetailResult.push(...res.data)
          } else _this.toastrService.error(res.ResultMessage)
        })
      }
    }

    getSelectedRowsDetail().then(() => {
      this.exportExcelComponent.exportAsExcelFile(
        getSelectedRowsDetailResult.map(e => ({
          '주문번호': e.order_seq,
          '주문자': e.o_name,
          '수신자': e.r_name,
          '출고지시일': e.out_order_date,
          '출고일': e.out_date_format,
          '상품': `${e.product_name} / ${e.display_color_name} / ${e.display_size_name}`,
          '수량': `${e.out_order_qty}`,
          '공급가': `${e.buy_price}`,
          '금액': `${e.total_buyPrice}`,
        })), 
        '선택한 주문의 배송정보 리스트',
        'sheet1',
        [80, 100, 100, 100, 100, 350 , 80 , 80, 80]
        )

    })
  }


  // 출고지시 분할
  divideRelease(data) {
    if(data.status != 1000 && this.toastrService.warning('출고지시 상태에서만 가능합니다.')) return

    this.scmOrderService.getOrderDetail({
      mem_no : this.dashboardComponent.getUser(),
      mng_out_seq : data.mng_out_seq
    }).then(res => {
      if(res.ResultCode) {
        this.orderDetailList = res.data.map(item => ({
          ...item,
          divide_qty: 0
        }))
        document.getElementById('orderDetailList').style.transform = "scale(1)"
      } else this.toastrService.error(res.ResultMessage)
    }).catch(e => this.toastrService.error(e))
  }

  putOrderDetailListDivideQty($event, index) {
    const value = parseInt($event.target.value)
    if(!value) this.toastrService.error('유효한 입력이 아닙니다.')
    this.orderDetailList[index].divide_qty = value || 0
  }

  divideSubmit() {
    const data = this.orderDetailList.filter(item => item.divide_qty)
    if(!data.length) {
      this.toastrService.warning('분할 수량을 입력하십시오.')
      return
    } else if( this.orderDetailList.reduce((a,c) => a + c.out_order_qty, 0) == this.orderDetailList.reduce((a,c) => a + c.divide_qty, 0) ) {
      this.toastrService.warning('남는 수량이 존재해야 합니다.')
      return
    }
    for(let i = 0; i < data.length; i++) 
    if(data[i].divide_qty > data[i].out_order_qty - data[i].out_qty) {
      this.toastrService.warning(`분할 수량은 남은 수량을 초과할 수 없습니다.`, `LINE ${i+1}`)
      return
    }

    this.scmOrderService.postDivideRelease({
      mem_no : this.dashboardComponent.getUser(),
      divide_list : data
    }).then(res => {
      if(res.ResultCode) {
        this.toastrService.success('성공')
        this.divideCancel()
        this.getOrderList()
      } else this.toastrService.error(res.ResultMessage)
    }).catch(e => this.toastrService.error(e))
  }

  divideCancel() {
    document.getElementById('orderDetailList').style.transform = "scale(0)"
  }

  mergeRelease() {
    const message = [
      '적어도 두개 이상의 데이터를 선택하십시오.',
      '주문번호가 서로 다르므로 병합할 수 없습니다.',
      '출고지시 상태가 아니므로 병합할 수 없습니다.',
      '가장 낮은 출고지시번호를 기준으로 병합합니다. \r\n실행하시겠습니까?'
    ]
    const rows = this.gridApi.getSelectedRows()
    if(2 > rows.length && this.toastrService.warning(message[0])) return
    else if([...new Set(rows.map(item => item.order_seq))].length > 1 && this.toastrService.warning(message[1])) return
    else if(rows[0].status != 1000 && this.toastrService.warning(message[2])) return
    else if(!confirm(message[3])) return
    
    this.scmOrderService.getOrderDetail({
      mem_no : this.dashboardComponent.getUser(),
      mng_out_seq : rows.map(item => item.mng_out_seq)
    }).then(res => {
      if(res.ResultCode) {
        const mergeKey = 'order_detail_seq'
        const mergeValue = 'out_order_qty'
        res.data.forEach((e,i,a) => {
          const _i = a.findIndex(_e => _e[mergeKey] == e[mergeKey])
          if(_i != i) {
            a[_i][mergeValue] = parseInt( a[_i][mergeValue] ) + parseInt( a[i][mergeValue] )
            a[i][mergeValue] = 0
          }
        })
        const newReleaseData = res.data.filter(item => item[mergeValue])
        const oldReleaseData = rows.sort((a,b) => a.mng_out_seq - b.mng_out_seq)
        const mergeMng = oldReleaseData.shift()
        const mergeMngSeq = mergeMng.mng_out_seq
        // const mergeMngChanger = mergeMng.mng_out_changer
        // const mergeMngChangeDate = mergeMng.mng_out_change_date

        this.scmOrderService.postMergeRelease({
          mem_no : this.dashboardComponent.getUser(),
          newReleaseData : newReleaseData,
          oldReleaseData : oldReleaseData,
          mergeMngSeq : mergeMngSeq
        }).then(res => {
          if(res.ResultCode) {
            this.toastrService.success('성공')
            this.getOrderList()
          } else this.toastrService.error(res.ResultMessage)
        }).catch(e => this.toastrService.error(e))

      } else this.toastrService.error(res.ResultMessage)
    }).catch(e => this.toastrService.error(e))

  }

}
