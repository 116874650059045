<section id="member-company">

  <div class="mb5">
    <div class="fl form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
        <i class="material-icons-outlined">search</i>
      </button>

	    <input type="text" size="15" readonly="readonly" id="sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
	    <ng-template #footerTemplate1>
	      <hr class="my-0">
	      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
	      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
	    </ng-template>
	    <span class="ml5 mr5">~</span>
	    <input type="text" size="15" readonly="readonly" id="edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
	    <ng-template #footerTemplate2>
	      <hr class="my-0">
	      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
	      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
	    </ng-template>  

      <div class="btn-group ml10">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.state==''}" (click)="search.state='';">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.state=='1'}" (click)="search.state='1';">취소</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.state=='2'}" (click)="search.state='2';">반품</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.state=='3'}" (click)="search.state='3';">교환</button>
      </div>
      <div class="btn-group ml10">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.state1==''}" (click)="search.state1='';">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.state1=='1'}" (click)="search.state1='1';">요청</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.state1=='2'}" (click)="search.state1='2';">승인</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.state1=='3'}" (click)="search.state1='3';">미승인</button>
      </div>
    </div>
    <div class="fr right">
      <button type="button" class="btn btn-primary btn-small">선택승인</button>
      <button type="button" class="btn btn-danger btn-small ml5">선택미승인</button>
    </div>    
    <div class="cb"></div>
  </div>

  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 130px);background-color:#fff;">
    <as-split-area size="60" minSize="40" maxSize="80">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="orderList"
        [singleClickEdit]="true"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        >
      </ag-grid-angular>
    </as-split-area>

  </as-split>
</section>

