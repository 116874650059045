import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ScmOrderService {

  constructor(
    private restful: RestfulService,
  ) {
  }

  
  // 주문 위젯 get
  getWidget( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'getWidget'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 주문 리스트 get
  getOrder( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'getOrder'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 택배사 리스트 get
  getDeliveryCompany(): Promise<any> {
    return this.restful.get({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'getDeliveryCompany'
    }, {}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 배송처리
  setDelivery( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'setDelivery'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 배송취소
  setCancelDelivery( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'setCancelDelivery'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 주문상세정보 가져오기
  getOrderDetail( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'getOrderDetail'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 주문 메모 가져오기
  getMemo( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'getMemo'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 주문 메모 작성하기
  setMemo( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'setMemo'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  postDivideRelease( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'postDivideRelease'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  postMergeRelease( params ): Promise<any> {
    return this.restful.post({
      program: 'scm',
      service: 'order',
      version: '1.0',
      action: 'postMergeRelease'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }



}
