/*******************************************************************************
  설  명 : 상품마진 관리
  작성일 : 2020-09-24
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';

import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { ProductSellGraphComponent } from '@page/product/product-sell-graph/product-sell-graph.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-margin',
  templateUrl: './product-margin.component.html',
  styleUrls: ['./product-margin.component.scss']
})
export class ProductMarginComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 1000,
    listType: 'margin',
    searchText: '',
    category_code: '',
    brand_seq: '',
    soldout: '',
    display: ''
  };

  public statistic: any = {
    productCnt: 0,
    searchCnt: 0,
    waitCnt: 0,
    completeCnt: 0,
  }

  public categoryList: any = [];
  public brandList: any = [];
  public marginList: any = [];

  public totalCount: number = 0;

  public components: any;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilService: UtilService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '이미지', field: 'thumbnail_url', width: 150, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'product_name', width: 250, cellClass: 'cp ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.category_name + '<br />[' + params.data.maker + ']<br />' + params.data.product_name;
        }
      },
      { headerName: '공급자', field: 'provider_name', cellClass: 'cp center ag-cell80h', width: 120 },
      { headerName: '마진율', field: 'margin_rate', cellClass: 'cp center ag-cell80h', width: 70, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.margin_rate < 10 ) {
            return `<span style="color:red;">${params.data.margin_rate} %</span>`;
          } else {
            return `${params.data.margin_rate} %`;
          }
        }
      },
      { headerName: '표시가격', field: 'display_price', width: 100, cellClass: 'cp right ag-cell80h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '판매가격', field: 'sale_price', width: 100, cellClass: 'cp right ag-cell80h', editable: true, cellRenderer: 'agGridHtmlComponent',
        alueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '슈퍼가격', field: 'supersale_price', width: 100, cellClass: 'cp right ag-cell80h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '역입점가격', field: 'seller_price', width: 100, cellClass: 'cp right ag-cell80h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '상사가격', field: 'com_price', width: 100, cellClass: 'cp right ag-cell80h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: 'B2B가격', field: 'b2b_price', width: 100, cellClass: 'cp right ag-cell80h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '구매가격', field: 'buy_price', width: 100, cellClass: 'cp right ag-cell80h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '마일리지', field: 'mileage', width: 100, cellClass: 'cp right ag-cell80h', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '판매현황보기', field: '', width: 100, cellClass: 'cp center ag-cell80h',
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          label: '보기',
          btnClass: 'btn btn-default btn-small',
          clicked: this.onProductSellGraphView.bind(this),
        },
      },
      { headerName: '처리', field: '', width: 70, cellClass: 'cp center ag-cell80h',
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          label: '수정',
          btnClass: 'btn btn-default btn-small',
          clicked: this.setProductMarginSave.bind(this),
        },
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor(),
      btnCellRenderer: this.agGridExComponent.getBtnCellRenderer()
    };

    function currencyFormatter(params: any) {
      const str = String(params.data[ params.column.colId ]);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCategoryList();
    this.getBrandList();

    // 상품마진 리스트 가져오기
    this.getProductMarginList();
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      // console.log(response);
      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      // console.log(response);
      this.brandList = [];
      if( response.ResultCode ) {
        // this.brandList = response.data;

        this.brandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.brandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.brandList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품마진 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductMarginList() {
    this.productService.getProductMarginList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.marginList = response.data;
        this.statistic = response.statistic;
        this.totalCount = this.statistic.productCnt;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품마진 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductMarginSave( data: any ) {
    this.productService.setProductMarginSave( data ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onBrandChanged( event: any ) {
    // console.log(event);
  }

  /*******************************************************************************
    설  명 : 각 행의 수정버튼 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowBtnClicked( event: any ) {
    // console.log(event);
  }

  /*******************************************************************************
    설  명 : 리스트 타입 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setListTypeChange( value: any ) {
    this.search.listType = value;

    this.getProductMarginList();
  }

  /*******************************************************************************
    설  명 : 판매현황보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onProductSellGraphView( data: any ) {
    const modalRef = this.modalService.open(ProductSellGraphComponent, options);

    modalRef.componentInstance.productSeq = data.seq;
    modalRef.componentInstance.productName = data.product_name;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 1000,
      listType: 'margin',
      searchText: '',
      category_code: '',
      brand_seq: {id: '0'},
      soldout: '',
      display: ''
    };

    this.getProductMarginList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductMarginList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getProductMarginList();
  }

}
