import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SystemGroupService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 조직도 리스트 가져오기
  getGroupList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'group',
        version: '1.0',
        action: 'getGroupList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 조직도 리스트 가져오기
  getGroupListN(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'group',
        version: '1.0',
        action: 'getGroupListN'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사원포함한 조직도 가져오기
  getGroupEmployeeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'employee',
        version: '1.0',
        action: 'getGroupEmployeeList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 조직도 정보 저장하기
  setGroup( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'group',
        version: '1.0',
        action: 'setGroup'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 카테고리 정보 저장하기
  updateGroup( jsonData ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'group',
        version: '1.0',
        action: 'updateGroup'
      }, {
        data: jsonData
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 카테고리 정보 삭제하기
  deleteGroup( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'group',
        version: '1.0',
        action: 'deleteGroup'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 노드 순서 저장하기
  setNodeSort( node ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'group',
        version: '1.0',
        action: 'setNodeSort'
      }, {
        node: node
      }
    ).then(this.restful.extractData)
      .catch( err => {
      console.log( err );
      //this.restful.handleErrorPromise
    });
  }
}
