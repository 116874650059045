import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FavoriteMenuService } from '@app/service/favorite-menu.service';
import { CustomValidator } from '@app/service/custom.validators';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from '@app/service/api-response';

@Component({
  selector: 'app-modal-favorite-menu-group',
  templateUrl: './modal-favorite-menu-group.component.html',
  styleUrls: ['./modal-favorite-menu-group.component.scss']
})
export class ModalFavoriteMenuGroupComponent implements OnInit {

  public menuData: any = [];
  public form: FormGroup;
  public formErrors = {};
  public selectedSeq = '';
  private errorResponse: ApiResponse;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private favoriteMenuService: FavoriteMenuService,
    private toastrService: ToastrService,
  ) {
    this.form = this.formBuilder.group({
      seq: ['', []],
      name: ['', [Validators.required, Validators.maxLength(50)]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  }

  ngOnInit(): void {
  }

  selectMenu($event) {
    this.selectedSeq = $event.target.value;
    const findMenu = this.menuData.find( el => el.seq === $event.target.value);
    const menuName = typeof findMenu === 'undefined' ? '' : findMenu.name;
    this.form.patchValue({name: menuName});
  }

  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.favoriteMenuService.setFavoriteMenuGroup(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success('정상적으로 등록되었습니다.', '');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      })
      .catch(response => {
        this.toastrService.error('등록에 실패하였습니다.', '');
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    }
  }

  delete() {
    if ( confirm('선택하신 그룹메뉴를 삭제하시겠습니까? 그룹을 삭제하면 등록하신 하위 메뉴들도 삭제됩니다.') ) {
      this.favoriteMenuService.delFavoriteMenuGroup( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
}
