  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{widget?.all | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">금일결제<span class="cnt">{{widget?.today | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">미배송<span class="cnt">{{widget?.not | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">취소<span class="cnt">{{widget?.cancel | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <hr class="db mt5 mb5" />

  <section>
  <div class="fr right">
    <button type="button" class="btn btn-primary btn-small mr5" (click)="mergeRelease()">출고지시병합</button>
    <!-- <ag-grid-excel [gridApi]="gridApi" selected="true" filename="주문리스트" title="주문리스트"></ag-grid-excel> -->
    <button type="button" class="btn btn-default btn-small mr5" (click)="printExcel()">선택한 주문의 배송정보 엑셀다운</button>
    <!-- <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="1" [btnGroup]="1" class="ml5"></btn-ag-grid-save> -->
    <button type="button" class="btn btn-default btn-small" (click)="printExcel1()">선택한 주문의 상세정보 엑셀다운</button>
  </div>
  </section>

  <section>
  <div class="fl form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>

    <select name="pageRow" style="height:30px !important" [(ngModel)]="search.size" class="form-control ml5" (change)="getOrderList()">
      <option value="25">25줄</option>
      <option value="500">50줄</option>
      <option value="100">100줄</option>
      <option value="200">200줄</option>
      <option value="500">500줄</option>
    </select>    

    <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
    <ng-template #footerTemplate1>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
    </ng-template>
    <span class="ml5 mr5">~</span>
    <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
    <ng-template #footerTemplate2>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
    </ng-template>

    <div class="btn-group ml5">
      <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value);getOrderList();">{{item.title}}</button>
    </div>

    <div class="btn-group ml10">
      <button type="button" class="btn btn-info btn-small" [ngClass]="{active:search.status==''}" (click)="search.status='';getOrderList();">전체</button>
      <button type="button" class="btn btn-info btn-small" [ngClass]="{active:search.status=='1000'}" (click)="search.status='1000';getOrderList();">출고지시</button>
      <button type="button" class="btn btn-info btn-small" [ngClass]="{active:search.status=='2000'}" (click)="search.status='2000';getOrderList();">출고완료</button>
    </div>

    <select id="searchField" [(ngModel)]="search.searchField" class="form-control form-control-small ml10">
      <option value="o_name">주문자</option>
      <option value="r_name">수신자</option>
      <option value="mng_out_seq">출고지시번호</option>
      <option value="order_seq">주문번호</option>
      <option value="delivery_no">송장번호</option>
      <option value="status">출고상태</option>
    </select>
    <input type="text" id="searchText" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="30" class="form-control form-control-small ml5" placeholder="검색어" />

    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getOrderList()">
      <i class="material-icons-outlined">search</i>
    </button>
  </div>
  </section>

  <div class="cb"></div>

  <div class="mt5" style="height: calc(100% - 150px); position: relative;">
    <ag-grid-angular
      #myGrid

      style="width: 100%; height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="orderList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowClassRules]="rowClassRules"
      [pagination]="false"
      [paginationPageSize]="paginationPageSize"

      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
    <section class="pagination mt10">
      <ngb-pagination
        *ngIf="search.totalCount"
        [(page)]="search.index"
        [pageSize]="25"
        [collectionSize]="search.totalCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
    <div id="orderDetailList">
      <article>
      <ul>
        <li>이미지</li>
        <li>브랜드</li>
        <li>상품명</li>
        <li>색상</li>
        <li>사이즈</li>
        <li>수량</li>
        <li>분할수량</li>
      </ul>
      <ul *ngFor="let item of orderDetailList; index as index">
        <li><img src="{{item.thumbnail_url}}"></li>
        <li>{{item.brand_name}}</li>
        <li>{{item.product_name}}</li>
        <li>{{item.color_name}} - {{item.display_color_name}}</li>
        <li>{{item.size_name}} - {{item.display_size_name}}</li>
        <li>{{item.out_order_qty}}</li>
        <li><input type="text" [value]="item.divide_qty" (change)="putOrderDetailListDivideQty($event, index)"></li>
      </ul>
      </article>
      <div id="buttonGroup">
        <button type="button" class="btn btn-danger btn-small mr5" (click)="divideSubmit()">실행</button>
        <button type="button" class="btn btn-secondary btn-small" (click)="divideCancel()">취소</button>
      </div>
    </div>

  </div>