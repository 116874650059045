<section id="order-change">
  <div class="mt10">
    <div class="fl w30p">
      <div class="fb mt5 mb6">출고지시</div>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl" style="height:calc(100% - 35px); width:calc(15% - 10px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="outColumnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="rowData"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onOutGridReady($event)"
      (rowClicked)="onRowClicked($event)"
      >
    </ag-grid-angular>
  </div>

  <div class="fr" style="height:calc(100% - 35px); width:calc(85% - 10px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="rowData1"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [getRowHeight]="getRowHeight"

      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
