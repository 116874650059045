import { Injectable, NgModuleFactoryLoader } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';
import { UtilService } from '@app/service/util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public redirectURL: string = '';

  constructor(
    private router: Router,
    private globals: Globals,
    private member: MemberService,
    private toastrService: ToastrService,
    private readonly loader: NgModuleFactoryLoader,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.redirectURL = state.url;

    let result = await this.member.getMemberInfo( 'admin' ).then( response => {
      if( response['ResultCode'] == true ) {
        this.authService.login(response);
        return true;

      } else {
        this.toastrService.error(response['ResultMessage'], '회원로그인');
        this.authService.logout('A');
        this.router.navigate(
          ['/login'],
          {
            queryParams: {},
            queryParamsHandling: '', // remove to replace all query params by provided
          }
        );
        return false;
      }
    })
    .catch(response => {
      return false;
    });

    return result;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(next, state);
  }
}