<form [formGroup]="form" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-group-add">나만의 메뉴그룹</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="code" class="control-label">메뉴 그룹선택</label></th>
      <td>
        <select formControlName="seq" [ngClass]="{formErrors:formErrors['seq']}" class="form-control form-control-small" (change)="selectMenu($event)">
          <option value="">메뉴 그룹추가</option>
          <option *ngFor="let item of menuData" [value]="item.seq">{{item.name}}</option>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="name" class="control-label">메뉴 그룹명</label></th>
      <td>
        <inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>

  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="selectedSeq !== ''" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
    </div>
    <div class="fr w50p right ml0 mr0">
      <ng-container *ngIf="(form.controls.seq.value!=0 && form.controls.seq.value!='')">
        <button type="submit" class="btn btn-primary btn-small f15">저장하기</button>
      </ng-container>
      <ng-container *ngIf="(form.controls.seq.value==0 || form.controls.seq.value=='')">
        <button type="submit" class="btn btn-primary btn-small f15">저장하기</button>
      </ng-container>
      <button type="button" class="btn btn-default btn-small ml5 f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
