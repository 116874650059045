import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';

import { UtilService } from '@app/service/util.service';
import { BrandService } from '@app/service/brand.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-brand-find',
  templateUrl: './brand-find.component.html',
  styleUrls: ['./brand-find.component.scss']
})
export class BrandFindComponent implements OnInit {

  brandList: any = [];

  // 그리드 변수 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  noRowsTemplate: string;

  // 폼 변수 선언
  public form: FormGroup;
  public formErrors = {
    searchText: ''
  };

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  buildForm(): void {
    this.form = this.formBuilder.group({
      searchText: ['', [Validators.required, Validators.maxLength(50)]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private utilService: UtilService,
    private brandService: BrandService,
  ) {
    this.buildForm();

    // 그리드 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', cellClass: 'cp right', width: 100 },
      { headerName: '브랜드명', field: 'brand_name', cellClass: 'cp', width: 500 },
      { headerName: '사용여부', field: 'use_yn', cellClass: 'cp center', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1' ) {
            return '<span class="badge badge-success f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
    ];

    // 컬럼 디폴트 값 선언
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  ngOnInit(): void {
    this.getBrandList()
  }

  ngAfterViewInit() {
    $("#searchText").focus();
  }

  // 그리드 준비 시 처리
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  // 그리드 row 클릭 시 처리
  onRowClicked($event) {
    this.activeModal.close( $event );
  }

  // 처음 데이터 렌더링 시 처리
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // 브랜드 검색
  getBrandListForComponent() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.brandService.getBrandListForComponent( this.form ).then( response => {
        this.brandList = response.data;
      });
    }
  }

  // 창닫기
  setDismiss() {
    this.activeModal.dismiss();
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      if( response.ResultCode ) this.brandList = response.data
    })
  }
}
