  <div class="modal-header">
    <h4 class="modal-title fb mb0">환불 수정</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <form [formGroup]="form">
      <table class="table table-bordered table-small1 mb0">
      <caption class="none">회원정보</caption>
      <colgroup>
        <col style="width:15%;" />
        <col style="width:35%;" />
        <col style="width:15%;" />
        <col style="width:35%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="name">모델</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">ID</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">주문자명</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">환불사유</label></th>
        <td><inputEx type="select" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="name">등록일</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
        <th scope="row"><label for="name">반납일</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="name">정산배송비</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="name">수령배송비</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors" placeholder="반품에 동봉된 배송비"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="name">수령송장</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="name">환불방법</label></th>
        <td><inputEx type="select" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">환불수량</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">환불금액</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="name">환불요청담당</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
        <th scope="row"><label for="name">환불요청결제</label></th>
        <td><inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="name">환불요청담당</label></th>
        <td colspan="3"><inputEx type="textarea" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      </tbody>
      </table>

    </form>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button type="button" class="btn btn-danger btn-small">삭제</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small">저장하기</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
  </div>
