  <section class="h100 mt10">
    <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;height: 100%;">
      <as-split-area size="30" minSize="20" maxSize="40">
        <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="onTabChange($event)">
          <mat-tab style="min-width:100px;" label="카테고리(제품군)">
            <div class="right mt5">
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
            </div>

            <div class="mt5" style="height:calc(100% - 50px)">
              <ag-grid-angular
                #myGrid

                style="width: 100%;height:100%;"
                class="ag-theme-balham"

                [columnDefs]="columnDefsCategory"
                [defaultColDef]="defaultColDef"
                [rowData]="categoryList"
                [pagination]="false"
                [domLayout]="domLayout"
                [overlayNoRowsTemplate]="noRowsTemplate"
                [frameworkComponents]="frameworkComponents"
                [rowSelection]="rowSelection"

                (gridReady)="onGridReadyCategory($event)"
                (rowClicked)="onCategoryGridRowClicked($event)"
                >
              </ag-grid-angular>
            </div>
          </mat-tab>
          <mat-tab style="min-width:100px;" label="브랜드">
            <div class="right mt5">
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="2" [btnGroup]="1"></btn-ag-grid-save>
            </div>

            <div class="mt5" style="height:calc(100% - 50px)">
              <ag-grid-angular
                #myGrid

                style="width: 100%;height:100%;"
                class="ag-theme-balham"

                [columnDefs]="columnDefsBrand"
                [defaultColDef]="defaultColDef"
                [rowData]="brandList"
                [pagination]="false"
                [domLayout]="domLayout"
                [overlayNoRowsTemplate]="noRowsTemplate"
                [frameworkComponents]="frameworkComponents"
                [rowSelection]="rowSelection"

                (gridReady)="onGridReadyBrand($event)"
                (rowClicked)="onBrandGridRowClicked($event)"
                >
              </ag-grid-angular>
            </div>
          </mat-tab>
        </mat-tab-group>
      </as-split-area>
      <as-split-area size="70" minSize="60" maxSize="80">
        <div class="right mt5">
          <div class="fl w80p form-inline">
            <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
              <i class="material-icons-outlined">refresh</i>
            </button>
            <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductListForSort()">
              <i class="material-icons-outlined">search</i>
            </button>

            <select id="search_field" [(ngModel)]="params.searchField" class="form-control form-control-small ml5">
              <option value="category_name">카테고리</option>
              <option value="brand_name">메이커(브랜드)</option>
              <option value="product_name">모델명</option>
            </select>

            <input type="text" [(ngModel)]="params.searchText" size="30" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="검색어" />

            <div class="btn-group ml5">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == ''}" (click)="searchParams('status', '')">전체</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '1'}" (click)="searchParams('status', '1')">판매중</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '0'}" (click)="searchParams('status', '0')">대기중</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '2'}" (click)="searchParams('status', '2')">품절</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '3'}" (click)="searchParams('status', '3')">품절제외</button>
            </div>
          </div>

          <div class="fr w20p right">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
            <button type="button" class="btn btn-primary btn-small ml5" (click)="setSortOrderSaveAll()">순서저장</button>
          </div>
          <div class="cb"></div>
        </div>

        <div class="mt5" style="height:calc(100% - 50px)">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="productList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [rowDragManaged]="true"
            [enableMultiRowDragging]="true"
            [animateRows]="true"
            [pagination]="false"
            [paginationPageSize]="paginationPageSize"
            [components]="components"
            [stopEditingWhenGridLosesFocus]="true"

            (gridReady)="onGridReady($event)"
            (cellEditingStarted)="onCellEditingStarted($event)"
            (cellEditingStopped)="onCellEditingStopped($event)"
            >
          </ag-grid-angular>
        </div>
      </as-split-area>
    </as-split>
  </section>
