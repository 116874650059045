<div *ngIf="type=='text' || type == '' || type=='password'" [formGroup]="formGroup" [ngClass]="{'input-group':checkAddOn()}">
  <input *ngIf="keyup !== null"
    [type]="type || 'text'"
    [id]="name"
    formControlName="{{name}}"
    [placeholder]="placeholder || ''"
    [readonly]="readonly || ''"
    [autofocus]="autofocus"
    [size]="size || 20"
    [pattern]="pattern || ''"
    class="form-control"
    [ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
    (keyup)="keyup($event)"
    [value]="selected"
  />
  <input *ngIf="keyup === null"
    [type]="type || 'text'"
    [id]="name"
    formControlName="{{name}}"
    [placeholder]="placeholder || ''"
    [readonly]="readonly || ''"
    [autofocus]="autofocus"
    [size]="size || 20"
    [pattern]="pattern || ''"
    class="form-control"
    [ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
    [value]="selected"
  />
  <div *ngIf="checkAddOn()" class="input-group-append">
    <button class="btn btn-outline-secondary btn-small-addon f12" type="button" (click)="addOn()">{{addOnText}}</button>
  </div>
  <div *ngIf="formErrors[name] && ! checkAddOn()" class="error-message">{{formErrors[name]}}</div>
  <div *ngIf="formErrors[name] && checkAddOn()" class="error-message-hidden">{{formErrors[name]}}</div>
</div>

<div *ngIf="type=='file'" [formGroup]="formGroup" [ngClass]="{'input-group':checkAddOn()}">
  <input *ngIf="keyup !== null"
    [type]="type || 'text'"
    [id]="name"
    formControlName="{{name}}"
    [placeholder]="placeholder || ''"
    [readonly]="readonly || ''"
    [autofocus]="autofocus"
    [size]="size || 20"
    [pattern]="pattern || ''"
    class="form-control"
    [ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
    (keyup)="keyup($event)"
  />
  <input *ngIf="keyup === null"
    [type]="type || 'text'"
    [id]="name"
    formControlName="{{name}}"
    [placeholder]="placeholder || ''"
    [readonly]="readonly || ''"
    [autofocus]="autofocus"
    [size]="size || 20"
    [pattern]="pattern || ''"
    class="form-control"
    [ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
  />
  <div *ngIf="checkAddOn()" class="input-group-append">
    <button class="btn btn-outline-secondary btn-small-addon f12" type="button" (click)="addOn()">{{addOnText}}</button>
  </div>
  <div *ngIf="formErrors[name] && ! checkAddOn()" class="error-message">{{formErrors[name]}}</div>
  <div *ngIf="formErrors[name] && checkAddOn()" class="error-message-hidden">{{formErrors[name]}}</div>
</div>

<div *ngIf="type=='numeric' || type==='number'" [formGroup]="formGroup" [ngClass]="{'input-group':checkAddOn()}">
  <input
    type="text"
    [id]="name"
    formControlName="{{name}}"
    [placeholder]="placeholder || ''"
    [readonly]="readonly || ''"
    [autofocus]="autofocus"
    [size]="size || 20"
    class="form-control"
    [ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
    (keyup)="numberCheck()"
  />
  <div *ngIf="checkAddOn()" class="input-group-append">
    <button class="btn btn-outline-secondary btn-small-addon f12" type="button" (click)="addOn()">{{addOnText}}</button>
  </div>
  <div *ngIf="formErrors[name]" class="error-message">{{formErrors[name]}}</div>
</div>

<div *ngIf="type=='hidden'" [formGroup]="formGroup">
  <input [type]="type" [id]="name" formControlName="{{name}}" />
  <div *ngIf="formErrors[name]" class="error-message-hidden">{{formErrors[name]}}</div>
</div>

<div *ngIf="type=='select'" [formGroup]="formGroup" [ngClass]="{'input-group':checkAddOn()}">
  <select
    [id]="name"
    formControlName="{{name}}"
    class="form-control"
    [ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
    (change)="selectChange()"
  >
    <option *ngFor="let item of data" [value]="item.value" selected="{{item.value == selected}}">{{item.title}}</option>
  </select>
  <div *ngIf="checkAddOn()" class="input-group-append">
    <button class="btn btn-outline-secondary btn-small-addon f12" type="button" (click)="addOn()">{{addOnText}}</button>
  </div>
  <div *ngIf="formErrors[name]" class="error-message">{{formErrors[name]}}</div>
</div>

<div *ngIf="type=='select2'" [formGroup]="formGroup" [ngClass]="{'input-group':checkAddOn()}">
  <l-select2
    [data]="data"
    (ngModelChange)="changed($event)"
    [(ngModel)]="formGroup.controls[name].value"
    width="100%"
    class="form-control"
    [ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
    [ngModelOptions]="{standalone: true}"
    [options]="options"
    style="padding:0px !important;border:solid 0px #fff !important;"
  ></l-select2>
  <input type="hidden" [id]="name" formControlName="{{name}}" />
  <div *ngIf="checkAddOn()" class="input-group-append">
    <button class="btn btn-outline-secondary btn-small f12" type="button" (click)="addOn()">{{addOnText}}</button>
  </div>
  <div *ngIf="formErrors[name]" class="error-message">{{formErrors[name]}}</div>
</div>

<div *ngIf="type=='date'" [formGroup]="formGroup" [ngClass]="{'input-group':checkAddOn()}">
  <input
    type="text"
    [id]="name"
    formControlName="{{name}}"
    class="form-control calendar-input "
    ngbDatepicker
    [footerTemplate]="footerTemplate"
    #d="ngbDatepicker"
    [readonly]="true"
    [placeholder]="placeholder || ''"
    (click)="d.toggle()" 
    (dateSelect)="onDateSelect($event)"   
    [ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
  />
  <div *ngIf="checkAddOn()" class="input-group-append">
    <button class="btn btn-outline-secondary btn-small-addon f12" type="button" (click)="addOn()">{{addOnText}}</button>
  </div>

  <div *ngIf="formErrors[name] && ! checkAddOn()" class="error-message">{{formErrors[name]}}</div>
  <div *ngIf="formErrors[name] && checkAddOn()" class="error-message-hidden">{{formErrors[name]}}</div>

  <ng-template #footerTemplate>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d)">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d.close()">닫기</button>    
  </ng-template>
</div>

<div *ngIf="type=='datetime'" [formGroup]="formGroup" ngbDropdown>
  <input type="text" readonly="readonly" formControlName="{{name}}"
    [id]="name"
    [ngClass]="{formErrors:formErrors[name]}"
    class="form-control form-control-small calendar-input" ngbDropdownToggle />
  <div ngbDropdownMenu >
    <ngb-datepicker #dp [(ngModel)]="date" (dateSelect)="getDatetime()" [ngModelOptions]="{standalone:true}" ></ngb-datepicker>
    <div style="justify-content: center">
      <ngb-timepicker [(ngModel)]="time" [seconds]="true" (ngModelChange)="time=$event;getDatetime()" [ngModelOptions]="{standalone:true}"></ngb-timepicker>
    </div>
  </div>
</div>

<div *ngIf="type=='textarea'" [formGroup]="formGroup" [ngClass]="{'input-group':checkAddOn()}">
  <textarea
    [id]="name"
    formControlName="{{name}}"
    class="form-control"
    [placeholder]="placeholder || ''"
    [rows]="rows || 5"
    [cols]="cols || 80"
		[autofocus]="autofocus"
		[readonly]="readonly || ''"
		[ngClass]="{readonly:readonly, formErrors:formErrors[name], 'form-control-small': small}"
  ></textarea>
  <div *ngIf="checkAddOn()" class="input-group-append">
    <button class="btn btn-outline-secondary btn-small-addon f12" type="button" (click)="addOn()">{{addOnText}}</button>
  </div>
</div>

<div *ngIf="type=='summernote'" [formGroup]="formGroup">
  <textarea
    [id]="name"
    [ngxSummernote]="summernoteConfig"
    formControlName="{{name}}"
    [ngClass]="{readonly:readonly, formErrors:formErrors[name]}"
    [readonly]="readonly || ''"
  ></textarea>

</div>
