<div class="modal-header pb10">
  <h4 class="modal-title fb" id="modal-common-code">판매현황 - {{productName}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="setDismiss()">&times;</button>
</div>

<div class="modal-body p10 mb5">

  <section class="mt10">
    <h4 class="fb">월별 매출 추이(단위 만)</h4>

    <canvas baseChart
      [datasets]="chartData"
      [labels]="chartLabels"
      [options]="chartOptions"
      [plugins]="chartPlugins"
      [legend]="chartLegend"
      [chartType]="chartType"
      height="120"
    >
    </canvas>

  </section>

</div>

<div class="modal-footer p10">
  <button type="button" class="btn btn-default btn-small f15 mr10" aria-label="Close" (click)="setDismiss()">창닫기</button>
</div>
