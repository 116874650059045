import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbDateStruct, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '@app/service/order.service';
import { ScmOrderService } from '@app/service/scm.order.service'
import { DashboardComponent } from '@page/dashboard/dashboard.component'

@Component({
  selector: 'app-modal-delivery',
  templateUrl: './modal-delivery.component.html',
  styleUrls: ['./modal-delivery.component.scss']
})
export class ModalDeliveryComponent implements OnInit {

  @Input() mng_out_seq
  @Input() mng_out_delivery_seq
  @Input() order_master_seq
  @Input() returnDataMode = false
  public form: FormGroup
  public formErrors = {}
  // public date = parseInt(moment().format('YYYY-MM-DD'));

  delivery_company_list = []

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService,
    private scmOrderService: ScmOrderService,
    private dashboardComponent: DashboardComponent,
  ) { }

  ngOnInit(): void {
    this.buildForm()
    this.getDeliveryCompany()
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      mng_out_seq : [this.mng_out_seq, [Validators.required] ],
      consign_date : ['', [Validators.required] ],
      delivery_company : ['', [Validators.required] ],
      delivery_no : ['', [Validators.required] ]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    })
  }

  /*******************************************************************************
    설  명 : 배송처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if(this.form.valid) {
      this.returnDataMode ? this.activeModal.close(this.form.value) :
      this.scmOrderService.setDelivery({
        mem_no : this.dashboardComponent.getUser(),
        consign_date : this.form.value['consign_date'],
        delivery_company : this.form.value['delivery_company'],
        delivery_no : this.form.value['delivery_no'],
        mng_out_seq : this.mng_out_seq,
        mng_out_delivery_seq : this.mng_out_delivery_seq,
        order_master_seq: this.order_master_seq
      }).then(response => {
        if(response.ResultCode) {
          this.toastrService.success(response.ResultMessage)
          this.activeModal.close(this.form)
        } else this.toastrService.error(response.ResultMessage)
      }).catch(e => this.toastrService.error(e))
    } else this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.')
  }

  /*******************************************************************************
    설  명 : 택배사 가져오기
  *******************************************************************************/
  getDeliveryCompany() {
    this.scmOrderService.getDeliveryCompany().then(response => {
      if(response.ResultCode) {
        this.delivery_company_list = response.data.map(e => ({
          title : e['common_name'],
          value : e['common_code']
        }))
      } else this.toastrService.error(response.ResultMessage)
    })
  }

}
