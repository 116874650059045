import { NgModule } from '@angular/core';

import { MomentPipe } from '@app/pipes/moment.pipe';
import { NumeralPipe } from '@app/pipes/numeral.pipe';
import { SafePipe } from '@app/pipes/safe.pipe';
import { KCurrencyPipe } from '@app/pipes/kcurrency.pipe';
import { MenuSearchPipe } from '@app/pipes/menu.search.pipe';

@NgModule({
  declarations: [
    MomentPipe,
    NumeralPipe,
    SafePipe,
    KCurrencyPipe,
    MenuSearchPipe,
  ],
  exports: [
    MomentPipe,
    NumeralPipe,
    SafePipe,
    KCurrencyPipe,
    MenuSearchPipe,
  ],
  providers: [
    MomentPipe,
    NumeralPipe,
  ]
} )
export class PipesModule {
}
