/*******************************************************************************
  설  명 : 주문관리 - 오픈마켓 주문관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateStruct, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OpenmarketService } from '@app/service/openmarket.service';

import * as moment from 'moment';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { OrderOpenmarketUploadComponent } from '@page/order/order-openmarket/order-openmarket-upload/order-openmarket-upload.component';
import { OrderOpenmarketFindComponent } from '@page/order/order-openmarket/order-openmarket-find/order-openmarket-find.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-openmarket',
  templateUrl: './order-openmarket.component.html',
  styleUrls: ['./order-openmarket.component.scss']
})
export class OrderOpenmarketComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public typeList: any = ['지마켓/옥션', '네이버', '스토어팜', '11번가', '쿠팡', '톡스토어'];
  
  public openmarketList: any = [];
  public printDataList: any = [];

  public fileName: any = 'GeneralDelivery_' + moment().format('YYYY-MM-DD hh:mm');

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    standbyCount: 0,
  }

  public search: any = {
    pageNo: 1,
    pageRow: 1000,
    type: '0',
    deliveryYN: '',
    searchGroup: 'g',
    searchText: '',
    sdate: '',
    edate: '',
    searchTerm: 'A',
  };

  public keyField: any = [
    // 지마켓/옥션
    [
      {key: 'a9', title: '수령인'},
      {key: 'a5', title: '모델'},
      {key: 'p_serial', title: '마켓상품코드'},
      {key: 'order_serial', title: '주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 네이버
    [
      {key: 'g', title: '수령인'},
      {key: 'p', title: '모델'},
      {key: 'c', title: '상품주문번호'},
      {key: 'd', title: '마켓주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 스토어팜
    [
      {key: 'j', title: '수령인'},
      {key: 'p', title: '모델'},
      {key: 'o', title: '마켓상품코드'},
      {key: 'a', title: '주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 11번가
    [
      {key: 'm', title: '수령인'},
      {key: 'g', title: '모델'},
      {key: 'ak', title: '마켓상품코드'},
      {key: 'c', title: '주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 쿠팡
    [
      {key: 'a9', title: '수령인'},
      {key: 'a5', title: '모델'},
      {key: 'p_serial', title: '마켓상품코드'},
      {key: 'order_serial', title: '주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 톡스토어
    [
      {key: 'a9', title: '수령인'},
      {key: 'a5', title: '모델'},
      {key: 'p_serial', title: '마켓상품코드'},
      {key: 'order_serial', title: '주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ]
  ];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  printGridApi: any;
  printGridColumnApi: any;
  printColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
    agGridImageComponent: AgGridImageComponent
  };

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
  ];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private utilService: UtilService,
    private openmarketService: OpenmarketService,
    private agGridExComponent: AgGridExComponent,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'uid', width: 50, cellClass: 'cp center ag-cell80h', 
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true 
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 140, cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      { headerName: '마켓모델', field: 'model', width: 300, cellClass: 'cp ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.model + '<br />' + params.data.size_color;
        }
      },
      { headerName: '판매단가', field: 'sale_price', width: 100, cellClass: 'cp right ag-cell80h', cellRenderer: 'agGridHtmlComponent', 
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          return params.data.sale_price + '<br />' + params.data.discount_price;
        }      
      },
      { headerName: '수량', field: 'num', width: 80, cellClass: 'cp right ag-cell80h', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '수령인', field: 'r_name', width: 120, cellClass: 'cp ag-cell80h' },
      { headerName: '택배사', field: 'consign_co', width: 150, cellClass: 'cp ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.consign_co + '<br />' + params.data.consign_date;
        }
      },
      { headerName: '송장번호', field: 'consign_num', width: 150, cellClass: 'cp ag-cell80h ag-cell-edit', editable: true, singleClickEdit: true,
        valueGetter(params: any) {
          const consignNum = params.data.consign_num;
          return ( consignNum ) ? consignNum.replace(/-/g, '') : '';
        }
      },
      { headerName: '마켓주문번호(상품번호)', field: 'p_serial', width: 160, cellClass: 'cp ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.p_serial + '<br />' + params.data.order_serial;
        }
      },
      { headerName: '주문일시', field: 'order_date', width: 150, cellClass: 'cp center ag-cell80h' },
      { headerName: '제품코드', field: 'p_code', width: 100, cellClass: 'cp center ag-cell80h ag-cell-edit', editable: true, singleClickEdit: true },
      { headerName: '처리', field: '', width: 140, cellClass: 'cp center ag-cell80h',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'openmarket',
          setOpenOrderProductFind: this.setOpenOrderProductFind.bind(this),
          setOpenOrderDelete: this.setOpenOrderDelete.bind(this),
          setOpenOrderSave: this.setOpenOrderSave.bind(this),
        }
      },
    ];

    // ag grid 컬럼 선언
    this.printColumnDefs = [
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.searchInit();

    this.getPrintGmarketAuctionList();
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getOpenmarketList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }

    this.getOpenmarketList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getOpenmarketList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 1000,
      type: '0',
      deliveryYN: '',
      searchGroup: this.keyField[0][0].key,
      searchText: '',
      sdate: '',
      edate: '',
      searchTerm: 'A',
    };

    this.getOpenmarketList();
  }

  /*******************************************************************************
    설  명 : 배송목록 업로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openOpenmarketUpload() {
    const modalRef = this.modalService.open(OrderOpenmarketUploadComponent, optionsLG);

    modalRef.componentInstance.type = this.search.type;

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 검색 타입 종류 선택 시
    입력값 : search.type
    리턴값 : 없음
  *******************************************************************************/
  setSearchType( value: any ) {
    this.search.type = value;

    switch( value ) {
      case 0:   // 지마켓/옥션
        this.getPrintGmarketAuctionList();
        this.fileName = 'GeneralDelivery_' + moment().format('YYYY-MM-DD hh:mm');
        break;
      case 1:   // 네이버
        this.getPrintNaverList();
        this.fileName = '발송처리';
        break;
      case 2:   // 스토어팜
        this.getPrintFarmList();
        this.fileName = '발송처리';
        break;
      case 3:   // 11번가
        this.getPrint11stList();
        this.fileName = '11st_list_' + moment().format('YYYY-MM-DD');
        break;
      case 4:   // 쿠팡
        this.getPrintCoupangList();
        this.fileName = 'coupang_list_' + moment().format('YYYY-MM-DD');
        break;
      case 5:   // 톡스토어
        this.getPrintTalkList();
        this.fileName = 'talk_list_' + moment().format('YYYY-MM-DD');
        break;
      default:
    }

    this.getOpenmarketList();
  }
  
  /*******************************************************************************
    설  명 : 오픈마켓 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOpenmarketList() {
    this.openmarketService.getOpenmarketList(this.search).then( response => {
      if( response.ResultCode ) {
        this.openmarketList = response.data;
        this.statistic = response.statistic;
      } else {
        this.openmarketList = [];
        this.statistic = {
          totalCount: 0,
          searchCount: 0,
          standbyCount: 0,
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 지마켓/옥션 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintGmarketAuctionList() {
    this.openmarketService.getPrintGmarketAuctionList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 네이버 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintNaverList() {
    this.openmarketService.getPrintNaverList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 스토어팜 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintFarmList() {
    this.openmarketService.getPrintFarmList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 11번가 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrint11stList() {
    this.openmarketService.getPrint11stList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 쿠팡 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintCoupangList() {
    this.openmarketService.getPrintCoupangList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 톡스토어 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintTalkList() {
    this.openmarketService.getPrintTalkList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 바마찾기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenOrderProductFind( params: any ) {
    const modalRef = this.modalService.open(OrderOpenmarketFindComponent, optionsXXL);

    modalRef.componentInstance.rowData = params.data;

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenOrderDelete() {
    if( confirm("삭제하시겠습니까?") ) {

    }
    // this.openmarketService.setOrderMemoSave(this.memoForm).then( response => {
    //   if ( response.ResultCode ) {
    //     this.toastrService.success( response.ResultMessage, '메모저장');

    //     this.getOrderMemoList();
    //   } else {
    //     this.toastrService.error( response.ResultMessage, '메모저장');
    //   }
    // });
  }

  /*******************************************************************************
    설  명 : 세션수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenOrderSave() {
    if( confirm("수정하시겠습니까?") ) {

    }
    // this.openmarketService.setOrderMemoSave(this.memoForm).then( response => {
    //   if ( response.ResultCode ) {
    //     this.toastrService.success( response.ResultMessage, '메모저장');

    //     this.getOrderMemoList();
    //   } else {
    //     this.toastrService.error( response.ResultMessage, '메모저장');
    //   }
    // });
  }

}
