<h2>주문 메모</h2>
<p>☆ 고객이 업체의 상품을 주문할 경우, 바이크마트에서 해당 주문에 남긴 메모를 보여줍니다.</p>
<p>☆ 데이터 선택시, 해당 주문의 상세정보로 이동합니다.</p>

<div class="mt5" style="height:calc(100% - 170px)">
  <ag-grid-angular #myGrid
    style="width: 100%; height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [pagination]="false"

    (cellDoubleClicked) = "onCellDoubleClicked($event)"
    (gridReady) = "onGridReady($event)"
    (rowClicked) = "onRowClicked($event)"
  ></ag-grid-angular>
  <section class="pagination mt10">
    <ngb-pagination
      [(page)]="page.index"
      [pageSize]="1000"
      [collectionSize]="page.size"
      [maxSize]="20"
      [rotate]="true"
      [boundaryLinks]="true"

      (pageChange) = "pageChange($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>
</div>