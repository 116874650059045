import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'dateTimePicker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
  @Input() name: string;
  @Input() formGroup: any;
  @Input() formErrors: any;

  // public date: any = {
  //   year: parseInt( moment().format('YYYY'), 10 ),
  //   month: parseInt( moment().format('MM'), 10 ),
  //   day: parseInt( moment().format('DD'), 10 )
  // };

  public date: any = null;

  // public time: any = {
  //   hour: 0,
  //   minute: 0,
  //   second: 0
  // };

  public time: any = null;

  public value = '';

  constructor() { }

  ngOnInit(): void {
    this.formGroup.controls[this.name].valueChanges.subscribe( () => {
      const datetime = this.formGroup.controls[this.name].value;

      this.date = {
        year: parseInt( moment(datetime).format('YYYY'), 10 ),
        month: parseInt( moment(datetime).format('MM'), 10 ),
        day: parseInt( moment(datetime).format('DD'), 10 )
      };
      this.time = {
        hour: parseInt( moment(datetime).format('HH'), 10 ),
        minute: parseInt( moment(datetime).format('mm'), 10 ),
        second: parseInt( moment(datetime).format('ss'), 10 )
      };
    });

    if( this.date !== null ) this.getDatetime();
  }

  getDatetime() {
    const tmpDate = {
      year: this.date.year,
      month: this.date.month - 1,
      day: this.date.day
    };
    const datetime = Object.assign(tmpDate, this.time);
    this.value = moment(datetime).format('YYYY-MM-DD HH:mm:ss');

    const data: any = {};
    data[this.name] = this.value;
    this.formGroup.patchValue(data);
  }
}
