<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체<span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">검색<span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">설정<span class="cnt">{{statistic?.completeCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">미설정<span class="cnt">{{statistic?.uncompleteCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<as-split unit="percent" restrictMove="true" gutterSize="10" style="height: calc(100% - 55px) !important;">
  <as-split-area size="55" minSize="50" maxSize="70" >
    <div class="fl w90p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select [(ngModel)]="search.category_code" class="form-control form-control-small ml5" (change)="getProductList()">
        <option value="">카테고리 전체</option>
        <option *ngFor="let item of categoryList" value="{{item.category_code}}">{{item.category_nm}}</option>
      </select>

      <l-select2
        [data]="brandList"
        (ngModelChange)="onBrandChanged($event)"
        [(ngModel)]="search.brand"
        class="form-control form-control-small ml5"
        [options]="{multiple: false}"
        style="width: 200px; padding:0px !important;border:solid 0px #fff !important;"
      ></l-select2>

      <input type="text" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="30" class="form-control form-control-small ml5" placeholder="상품코드, 상품명, 공급자명, 업체명" />
    </div>

    <div class="fr w10p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
    </div>

    <div class="cb"></div>

    <div class="mt5" style="height:calc(100% - 35px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%; height: calc(100% - 50px);"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="productList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [pagination]="false"

        (gridReady)="onGridReady($event)"
        (paginationChanged)="onPaginationChanged($event)"
        (rowClicked)="onRowClicked($event)"
        >
      </ag-grid-angular>
      <section class="pagination mt10" *ngIf="totalCount">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="totalCount"
          [maxSize]="15"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </section>
    </div>
  </as-split-area>
  <as-split-area size="45" minSize="30" maxSize="50">
    <div class="right">
      <btn-ag-grid-save [gridColumnsApi]="propertyGridColumnApi" [gridNumber]="1" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-danger btn-small ml5" (click)="setBarcodeDelete()">바코드삭제</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="addBarcode()">바코드추가</button>
    </div>

    <div class="mt5" style="height:calc(50% - 45px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="propertyColumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="productPropertyList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [pagination]="false"

        (gridReady)="onPropertyGridReady($event)"
        (rowClicked)="onPropertyRowClicked($event)"
        >
      </ag-grid-angular>
    </div>

    <div class="mt5" style="height:calc(50% - 45px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefsBarcode"
        [defaultColDef]="defaultColDef"
        [rowData]="barcodeList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (gridReady)="onGridReadyBarcode($event)"
        (firstDataRendered)="onFirstDataRenderedBarcode($event)"
        >
      </ag-grid-angular>
    </div>
  </as-split-area>
</as-split>
