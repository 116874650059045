import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal, NgbDateStruct, NgbModalOptions, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '@app/service/order.service';

import * as moment from 'moment';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss']
})
export class OrderPaymentComponent implements OnInit {


  private dateModel: NgbDateStruct;

  public search = {}
  public productList: any = [];

  public form: FormGroup;
  public formErrors = {};
  
  public seq: any;

  public date = parseInt(moment().format('YYYY-MM-DD'));


  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      pay_method: ['0003', [Validators.required] ],
      pay_date: [ null, [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService
  ) {
    this.buildForm();
   }

  ngOnInit(): void {
    this.form.patchValue( {seq: this.seq} );
  }


  /*******************************************************************************
    설  명 : 결제 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    setOrderPayComplete() {
      this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

      if(this.form.valid) {
        this.orderService.setOrderPayComplete(this.form).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.activeModal.close(this.form);
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });

      } else {
        this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      }
    }
}
