  <div class="">
    <div class="fl w80p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductStock()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getProductStock()">
        <option value="10">10줄</option>
        <option value="20">20줄</option>
        <option value="50">50줄</option>
        <option value="100">100줄</option>
        <option value="200">200줄</option>
        <option value="500">500줄</option>
      </select>

      <!-- <l-select2
        [data]="categoryList"
        (ngModelChange)="selectCategory($event)"
        [(ngModel)]="search.category_seq"
        class="form-control form-control-small ml5"
        [options]="{multiple: false}"
        style="width: 250px; padding:0px !important;border:solid 0px #fff !important;"
      ></l-select2> -->
      <l-select2
        [data]="brandList"
        (ngModelChange)="selectBrand($event)"
        [(ngModel)]="search.brand_name"
        class="form-control form-control-small ml5"
        [options]="{multiple: false}"
        style="width: 250px; padding:0px !important;border:solid 0px #fff !important;"
      ></l-select2>
      <input type="text" [(ngModel)]="search.searchText" size="30" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="상품명, 상품번호" />

    </div>
    <div class="cb"></div>
  </div>

  <div class="mt5" style="height: calc(100% - 170px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%; height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="productList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowHeight]="getRowHeight"

      (gridReady)="onGridReady($event)"
      (columnResized)="onColumnResized($event)"
      (columnVisible)="onColumnVisible($event)"
      >
    </ag-grid-angular>
    <section class="pagination mt10">
      <ngb-pagination
        *ngIf="search.totalCount"
        [(page)]="search.pageNo"
        [pageSize]="search.pageRow"
        [collectionSize]="search.totalCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
  </div>
