import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { OrderReturnreqDetailImgComponent } from '@page/order/order-returnreq/order-returnreq-detail/order-returnreq-detail-img/order-returnreq-detail-img.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-returnreq-detail',
  templateUrl: './order-returnreq-detail.component.html',
  styleUrls: ['./order-returnreq-detail.component.scss']
})
export class OrderReturnreqDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() kind: '';

  kind1 = '';
  title = '반품';

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.kind1 = this.kind;
    if (this.kind1 == '1') {
      this.title = '반품';
    } else if (this.kind1 == '2') {
      this.title = '교환';
    } else if (this.kind1 == '3') {
      this.title = '취소';
    } 
  }

  /*******************************************************************************
    설  명 : 이미지 보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  viewImg() {
    const modalRef = this.modalService.open(OrderReturnreqDetailImgComponent, options);

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  } 

}
