<section id="order-change">
  <div class="mt10">
    <div class="fl w30p">
      <div class="fb mt5 mb6">교환/환불 내역</div>
    </div>
    <div class="cb"></div>
  </div>

  <div class="" style="height:calc(100% - 35px)">
    <ag-grid-angular
      #myGrid

      style="width:100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="rowData"
      [pagination]="false"
      [domLayout]="domLayout"
      [getRowHeight]="getRowHeight"
      [components]="components"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>

</section>