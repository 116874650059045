import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';

import { ToastrService } from 'ngx-toastr';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { OrderIniorderAddComponent } from '@page/order/order-iniorder/order-iniorder-add/order-iniorder-add.component';
import { OrderIniorderPriceComponent } from '@page/order/order-iniorder/order-iniorder-price/order-iniorder-price.component';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';


const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-iniorder',
  templateUrl: './order-iniorder.component.html',
  styleUrls: ['./order-iniorder.component.scss']
})
export class OrderIniorderComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
 
  public orderInitialList: any = [];
  public orderDetailList: any = [];

  public rowSelectProduct: any;

  public iniOrderDetail = {
    manage: true,
    pageNo: 1,
    pageRow: 20,
    iniseq: '',
    searchField: 'r_name',
    searchText: '',
    searchField2: 'o_name',
    searchText2: '',
    shop_gbn: '',
    sdate: '',
    edate: '',
    order_status: '',
    searchType: '',
    onoff_gbn: '',
    pay_method: '',
    topSearchText: ''
  }

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;

  columnDefs: any;
  columnDefsDetail: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionDetail: any;

  public components: any;

  public form: FormGroup;
  public formErrors = {};

  public search: any = {
    orderText: ''
  };

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
    buildForm(): void {
      this.form = this.formBuilder.group({
        seq: ['', [Validators.required] ],
        detail: [[], [Validators.required] ]
      });
  
      this.form.valueChanges.subscribe(data => {
        this.utilService.updateFormErrors( this.form, this.formErrors );
      });
    }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService,
    private formBuilder: FormBuilder,
    private agGridExComponent: AgGridExComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.buildForm();
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', width: 100, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '초도주문명', field: 'order_name', width: 500, cellClass: 'cp' },
      { headerName: '합계금액', field: 'order_amt', width: 200, cellClass: 'cp', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '수량', field: 'order_qty', width: 200, cellClass: 'cp', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '등록일자', field: 'write_date', width: 150, cellClass: 'cp' },
      { headerName: '수정일자', field: 'change_date', width: 150, cellClass: 'cp' },
      { headerName: '수정', field: 'modify', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<button type="button" class="btn btn-default btn-grid2 mr5"><i class="material-icons menu-icon fl micon-create f12 ml3">&#xe150;</i></button>`
        }  
      },
    ];  
    
    this.columnDefsDetail = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },    
      { headerName: '브랜드', field: 'brand_name', width: 200, cellClass: 'cp center ag-cell80h' },
      { headerName: '카테고리', field: 'category_name', width: 200, cellClass: 'cp center ag-cell80h' },
      { headerName: '이미지', field: 'thumbnail_url', width: 300, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '모델', field: 'product_name', width: 300, cellClass: 'cp center ag-cell80h' },
      { headerName: '수량', field: 'qty', width: 100, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, valueFormatter: this.agGridExComponent.currencyFormatter,
        cellEditor: "numericCellEditor"
      },
      { headerName: '구매단가', field: 'provider_buy_price', width: 100, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '구매원가', field: 'provider_buy_price', width: 100, cellClass: 'cp right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: function(params) {
          return (
            params.data.provider_buy_price * params.data.qty
          )
        }
      },
      { headerName: '판매단가', field: 'unit_price', width: 100, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '판매가', field: 'amt', width: 100, cellClass: 'cp right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: function(params) {
          if( params.data.unit_price != params.data.amt ) {
            params.data.amt = params.data.unit_price;
          }
          return (
            params.data.amt = params.data.amt * params.data.qty
          )
        }
      },
      { headerName: '마진율', field: '', width: 100, cellClass: 'cp right ag-cell80h',
        valueGetter: function(params) {
          return (
            ((params.data.unit_price - params.data.provider_buy_price) / params.data.unit_price * 100).toFixed(1) + '%'
          )
        }
      }
    ];      

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';
    this.rowSelectionDetail = 'multiple';


    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';


    // ag grid 컴포넌트
    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor()
    };
   }
  
  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    this.getOrderInitialList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리 -> 주문 상세정보
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onGridApiDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }
  

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getRowHeight = function(params) {
      return 80;
    };

  

  /*******************************************************************************
    설  명 : 관리자 주문등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    adminOrderAdd() {
      // this.iniOrderDetail.iniseq = this.orderDetailList;

      const nodes = this.gridApi.getSelectedNodes();

      const data: any = [];
      for(let node of nodes) {
        data.push( node.data.seq );
      }

      this.iniOrderDetail.iniseq = data;

      if( data.length < 1 ) {
        this.toastrService.error('주문 등록할 항목을 선택해주세요.');
      } else {
        // this.router.navigate(
        // ['/order/detail'],
        // {
        //   relativeTo: this.activatedRoute,
        //   queryParams: this.iniOrderDetail,
        //   queryParamsHandling: '',
        // });

        const url = '/order/detail?iniseq=' + data;
        window.open("about:blank").location.href = url;
        
      }
    }

  

  /*******************************************************************************
    설  명 : 초도주문명 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderInitialList() {
    this.orderService.getOrderInitialList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.orderInitialList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 초도주문등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderAdd( item: any ) {
    const modalRef = this.modalService.open(OrderIniorderAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      if(result) {
        this.getOrderInitialList();
      }
    }, (reason) => {
    });
  }


  /*******************************************************************************
    설  명 : 초도주문관리 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    delOrderInitialList() {

      const nodes = this.gridApi.getSelectedNodes();

      const data: any = [];
      for(let node of nodes) {
        data.push( node.data.seq );
      }

      if ( nodes.length < 1 ) {
        this.toastrService.error('삭제할 항목을 선택해주세요.');
      
      } else if( confirm("선택하신 항목을 삭제하시겠습니까?") ) {
          this.orderService.delOrderInitialList( data ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');
              this.getOrderInitialList();
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
        });
       }
    }
  


  /*******************************************************************************
    설  명 : 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    productAdd() {

      const nodes = this.gridApi.getSelectedNodes();

      const data: any = [];
      for(let node of nodes) {
        data.push( node.data );
      }

      if(data.length < 1) {
        this.toastrService.error('추가할 상품의 주문명을 선택해주세요.', '');

      } else {
      const modalRef = this.modalService.open(ProductFindPropertyComponent, optionsXXL);
     
      modalRef.result.then((result) => {
  
        if( typeof result != 'undefined' ) {
  
          let tmp = this.form.controls.detail.value;
  
          const newResult: any = [...tmp];
          result.forEach(obj => {
            const duplicate = tmp.filter(function (item) {
              return (item.product_code === obj.product_code && item.product_property_seq === obj.property_seq );
            });

            if( duplicate.length < 1 ) {
              obj.order_status = 0;
              obj.qty = 1;
              obj.unit_price = obj.consumer_price;
              obj.discount_amt = 0;
              obj.provider_buy_price = obj.buy_price;
              obj.amt = obj.unit_price;
              obj.total_amt = obj.amt;
              obj.product_seq = obj.seq;
              obj.product_property_seq = obj.property_seq;

              newResult.push(obj);
            }
          });


          this.form.patchValue({ detail: newResult });
  
        }
      }, (reason) => {
      });
    }

    }


  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onRowClicked($event) {
      this.orderDetailList = $event.data.seq;
      this.getOrderDetailInitialList();
    }

  /*******************************************************************************
    설  명 : cell 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellClicked($event) {
      // 수정 시
      if( $event.colDef.field === 'modify' ) {
        this.orderAdd( $event.data );
      }
    }


  /*******************************************************************************
    설  명 : 상품 상세 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getOrderDetailInitialList() {

      const nodes = this.gridApi.getSelectedNodes();

      const data: any = [];
      for(let node of nodes) {
        data.push( node.data.seq );
      }
      
      this.orderService.getOrderDetailInitialList( this.orderDetailList ).then( response => {
        if ( response.ResultCode ) {
          this.form.patchValue({
            detail : response.data
          });
        } else {
          this.toastrService.error( response.ResultMessage, '')
        }
      })
    }



  /*******************************************************************************
    설  명 : 상품 상세리스트 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    setInitialOrderSave() {
      this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

      const nodes = this.gridApi.getSelectedNodes();

      const data: any = [];
      for(let node of nodes) {
        data.push( node.data );
      }

      if(data.length < 1) {
        this.toastrService.error('저장할 상품의 주문명을 선택해주세요.', '');

      } else {
        this.form.patchValue({
          seq: this.orderDetailList
        });
        this.orderService.setInitialOrderSave(this.form).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.getOrderInitialList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
    

  /*******************************************************************************
    설  명 : 주문상세정보 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    deleteProduct() {
      const selectProduct = [];
      this.gridApiDetail.getSelectedRows().forEach(index => {
        selectProduct.push(index['property_seq']);
      });

      const nodes = this.gridApiDetail.getSelectedNodes();

      const data: any = [];
      for(let node of nodes) {
        data.push( node.data );
      }

      const productList = JSON.parse(JSON.stringify(this.form.value.detail));
      if (data.length < 1) {
        this.toastrService.error( '삭제할 항목을 선택해주세요.');
      } else {
        if( confirm("선택하신 항목을 삭제하시겠습니까?") ) {
          selectProduct.forEach(el => {
            productList.splice(productList.findIndex( index => index['property_seq'] === el), 1);
          });
        }
        this.form.patchValue({
          detail : productList
        });
      }
    }



  /*******************************************************************************
    설  명 : 판매가 일괄변경
    입력값 : 없음
    리턴값 : 없음
  ******************************f*************************************************/
    priceChange() {
      const nodes = this.gridApi.getSelectedNodes();

      const data: any = [];
      for(let node of nodes) {
        data.push( node.data );
      }

      if( data.length < 1 ) {
        this.toastrService.error("주문명을 선택하세요.");
      } else if( this.form.controls.detail.value < 1 ) {
        this.toastrService.error("주문 상세 정보가 없습니다.");
      } else {
        const modalRef = this.modalService.open(OrderIniorderPriceComponent, options);
        modalRef.result.then((result) => {
        let tmp = this.form.controls.detail.value;
        const newResult: any = [];

        if( result.value.method == '1') {
          if( result.value.price_kind == '1') {
            tmp.forEach(obj => {
              const duplicate = tmp.filter(function (item) {
                return (item.product_code === obj.product_code && item.product_property_seq === obj.property_seq );
              });
                obj.unit_price = obj.com_price;
                obj.amt = obj.unit_price;
                newResult.push(obj);
            });
  
            } else if( result.value.price_kind == '2' ) {
              tmp.forEach(obj => {
                const duplicate = tmp.filter(function (item) {
                  return (item.product_code === obj.product_code && item.product_property_seq === obj.property_seq );
                });
                  obj.unit_price = obj.fra_price;
                  obj.amt = obj.unit_price;
                  newResult.push(obj);
  
              });
            } else if( result.value.price_kind == '3' ) {
              tmp.forEach(obj => {
                const duplicate = tmp.filter(function (item) {
                  return (item.product_code === obj.product_code && item.product_property_seq === obj.property_seq );
                });
                  obj.unit_price = obj.b2b_price;
                  obj.amt = obj.unit_price;
                  newResult.push(obj);
                  
              });
            } else if( result.value.price_kind == '4' ) {
              tmp.forEach(obj => {
                const duplicate = tmp.filter(function (item) {
                  return (item.product_code === obj.product_code && item.product_property_seq === obj.property_seq );
                });
                  obj.unit_price = obj.consumer_price;
                  obj.amt = obj.unit_price;
                  newResult.push(obj);
              });
            }
          } else {
            tmp.forEach(obj => {
              const duplicate = tmp.filter(function (item) {
                return (item.product_code === obj.product_code && item.product_property_seq === obj.property_seq );
              });
                obj.unit_price = result.value.price;
                obj.amt = obj.unit_price;
                newResult.push(obj);
            });
          }
          this.form.patchValue({ detail: newResult });
        }, (reason) => {
      });
    }
  }  


  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    searchInit() {
      this.search = {
        orderText: ''
      };
      this.getOrderInitialList();
    }


  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
    searchList( event ) {
      if( event.key == 'Enter' ) {
        this.getOrderInitialList();
      }
    }

}
