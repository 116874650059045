import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileSelectDirective, FileItem, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';

import { SystemPositionService } from '@app/service/position.service';
import { SystemAuthGroupService } from '@app/service/auth.group.service';
import { EmployeeService } from '@app/service/employee.service';
// import { EmployeeComponent } from '@app/page/system/employee/employee.component'

import * as $ from 'jquery';

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-modify-myinfo',
  templateUrl: './modify-myinfo.component.html',
  styleUrls: ['./modify-myinfo.component.scss']
})
export class ModifyMyinfoComponent implements OnInit {
  
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public baseURL = ( config.baseUrl.substr(0,4) == 'http' ) ? config.baseUrl : 'http:' + config.baseUrl;

  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });

  public form: FormGroup;
  public formErrors = {};

  private dateModel: NgbDateStruct;
  public uploadProgress: any = 0;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      mem_no: [ '', [Validators.required] ],
      id: [ '', [Validators.required, Validators.maxLength(20)] ],
      name: [ '', [Validators.required, Validators.maxLength(50)] ],
      email: [ '', [Validators.maxLength(100)] ],
      birthdate: [ null, [] ],
      birth_sec: [ 'S', [] ],
      tel: [ '', [Validators.maxLength(20)] ],
      hp: [ '', [Validators.maxLength(20)] ],
      extension_number: [ '', [Validators.maxLength(20)] ],
      emergency_tel: [ '', [Validators.maxLength(100)] ],
      zipcode: [ '', [] ],
      address: [ '', [Validators.maxLength(255)] ],
      address_detail: [ '', [Validators.maxLength(255)] ],
      picture: [ '', [] ],
      picture_url: ['/assets/images/no_image.png', [] ],
      picture_origin: [ '', [] ],
      
      password: [ '' ],
      auth_group_seq: [ '' ],
      dept_seq: [ '' ],
      position_seq: [ '' ],
      level: [ '' ],
      joindate: [ null ],
      leavedate: [ null ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 이미지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteImage() {
    this.form.patchValue({
      picture_url: '/assets/images/no_image.png',
      picture_origin: ''
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private employeeService: EmployeeService,
    private systemPositionService: SystemPositionService,
    private systemAuthGroupService: SystemAuthGroupService,
    public authService: AuthService,
  ) {
    this.buildForm();
    this.bindingInfo();
  }

  /*******************************************************************************
    설  명 : 데이터 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

    // 업로드 허용 파일 설정
    const filetype: string[] = ['image'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 5 * (1024 * 1024), // 최대 업로드 허용 5MB 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if ( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if ( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if ( response.result ) {
        this.toastrService.success( response.message, '');

        this.form.patchValue( {
          picture_url: this.baseURL + response.url,
          picture_origin: response.origin_filename
        });

      } else {
        this.toastrService.error( response.message, '');
      }
    };

  }

  /*******************************************************************************
    설  명 : 현재 로그인한 회원 정보 바인딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  bindingInfo() {
    this.authService.getLoginInfo.subscribe(e => {

      this.employeeService.getEmployeeInfoId( e['id'] ).then( response => {
        if( !response.ResultCode ) {this.toastrService.error( response.ResultMessage, "회원검색" ); return;}
        const e = response.data;
        this.form.patchValue({
          mem_no: e['mem_no'],
          id: e['id'],
          name: e['name'],
          email: e['email'],
          birthdate: this.utilService.getDate(e['birthdate']),
          birth_sec: e['birth_sec'],
          tel: e['tel'],
          hp: e['hp'],
          extension_number: e['extension_number'],
          emergency_tel: e['emergency_tel'],
          picture: e['picture'],
          picture_url: e['picture_url'],
          picture_origin: e['picture_origin'],
          zipcode: e['zipcode'],
          address: e['address'],
          address_detail: e['address_detail'],
  
          auth_group_seq: e['auth_group_seq'],
          dept_seq: e['dept_seq'],
          joindate: this.utilService.getDate(e['joindate']),
          leavedate: this.utilService.getDate(e['leavedate']),
          level: e['level'],
          password: e['password'],
          position_seq: e['position_seq'],
        });
      });

    }).unsubscribe;
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for (const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;
    this.uploader.addToQueue(filteredFiles, options, filters);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {
  }

  /*******************************************************************************
    설  명 : 사원정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if (this.form.valid) {
      // const _Format = ['birthdate', 'joindate', 'leavedate'];
      // _Format.forEach((e,i) => {
      //   const _F = this.form.value[e];
      //   _F ? _Format[i] = `${_F.year}-${_F.month}-${_F.day}` : _Format[i] = null;
      // });
      // const _FORM = {
      //   ...this.form,
      //   'value' : {
      //     ...this.form.value,
      //     birthdate: _Format[0],
      //     joindate: _Format[1],
      //     leavedate: _Format[2],
      //   }
      // };
      this.employeeService.setEmployee(this.form).then( async response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '사원정보 저장');
        else this.toastrService.error( response.ResultMessage, '사원정보 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '사원정보 저장');
    }
  }

  /*******************************************************************************
    설  명 : 다음 주소 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $('#address_detail').focus();
  }

}
