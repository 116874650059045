 <form [formGroup]="form">

  <section>
    <div class="mb5">
      <div class="fl fb pt5">상품 기본정보</div>

      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="submit(true)">저장하고 계속하기</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="submit()">저장하기</button>
      </div>

      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">상품 기본정보</caption>
    <colgroup>
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="brand_name">브랜드</label></th>
      <td colspan="3">
        <inputEx type="text" name="brand_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="검색버튼을 사용하세요." [addOn]="addBrandFunc" addOnText="검색"></inputEx>
        <inputEx type="hidden" name="brand_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="is_soldout">품절여부(전체)</label></th>
      <td colspan="3">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_soldout.value=='1'}" (click)="form.get('is_soldout').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_soldout.value=='0'}" (click)="form.get('is_soldout').setValue('0')">아니오</button>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="category_seq">주 카테고리</label></th>
      <td colspan="3">
        <inputEx type="select" name="category_seq" [formGroup]="form" [formErrors]="formErrors" [data]="usingCategoryList"></inputEx>
        <!-- <inputEx type="select2" name="category_seq" [formGroup]="form" [formErrors]="formErrors" [data]="categoryList" [small]="true" [options]="{multiple: false}" [onChanged]="mainCategoryChangedFunc"></inputEx> -->
        <span class="cred">※ 찾으시는 카테고리가 없는 경우 '해당없음'을 선택한 후 우측 카테고리 추가에서 선택해주세요.</span>
      </td>
      <th scope="row"><label for="add_category_seq">카테고리 추가</label></th>
      <td colspan="3">
        <inputEx type="select" name="add_category_seq" [formGroup]="form" [formErrors]="formErrors" [data]="categoryList"></inputEx>
        <!-- <inputEx type="select2" name="category_list" [formGroup]="form" [formErrors]="formErrors" [data]="categoryList" [small]="true" [options]="{multiple: true}" [onChanged]="categoryChangedFunc"></inputEx> -->
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="product_name">상품명</label></th>
      <td colspan="3">
        <inputEx type="text" name="product_name" [formGroup]="form" [formErrors]="formErrors" placeholder="핵심문구만 간단히, 법률에 위반되는 금칙어 입력제한"></inputEx>
      </td>
      <!-- <th scope="row"><label for="gift_product_name">사은품</label></th>
      <td>
        <inputEx type="text" name="gift_product_name" [formGroup]="form" [formErrors]="formErrors" placeholder="상품명 앞에 첨부되는 사은품 내용만"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="is_set_product">세트상품여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_set_product.value=='1'}" (click)="form.get('is_set_product').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_set_product.value=='0'}" (click)="form.get('is_set_product').setValue('0')">아니오</button>
        </div>
        <inputEx type="hidden" name="is_set_product" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td> -->
      <th scope="row"><label for="comment">상품홍보문구</label></th>
      <td colspan="3">
        <inputEx type="text" name="comment" [formGroup]="form" [formErrors]="formErrors" placeholder="상품명 앞에서 강조"></inputEx>
      </td>      
    </tr>
    </tbody>
    </table>
  </section>

  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">상품 가격정보</div>
      <div class="cb"></div>
      <p class="cred fb">소비자가 변경시 색상 및 사이즈의 개별 소비자가도 일괄 변경됩니다.</p>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">상품 가격정보</caption>
    <colgroup>
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
    </colgroup>

    <tbody>
    <tr>
      <!-- <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="display_price">화면표시가</label></th>
      <td>
        <inputEx type="numeric" name="display_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td> -->
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="consumer_price">소비자가</label></th>
      <td>
        <!-- <inputEx type="numeric" name="consumer_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)" (change)="onChangePrice($event)"></inputEx> -->
        <inputEx type="numeric" name="consumer_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="buy_price">공급가</label></th>
      <td>
        <!-- <inputEx type="numeric" name="buy_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onCheckPrice()" (change)="onCheckPrice()"></inputEx> -->
        <inputEx type="numeric" name="buy_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th colspan="4"></th>
    </tr>
    </tbody>
    </table>
  </section>

  
  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5"><i class="material-icons cred f11 lh20">star</i> 색상 및 사이즈</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="submit(true)">저장하고 계속하기</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="submit()">저장하기</button>
        <button type="button" class="btn btn-danger btn-small ml5" (click)="setPropertyDelete()">색상 및 사이즈 삭제</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="propertyAdd()">색상 및 사이즈 등록</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height:300px">
      <ag-grid-angular
        #myGrid

        style="width: 100%; height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs_property"
        [defaultColDef]="defaultColDef"
        [rowData]="form.value.rowData_property"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [stopEditingWhenGridLosesFocus]="true"
        [suppressRowClickSelection]="true"

        (gridReady)="onGridReadyProperty($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        >
      </ag-grid-angular>
    </div>
  </section>
  

  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">상품 상세정보</div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">상품 상세정보</caption>
    <colgroup>
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="origin_country">원산지</label></th>
      <td>
        <inputEx type="text" name="origin_country" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="search_keyword">연관 검색어 입력</label></th>
      <td colspan="5">
        <inputEx type="text" name="search_keyword" [formGroup]="form" [formErrors]="formErrors" placeholder="영문 200자, 한글 100자"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="contents">제품소개</label></th>
      <td colspan="7">
        <inputEx type="summernote" name="contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"></th>
      <td colspan="7"><span class="cred f16">※ 에디터에 이미지를 첨부하려면 아래 "파일선택" 클릭하여 이미지 업로드 한 후에 첨부하려는 이미지를 드래그 해서 제품소개 작성란 원하는곳에 놓아주세요.</span></td>
    </tr>
    <!-- <tr>
      <th scope="row"><label for="summary">제품소개 요약</label></th>
      <td colspan="7">
        <inputEx type="summernote" name="summary" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr> -->
    </tbody>
    </table>

  </section>

  <section class="mt15">
    <table class="table table-bordered table-small">
    <caption class="hidden">상품 이미지정보</caption>

    <colgroup>
      <col style="width:8%" />
      <col style="width:92%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row" class="active"><i class="material-icons cred f11 lh20">star</i> <label for="file">이미지</label></th>
      <td>
        <ng-container>
          <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
          <div class="progress mt5 mb5">
            <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
          </div>
          <div class="mt5">
          ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 50MB 제한, 가로*세로 : 1000*1000픽셀 (필히 등록)
          </div>
        </ng-container>
        <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
          <ul class="file-ul">
            <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
              <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                <div *ngIf="checkImage(getExt(file.origin_filename))" class="upload-image" (click)="insertUploadImage(file)">
                  <!-- <img *ngIf="file.thumbnail_url" [src]="file.thumbnail_url.substr(0,4) != 'http' ? baseURL + file.thumbnail_url : file.thumbnail_url" />
                  <img *ngIf="!file.thumbnail_url" [src]="file.url.substr(0,4) != 'http' ? baseURL + file.url : file.url" /> -->
                  <img [src]="file.url.substr(0,2) != '//' && file.url.substr(0,4) != 'http' ? baseURL + file.url : file.url" />
                </div>
                <div *ngIf="!checkImage(getExt(file.origin_filename))" class="upload-image1">{{getExt(file.origin_filename)}}</div>
                <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin_filename}}">{{file.origin_filename}}</a></div>
                <div class="upload-size">
                  ({{getComma(file.filesize)}} KB)
                  <a class="cp ml5" (click)="setDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                </div>
              </div>
            </li>
            <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
              <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                <div *ngIf="checkImage(getExt(file.origin_filename))" class="upload-image" (click)="insertFilesImage(file)">
                  <!-- <img *ngIf="file.thumbnail_url" [src]="file.thumbnail_url.substr(0,4) != 'http' ? baseURL + file.thumbnail_url : file.thumbnail_url" />
                  <img *ngIf="!file.thumbnail_url" [src]="file.url.substr(0,4) != 'http' ? baseURL + file.url : file.url" /> -->
                  <img [src]="file.url.substr(0,2) != '//' && file.url.substr(0,4) != 'http' ? baseURL + file.url : file.url" />
                </div>
                <div *ngIf="!checkImage(getExt(file.origin_filename))" class="upload-image1">{{getExt(file.origin_filename)}}</div>
                <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin_filename}}">{{file.origin_filename}}</a></div>
                <div class="upload-size">
                  ({{getComma(file.filesize)}} KB)
                  <a class="cp ml5" (click)="deleteFile( file, i )">
                    <img src="/assets/images/del_ico.png" />
                  </a>
                </div>
              </div>
            </li>
            <li class="cb"></li>
          </ul>
        </div>
      </td>
    </tr>
    <tr>
      <th></th>
      <td><span class="cred f16">※ 대표이미지로 설정하려면 해당 이미지를 클릭하시기 바랍니다.</span></td>
    </tr>
    </tbody>
    </table>
  </section>
  
  <ng-container *ngIf="form.value.product_seq!=''">
    <section class="mt15 mb15">
      <div class="mb5">
        <div class="fl fb pt5">상품 메모</div>
        <div class="fr">
        </div>
        <div class="cb"></div>
      </div>
  
      <table class="table table-bordered table-small1 mb0">
      <caption class="none">상품 메모</caption>
      <colgroup>
        <col style="width:8%;" />
        <col style="width:92%;" />
      </colgroup>
  
      <tbody>
      <tr>
        <th scope="row"><label for="memo">메모</label></th>
        <td>
          <inputEx type="text" name="memo" [formGroup]="form" [formErrors]="formErrors" [addOn]="memoAddFunc" addOnText="메모추가" (keypress)="setKeyPressMemo($event)"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
  
      <div style="height:200px" class="mt5">
        <ag-grid-angular
          #myGrid
  
          style="width: 100%; height:100%;"
          class="ag-theme-balham"
  
          [columnDefs]="columnDefs_memo"
          [defaultColDef]="defaultColDef"
          [rowData]="form.value.rowData_memo"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
  
          (gridReady)="onGridReadyMemo($event)"
          >
        </ag-grid-angular>
      </div>
    </section>
  </ng-container>


  <!-- <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">사은품 구성</div>
      <div class="fr" *ngIf = "!form.value.product_seq">
        <button type="button" class="btn btn-danger btn-small ml5" (click)="setProductGiftDelete()">상품삭제</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="productGiftAdd()">상품추가</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height:200px">
      <ag-grid-angular
        #myGrid

        style="width: 100%; height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs_gift"
        [defaultColDef]="defaultColDef"
        [rowData]="form.value.rowData_gift"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [suppressRowClickSelection]="true"

        (gridReady)="onGridReadyGift($event)"
        >
      </ag-grid-angular>
    </div>
  </section>

  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">연관상품 구성</div>
      <div class="fr" *ngIf = "!form.value.product_seq">
        <button type="button" class="btn btn-danger btn-small ml5" (click)="setProductRelationDelete()">상품삭제</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="productRelationAdd()">상품추가</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height:300px">
      <ag-grid-angular
        #myGrid

        style="width: 100%; height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs_relation"
        [defaultColDef]="defaultColDef"
        [rowData]="form.value.rowData_relation"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [stopEditingWhenGridLosesFocus]="true"
        [suppressRowClickSelection]="true"

        (gridReady)="onGridReadyRelation($event)"
        >
      </ag-grid-angular>
    </div>
  </section>

  <section class="mt15 pb50" *ngIf="form.value.is_set_product">
    <div class="mb5">
      <div class="fl fb pt5">세트상품 구성</div>
      <div class="fr" *ngIf = "!form.value.product_seq">
        <button type="button" class="btn btn-danger btn-small ml5" (click)="setProductSetDelete()">상품삭제</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="productSetAdd()">상품추가</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height:300px">
      <ag-grid-angular
        #myGrid

        style="width: 100%; height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs_set"
        [defaultColDef]="defaultColDef"
        [rowData]="form.value.rowData_set"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [stopEditingWhenGridLosesFocus]="true"
        [suppressRowClickSelection]="true"

        (gridReady)="onGridReadySet($event)"
        >
      </ag-grid-angular>
    </div>
  </section> -->

</form>
